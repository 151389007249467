.reminder_filter_mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    background-color: transparent;
    padding: 0px 0px 12px 2px;

    .purchase_filter_heading {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        color: rgba(25, 36, 52, 1);
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
    }

    .purchase_filter_list {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        .filter-item {
            color: rgba(27, 43, 65, 0.69);
            font-family: Lato;
            font-size: 14px;
            font-weight: 500;
            line-height: 18.76px;
            text-align: center;
            border: 1px solid rgba(28, 48, 74, 0.52);
            padding: 8px 16px 8px 16px;
            border-radius: 32px;

            &.active {
                color: rgba(34, 163, 140, 1);
                border: 1px solid rgba(34, 163, 140, 1);
                background-color: rgba(55, 191, 167, 0.15);
            }
        }
    }
}