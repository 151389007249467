.forgot_password_content {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.email_address_heading {
    font-family: Lato;
    font-size: 28px;
    font-weight: 700;
    line-height: 35.08px;
    text-align: left;
    color: rgba(25, 39, 57, 1);
}

.email_address_info {
    color: rgba(25, 39, 57, 1);
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
}

.email_input {
    padding: 8px 20px 8px 20px;
    border-radius: 4px 0px 0px 0px;
    background-color: rgba(245, 245, 245, 1);
    display: flex;
    flex-direction: column;
    gap: 10px;

    label {
        color: rgba(28, 48, 74, 0.52);
        font-family: Lato;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.01em;
        text-align: left;
    }

    input {
        color: #192739 !important;
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: left;
        border: none;
        background-color: transparent;
    }
}

.error-input {
    border: 1px solid rgba(255, 216, 216, 1) !important;
    outline: 1px solid rgba(255, 216, 216, 1) !important;
    background-color: rgba(255, 245, 245, 1) !important;
}

.send_email_button {
    padding: 8px 14px 8px 14px;
    border-radius: 4px;
    width: 100%;
    background-color: rgba(55, 191, 167, 1);
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    border: none;

    .send_email_btn {
        padding: 8px 14px 8px 14px;
        color: rgba(255, 255, 255, 1);
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
        background-color: transparent;
        color: inherit;
        border: transparent;
        width: 100%;


        &:hover {
            background-color: rgba(55, 191, 167, 1);
        }

        &:focus {
            // outline: 2px solid rgba(255, 255, 255, 1);
        }
    }
}

.return_signin {
    margin-bottom: 20%;

    a {
        text-decoration: none;
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.01em;
        text-align: right;
        color: rgba(54, 118, 224, 1);
    }
}

.alert_error {
    background-color: transparent;
    border: none;
    color: rgba(202, 21, 12, 1);
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
}

.alert_error::before {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 5px;
}

/* Ensure content takes full width on small screens */
@media (max-width: 768px) {

    .alert_error {
        display: none;
    }
}