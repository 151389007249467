.CATALOGPLP {
    max-width: 1440px;
    margin: 8px auto 60px;
    // min-height: 475px;

    .CATALOGPLP__BACK {
        display: flex;
        align-items: center;
        gap: 1em;
        margin-bottom: 8px;

        a {
            text-decoration: none;
            color: black;
            font-size: 12px;
            font-weight: 400;
            line-height: normal;
        }

        .CATALOGPLP__CURRENT {
            text-decoration: underline;
        }
    }


    .CATALOGPLP__HEADER {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 0;

        .CATALOGPLP__FILTERS {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 1em;
            margin-bottom: 10px;
        }

        span {
            color: rgba(27, 43, 65, 0.69);
            font-size: 12px;
            font-weight: 400;
        }
    }

    .CATALOGPLP__CATEGORY {
        color: #192434;
        font-size: 40px;
        font-weight: 600;
        line-height: 50.121px;
        text-transform: capitalize;
        margin-bottom: 8px;
    }

    .CATALOGPLP__GENERALFILTER {
        ul {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            gap: 16px;
            row-gap: 0;
            flex-wrap: wrap;

            li {
                flex-wrap: wrap;
                list-style: none;
                color: rgba(27, 43, 65, 0.69);
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                height: 27px;
                background-color: #FFF;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                padding: 8px 16px;
                border-radius: 4px;
                height: 32px;
                cursor: pointer;
                margin-bottom: 1em;

                &:hover {
                    color: #22A38C;
                    border-color: #22A38C;
                }
            }

            .selected {
                color: #22A38C;
                border-color: #22A38C;
                background-color: #F0FFF9;
            }
        }
    }

    .CATALOGPLP__CONTENT .infinite-scroll-component {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
    }
}