.DROPDOWN {
    max-width: calc(100% / 3 - 2em);
    width: 100%;
    color: rgba(25, 39, 57, 0.94);
    position: relative;

    h5 {
        color: rgba(25, 39, 57, 0.94);
        font-size: 14px;
        font-weight: 400;
        line-height: 18.76px;
        height: 19px;
        margin-bottom: 8px;

        span {
            color: #EE4D44;
        }
    }

    &>p {
        width: 100%;
        padding: 16px 20px;
        border-radius: 4px;
        background: rgba(25, 59, 103, 0.05);
        border: 0;
        color: rgba(25, 39, 57, 0.94);
        font-size: 14px;
        font-weight: 400;
        line-height: 18.76px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin: 0;
        z-index: 9;
    }

    .DROPDOWN__DATALIST {
        position: absolute;
        list-style: none;
        padding: 0px;
        width: 100%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        margin: 0;
        z-index: 1000;
        max-height: 280px;
        overflow: scroll;

        li {
            padding: 16px 20px;
            margin-bottom: 10px;
            margin: 0;
            cursor: pointer;

            &:hover {
                background: rgba(25, 59, 103, 0.05);
            }
        }
    }
}