.purchase_card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 16px;
    gap: 115px;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 114px;

    a {
        text-decoration: none !important;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        grid-gap: 15px;
        gap: 15px;
        color: rgba(25, 39, 57, 0.94);
    }

    .purchase_card_info {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        width: calc(50% - 57.5px);

        img {
            width: 82px;
            height: 82px;
        }

        .product_info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;

            .product_info_name {
                font-family: Lato;
                font-size: 13px;
                font-weight: 600;
                line-height: 16.29px;
                text-align: left;
                color: rgba(25, 39, 57, 0.94);
            }

            .product_info_sku {
                color: rgba(27, 43, 65, 0.69);
                font-family: Lato;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
            }
        }
    }
}

.purchase_card_price_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    // gap: 24px;
    gap: 0px;
    flex: 0 0 calc(50% - 57.5px);

    .product_price_info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 8px;
        flex: 0 0 calc(32.33% - 24px);

        .product_price_info_heading {
            color: rgba(27, 43, 65, 0.69);
            font-family: Lato;
            font-size: 13px;
            font-weight: 400;
            line-height: 15px;
            text-align: left;
        }

        .product_price_info_price {
            color: rgba(0, 0, 0, 0.8);
            font-family: Lato;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.01em;
            text-align: left;
        }
    }
}


@media(max-width: 912px) {
    .purchase_content .PURCHASEPLP__DATA .infinite-scroll-component {
        min-height: 70vh;
        justify-content: flex-start;
        padding-bottom: 110px;
    }

    .purchase_card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 8px;
        padding: 8px 16px 8px 16px;
        gap: 16px;
        background-color: rgba(255, 255, 255, 1);
        width: 100%;
        max-width: 90%;
        height: 146px;

        .vertical-line {
            border-left: 2px solid rgba(28, 55, 90, 0.16);
            height: 40px;
        }
    }

    .purchase_card .purchase_card_info img {
        width: 72px;
        height: 72px;
    }

    .purchase_card .purchase_card_info {
        .product_info {
            gap: 2px;

            .product_info_name {
                font-family: Lato;
                text-align: left;
                color: rgba(25, 39, 57, 0.94);
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.01em;
            }
        }
    }


    .purchase_card .purchase_card_info {
        width: 100%;
    }

    .purchase_card_price_info {
        width: 100%;
        justify-content: space-between;
        gap: 0px;
        // flex: 0 0 100%;

        .product_price_info {
            .product_price_info_heading {
                font-size: 12px;
                line-height: 16px;
            }

            .product_price_info_price {
                font-size: 15px;
            }
        }
    }

    .purchase_card_price_info .product_price_info {
        gap: 2px;
        flex-basis: auto;
    }

    .purchase_content .PURCHASEPLP__DATA .infinite-scroll-component {
        align-items: center;
    }
}