.filters {
    max-width: 10em;
    width: 100%;

    &:focus-within {
        box-shadow: none;
    }

    .rmsc {
        &:focus-within {
            box-shadow: none;
        }

        .dropdown-heading {
            &:focus-within {
                box-shadow: none;
            }

            &>svg {
                display: none;
            }

            .dropdown-heading-value {
                &:focus-within {
                    box-shadow: none;
                }

                span {
                    display: flex;
                    align-items: center;

                    .MS__type {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: 0;
                        background: none;
                        width: 100%;
                    }

                }
            }
        }
    }
}

@media(max-width: 912px) {
    .rmsc {
        position: relative;
        width: 150px;
        z-index: 9999;

        .dropdown-container {
            .dropdown-heading {
                min-width: 130px;
                z-index: 9999 !important;
            }

            .dropdown-content {
                position: relative;
                left: 0;
                right: 0;
                // top: 40px;
                z-index: 9999;
            }
        }
    }
    
}