.CONTENTDETAILS {
    background-color: #FFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-top: 1em;

    .CONTENTDETAILS__INFO {
        h5 {
            padding: 10px;
            background-color: #37BFA7;
            color: #FFF;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            display: flex;
            gap: 8px;
            align-items: center;
            margin: 0;
            font-size: 16px;
        }
    }

    .CONTENTDETAILS__INVOICES {
        padding: 1em;
        display: flex;
        flex-direction: column;
        padding-bottom: 0;

        hr {
            margin: 0;
        }

        .CONTENTDETAILS__TOP {
            padding: 1em;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            gap: 10px;
            margin-bottom: 1em;

            .CONTENTDETAILS__INVOICES__DOWNLOAD {
                cursor: pointer;
            }

            .CONTENTDETAILS__TOP__INVOICE,
            .CONTENTDETAILS__TOP__QTY,
            .CONTENTDETAILS__TOP__PRICETOTAL {
                // margin-right: auto;
                width: max-content;
            }

            .CONTENTDETAILS__TOP__DELIVERYDATE,
            .CONTENTDETAILS__TOP__STATUS {
                margin-left: auto;
                width: max-content;
            }

            .CONTENTDETAILS__TOP__STATUS {
                margin-left: 0;
            }

            .CONTENTDETAILS__TOP__INVOICE,
            .CONTENTDETAILS__TOP__PRICETOTAL,
            .CONTENTDETAILS__TOP__DELIVERYDATE,
            .CONTENTDETAILS__TOP__QTY {
                color: var(--text-body-light, rgba(25, 39, 57, 0.94));
                font-size: 14px;
                font-weight: 400;
                line-height: 18.76px;
            }

            .CONTENTDETAILS__TOP__PRICETOTAL,
            .CONTENTDETAILS__TOP__DELIVERYDATE,
            .CONTENTDETAILS__TOP__QTY {
                color: rgba(27, 43, 65, 0.69);
            }

            .CONTENTDETAILS__TOP__PRICETOTAL {
                color: rgba(25, 39, 57, 0.94);
                font-size: 16px;
                font-weight: 600;
                line-height: 21.44px
            }

            .CONTENTDETAILS__TOP__DELIVERYDATE {
                display: flex;
                gap: 4px;
            }

            .CONTENTDETAILS__TOP__STATUS {
                padding: 4px 10px;

                span {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16.289px;
                    text-transform: capitalize;
                }
            }

            .GREEN {
                color: #058B73;
                background-color: #E2FBE9;
                width: max-content;
            }

            .BLUE {
                color: #2179E8;
                background-color: #F2F8FF;
                width: max-content;
            }

            .MAP__CLICK {
                cursor: pointer;
            }

            .YELLOW {
                color: #EB8D00;
                background-color: #FFF7E3F0;
                width: max-content;
            }

            .RED {
                color: #CA150C;
                background-color: rgba(231, 93, 85, 0.10);
                width: max-content;
            }
        }

        p {
            margin: 0;
        }

        .CONTENTDETAILS__BOTTOM {
            display: flex;
            align-items: center;
            margin-bottom: 1em;
            gap: 1em;
            padding: 0 1em;

            .CONTENTDETAILS__INVOICES__NAMESKU {
                width: 350px;
                display: flex;
                flex-direction: column;
                gap: 4px;

                .CONTENTDETAILS__BOTTOM__NAME,
                .CONTENTDETAILS__BOTTOM__SKU {
                    color: rgba(25, 39, 57, 0.94);
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 15px;
                    text-transform: capitalize;
                }

                .CONTENTDETAILS__BOTTOM__SKU {
                    color: rgba(43, 43, 43, 0.50);
                }

                .CONTENTDETAILS__BOTTOM__NAME {
                    // height: 30px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }

            .CONTENTDETAILS__BOTTOM__PRICE,
            .CONTENTDETAILS__BOTTOM__QTY,
            .CONTENTDETAILS__BOTTOM__TOTAL {
                color: rgba(25, 39, 57, 0.94);
                text-align: right;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                text-transform: capitalize;
                width: 150px;
            }

            .CONTENTDETAILS__BOTTOM__QTY {
                color: rgba(27, 43, 65, 0.69);
            }

            .CONTENTDETAILS__BOTTOM__TOTAL {
                color: rgba(25, 39, 57, 0.94);
            }


            img {
                width: 50px;
                object-fit: contain;
            }
        }


    }
}

@media(max-width: 912px) {
    .CONTENTDETAILS {
        margin: 0;

        .CONTENTDETAILS__INFO {
            h5 {
                color: #FFF;
                font-size: 16px;
                font-weight: 600;
                line-height: 26.32px;
                text-transform: capitalize;
            }
        }

        .CONTENTDETAILS__INVOICES {
            hr {
                margin-bottom: 1em;
            }

            .CONTENTDETAILS__TOP {
                flex-wrap: wrap;
                padding: 0;
                gap: 8px;

                .CONTENTDETAILS__TOP__INVOICE {
                    order: 1;
                    width: 48%;
                    color: rgba(25, 39, 57, 0.94);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18.76px;
                }

                .CONTENTDETAILS__TOP__STATUS {
                    order: 2;
                    // width: 48%;
                    border-radius: 6px;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16.289px;
                    text-transform: capitalize;
                }

                .CONTENTDETAILS__TOP__DELIVERYDATE {
                    order: 3;
                    width: 50%;
                    color: rgba(27, 43, 65, 0.69);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15px;
                }

                .CONTENTDETAILS__TOP__QTY {
                    order: 4;
                    width: 15%;
                    color: rgba(25, 39, 57, 0.94);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: -0.12px;
                }

                .CONTENTDETAILS__TOP__PRICETOTAL {
                    order: 5;
                    width: 28%;
                    color: rgba(25, 39, 57, 0.94);
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 26.32px;
                    text-transform: capitalize;
                }
            }

            .CONTENTDETAILS__BOTTOM {
                padding: 0;
                flex-wrap: wrap;

                img {
                    max-width: 50px;
                    max-height: 50px;
                    width: 100%;
                    object-fit: contain;
                    order: 1;
                }

                .CONTENTDETAILS__INVOICES__NAMESKU {
                    order: 2;
                    width: 75%;
                    gap: 8px;

                    .CONTENTDETAILS__BOTTOM__NAME {
                        font-weight: 500;
                    }
                }

                .CONTENTDETAILS__BOTTOM__PRICE {
                    order: 3;
                    width: 25%;
                    margin-left: 66px;
                    text-align: left;
                }

                .CONTENTDETAILS__BOTTOM__QTY {
                    order: 4;
                    width: 15%;
                    text-align: left;
                }

                .CONTENTDETAILS__BOTTOM__TOTAL {
                    color: #22A38C;
                    font-weight: 500;
                    order: 5;
                    width: 25%;
                    text-align: left;
                }
            }
        }
    }
}