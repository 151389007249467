.compaign-product-card {
  overflow-x: hidden;
  width: 100%;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .compaign-product-card {
    margin-top: 0;
  }
}

containerdata {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 1320px;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-right: auto;
  margin-left: auto;
}
.banner img {
    width: 100%; // Make the image take the full width
    height: auto; // Maintain aspect ratio
    object-fit: cover; // Cover the entire area without distortion

  }
  .PRODUCTPLP .PRODUCTPLP__DATA.my_product_section .PRODUCTPLP__DATA .PRODUCTCARD {
    width: 276px;
}
/* src/pages/Productcard/CampaignSidebar.scss */

/* src/pages/Productcard/CampaignSidebar.scss */

/* src/pages/Productcard/CampaignSidebar.scss */

/* src/pages/Productcard/CampaignSidebar.scss */

.PRODUCTPLP__SIDEBAR {
  max-width: 100%;
  padding: 0px;
  background-color: white;
  border-radius: 5px;

  .PRODUCTPLP__MOBILE__SIDEBAR {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    padding-top: 5px;
    background-color: white;

    &>span {
      max-width: 36px;
    }

    .offcanvas {
      height: 80vh;

      .offcanvas-header {
        background: linear-gradient(0deg, #E4E4E4 0%, #E4E4E4 100%), #FFF;
        color: #192434;
        font-size: 18px;
        font-weight: 600;
        line-height: 22.554px;
        text-transform: capitalize;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }

      .offcanvas-body {
        background-color: #FFF;

        ul {
          padding: 0;
          list-style: none;
          display: flex;
          align-items: center;
          gap: 10px;
          flex-wrap: wrap;

          li {
            color: rgba(25, 39, 57, 0.94);
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            border-radius: 4px;
            background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
            padding: 10px 16px;
          }

          .selected,
          .selectedFilter {
            color: #22A38C;
            border-color: #22A38C;
            background-color: #F0FFF9;
          }
        }

        .offcanvas__buttons {
          position: fixed;
          bottom: 1em;
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 100%;
          gap: 2.5em;
          margin: 0 auto;

          .offcanvas__reset,
          .offcanvas__apply {
            width: 150px;
            padding: 16px;
            border-radius: 32px;
            border: 1px solid rgba(28, 52, 84, 0.26);
            color: rgba(27, 43, 65, 0.69);
            font-size: 18px;
            font-weight: 500;
            line-height: 22.554px;
            text-transform: capitalize;
          }

          .offcanvas__apply {
            background-color: #37BFA7;
            color: #FFF;
          }
        }
      }
    }

    .PRODUCTPLP__MENU {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      white-space: nowrap;
      overflow: auto;
      // overflow-y: hidden;
      padding-bottom: 0;
      scroll-behavior: smooth;

      .selectedMobileMenu {
        position: relative;
        color: #22A38C;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0px;
          border-bottom: 2px solid #22A38C;
        }

      }

      span {
        height: 40px;
        padding: 0 10px;
      }
    }

    svg {
      padding: 1em;
      color: black;
    }

    span {
      white-space: nowrap;
    }
  }

}

.filter-header {
  display: flex; /* Flexbox for layout */
  align-items: center; /* Center items vertically */
  margin-bottom: 10px; /* Space below the header */
}

.filter-header img {
  margin-right: 8px; /* Space between icon and text */
}

.filter-body {
  padding: 10px 0; /* Padding for body */
  overflow-x: auto; /* Enable horizontal scrolling */
}

.category-list {
  display: flex; /* Use flexbox for horizontal layout */
  white-space: nowrap; /* Prevent wrapping */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  list-style-type: none; /* Remove default list styles */
}

.category-list li {
  padding: 5px 10px; /* Space around each item */
  cursor: pointer; /* Pointer cursor for list items */
  min-width: 100px; /* Minimum width for each category item */
  text-align: center; /* Center text */
}

.selectedFilter {
  color: #22A38C;
}

  .main-menu {
    width: 246px;
    margin-right: 20px;
    border-radius: 20px;
    .PRODUCTPLP__SIDEBAR {
     width: 276px;;
      width: 100%;
      background-color: white;
      border-radius: 8px;
      padding: 16px 0px 16px 0px;
    }

    .accordion {
      .accordion-item {
        border: none;
        width: 232px;
        .accordion-collapse {
          .accordion-body {
            ul {
              text-decoration: none;
              li {
                cursor: pointer;
                padding:10px;
              }
              li::marker {
                content: "" !important;
              }
              .selectedFilter {
                color: #22a38c;
              }
              .all_category {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: rgba(25, 36, 52, 1);
                padding: 12px 16px 12px 16px;
              }
        
              .all_category.selectedFilter {
                color: #22A38C;
              }
            }
          }
        }
      }
    }
  }

  .filter-menu {
    //display: flex;
    //justify-content: space-around;
    // width: 100%;
    margin-bottom: 10px;
    color: #6f6b6b;
    margin-left: 18%;
    margin-top: 10px;
  }
  .filter-menu .button {
    color: #898484;
  }
  .menu-icon {
    display: none;
  }
  .menu-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  .close-modal {
    display: none;
  }
  .main-and-products {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top:30px;
    width: 100%;
    .category-heading {
      padding: 10px 20px;
      color: rgba(27, 43, 65, 0.69);
   
    }
    .campaign-products-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 20px;
      width: calc(100% - 246px);
    }
    .infinite-scroll-component {
      display: flex;
      flex-wrap: wrap;
      gap: 2px;
    }
    .PRODUCTCARD {
      height: 354px;
      width: 248px;
      background-color: #fff;
      border-radius: 6px;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      /* padding: 27px; */
      margin: 4px;

    }

    .newCardSkeleton span span {
      height: 354px;
      width: 248px;
      margin: 4px;
  }

    .campaign-product-price {
      color: #37bfa7;
    }
  }

button {
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background: none;
}

.btndata {
  background: #37bfa7;
  color: #fff;
  margin: 0px;
}

.search_box_wrapper {
  width: 420px;
  display: flex;
  margin-top: 12px;
  padding: 6px;
  border-radius: 5px;
  background: var(--Color-Grey-5, #193b670d);
}
.search_input {
  width: 90%;
  padding: 5px 12px;
  border: none;
  background: transparent;
  font-size: 12px;
}
.search_btn {
  width: 9%;
  border: 1px solid #37bfa7;
  background: #37bfa7;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
}
.search_input:focus {
  outline: none;
}
.compaign-product-card .input-group {
  position: relative;
  display: flex;
  width: 29%;
  border: none;
  margin-top: 20px;
  //padding: 2px 9px;
  border-radius: 4px;
  margin-top: 2px;

  margin-right: 3px;

  .form-control {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .input-group-btn {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    outline: none;
    padding: 1px 11px;
    margin-top: 0px;
    height: 100%;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    background-color: #37bfa7;
    i.fa {
      color: #ffffff;
    }
  }
}
.compaignspan-text {
  font-size: 14px;
}
.compaign-product-card .dropdownMenu .dropdownItem {
  overflow: none;
}
.dropdownMenu {
  width: 236px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .dropdownItem {
    display: block;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-bottom: 1px solid #eee;

    &:hover {
      background-color: #f0f0f0;
    }
    &:active {
      background-color: #f0f0f0;
    }
    &.active {
      background-color: #f5f5f5 !important;
      color: #333;
      font-weight: bold;
    }
  }

  .menu-button {
    display: none;
  }
  .PRODUCTPLP
    .PRODUCTPLP__DATA
    .PRODUCTPLP__CONTENT
    .PRODUCTPLP__DATA
    .infinite-scroll-component {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 17px;
    gap: 17px;
  }
  .PRODUCTPLP
    .PRODUCTPLP__DATA.my_product_section
    .PRODUCTPLP__DATA
    .PRODUCTCARD {
    width: 276px;
  }


  .main-menu .PRODUCTPLP__SIDEBAR {
    width: 236px;
    // border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin-right: 10px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
}

@media (max-width: 1024px) {
  .compaign-product-card .main-and-products {
    flex-direction: column;
    margin-top: 20px;
    .main-menu .PRODUCTPLP__SIDEBAR {
      width: 236px;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 10px;
      text-align: center;
      background-color: #fff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
    }

    .campaign-products-container {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
    }

    .PRODUCTCARD {
      width: 45%;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 768px) {
 
  .campaign-products-container {
    background-color: #f6f3f3fc;
    margin-top: 20px;
  }
  .campaign-products-container .PRODUCTPLP__DATA.my_product_section {
    width: 100%;
  }
  .compaign-product-card .main-and-products .main-menu .PRODUCTPLP__SIDEBAR {
    width: 342px;
    // border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin-right: 10px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // position: relative;
}
.infinite-scroll-component .PRODUCTCARD a img {
  min-height: 144px;
  max-height: 144px;
}
.PRODUCTCARD .PC__CONTENT {
  max-height: 143px;
}
.infinite-scroll-component .PRODUCTCARD {
  max-width: calc(96% / 2);
}
.main-and-products .infinite-scroll-component .newCardSkeleton.mobilebox{
  max-width: calc(95% / 2);
  width: 48%;
  margin: 1%;
  height: 50%;
}
  .compaign-product-card .header-text {
    width: 94%;
    background: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
    /* margin: 12px; */
    margin-left: 10px;
    margin-right: -10px;
    /* position: absolute; */
    position: relative;
    top: -100px;
    margin-bottom: -100px;

  }
  .compaign-product-card .main-and-products .PRODUCTCARD {
    width: 48%;
    margin: 1%;
    height: 50%;
  }
  .compaign-product-card .main-and-products {
    margin-top:0px;

  }
  .menu-modal {
    align-items: flex-end;
  }
  .containerdata {
    overflow: hidden; // Ensure content does not overflow the border
}

.banner {
    width: 100%; // Ensure the banner takes the full width
    overflow: hidden; // Hide any overflow
    // border-bottom: 2px solid #000; // Optional: Add a bottom border to the banner
}

.banner img {
    width: 100%; // Make the image take the full width
    height: auto; // Maintain aspect ratio
    object-fit: cover; // Cover the entire area without distortion
}
  .compaign-product-card .banner img {
    width: 100%;
    height: auto;
    // margin-top: 1.7%;
    // border-radius: 6px;
    position: relative;
  }
  .menu-content {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }
  .filter-menu2 button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #f8f8f8;
    text-align: left;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }
  .search_box_wrapper {
    width: 357px;
    display: flex;
    padding: 6px;
    border-radius: 5px;
    background: var(--Color-Grey-5, #193b670d);
  }
  .compaign-product-card .main-and-products .main-menu {
    width: 100%;
    // margin-top: 157px;
  }
  .compaign-product-card .search_btn {
    width: 14%;
    height:38px;
    margin: 5px 0;
  }
  .filter-menu2 button.selected {
    background-color: #e0ffe0;
    font-weight: bold;
  }

  .filter-menu2 button:last-child {
    border-bottom: none;
  }
  .filter-grid {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }

  .compaign-product-card .main-menu {
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #f0f0f0;
  }
  .menu-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .menu-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    width: 500px;
  }

  .filter-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
  }

  .filter-item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    position: relative;
    background-color: #f9f9f9;
  }

  .menu-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    text-align: center;
  }

  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: white;
  }

  .compaign-product-card .dropdownMenu {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    border: none;
    background-color: none;
    box-shadow: none;
  }
  .compaign-product-card .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    margin-top: 20px;
  }
  .compaign-product-card .input-group-btn {
    width: 42px;
    height: 31px;
    margin: 1px;
  }
  .compaign-product-card .filter-menu {
    display: none;
  }
  .PRODUCTCARD .PC__CONTENT .PC__NAME {
    height: 38px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20.2px;
    margin-bottom: 7px;
  }
  .compaign-product-card .menu-icon {
    display: block;
  }
  .category-dropdown {
    display: none !important;
  }
 
  @media (max-width: 480px) {
    .menu-button {
      display: block;
    }
  
    .compaign-product-card .input-group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
      margin-top: 20px;
    }
    .compaign-product-card .main-and-products .PRODUCTCARD {
      max-width: calc(95% / 2);
      
    }
    .compaign-product-card .menu-icon {
      display: block;
    }
    .main-menu .PRODUCTPLP__SIDEBAR {
      // width: 236px;
      // border: 1px solid #ddd;
      border-radius: 8px;
      padding: 10px;
      text-align: center;
      background-color: #fff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
    }
    .compaign-product-card
      .main-and-products
      .PRODUCTCARD
      .campaign-product-price {
      color: #e8169b;
    }
    .PRODUCTCARD .PC__CONTENT .PC__PRICE {
      font-size: 16px;
      font-weight: 700;
      line-height: 22.55px;
      margin: 0;
    }
  }
}

.containerdata {
    border-radius: 8px; // Optional: Add rounded corners
    overflow: hidden; // Ensure content does not overflow the border
}
