.newCardSkeleton {
    z-index: 0;

    span {
        span {
            height: 368px;
            width: 276px;
        }
    }
}


.newCardSkeleton.mobilebox {
    z-index: 0;
    width: 100%; // Ensure this is set to 100%
    box-sizing: border-box; // Ensures padding and borders are included in the width calculation
    max-width: 47%;
    span {
        span {            
            height: 256px !important;
            width: 100% !important;
        }
    }
}

// .mobilebox {
//     width: 100%; // Ensure the mobile version also takes full width
//     box-sizing: border-box; // Ensures padding and borders are included in the width calculation
//     z-index: 0;

//     span {
//         span {
//             height: 256px !important;
//             width: 47% !important;
//         }
//     }
// }