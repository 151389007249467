.ORDERTAKING__DATES {
  // display: flex;
  // gap: 8px;
  // align-items: center;
  // justify-content: flex-start;


  &>div {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;

    .ORDERINGCARDS__DATES {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      span {
        color: #1B2B41B0;
        white-space: nowrap;
        border: 1.5px solid #1C375A29;
        border-radius: 6px;
        padding: 8px;
        background: #fff;
        display: block;
        font-size: 14px;
        line-height: 18.76px;
        cursor: pointer;

        &.active {
          border-color: #37BFA7;
          color: #22A38C;
        }
      }

      &.disabled {
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }
}


.ORDERTAKING__DATES {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;


  .ORDERINGCARDSS {
    // color: rgba(27, 43, 65, 0.69);
    padding: 8px 8px;
    border-radius: 8px;
    border: 1px solid rgba(28, 55, 90, 0.16);
    background: #FFF;
    cursor: pointer;


    color: rgba(0, 33, 23, 1);
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;

  }

  .ORDERINGCARDSS.selected {
    color: #22A38C;
    border-color: #37BFA7;
  }

  .ORDERINGCARDSS.featured {
    color: rgba(28, 48, 74, 0.52);
    background: #FDF9E5;
  }

  .ORDERINGCARDSS.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}