$seafoam: #37bfa7;

.MOBILE__ADDRESSFORM {
    .MAF__BACK {
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 16px;

        span {
            color: #000;
            font-size: 22px;
            font-weight: 600;
            line-height: 27.567px;
            text-transform: capitalize;
        }
    }

    .MAF__TAB {
        padding: 0;
        display: flex;
        list-style: none;
        align-items: center;
        gap: 24px;
        margin: 16px;

        li {
            color: rgba(27, 43, 65, 0.69);
            font-size: 14px;
            font-weight: 400;
            line-height: 18.76px;
        }

        .MAF__SELECTION {
            color: #22A38C;
            position: relative;

            &::after {
                content: "";
                border-bottom: 3px solid #37BFA7;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -19px;
            }
        }
    }

    hr {
        margin: 0 16px
    }

    .MAF__CONTACT {
        margin: 0 16px;
        margin-top: 24px;

        h3 {
            color: #000;
            font-size: 18px;
            font-weight: 600;
            line-height: 22.554px;
            text-transform: capitalize;
        }

        .MAF__CONTACT__FORM {
            overflow: auto;
            margin-bottom: 60px;

            .FORMINPUTS {
                max-width: 100%;
                width: 100%;
                margin-bottom: 24px;
            }
        }
    }

    .MAF__ADDRESS {
        margin: 0 16px;


        .MAF__ADDRESS__LOCATION {
            border-radius: 6px;
            border: 1px solid rgba(28, 55, 90, 0.16);
            background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
            padding: 4px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
            height: 52px;
            margin-top: 24px;

            span {
                color: rgba(27, 43, 65, 0.69);
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.16px;
            }
        }

        .MAF__ADDRESS__FORM {

            .DROPDOWN,
            .FORMINPUTS {
                max-width: 100%;
                width: 100%;
                margin-bottom: 24px;
            }
        }

        @mixin label {
            input {
                width: 100%;
                padding: 16px 20px;
                border-radius: 4px;
                background: rgba(25, 59, 103, 0.05);
                border: 0;
                color: rgba(25, 39, 57, 0.94);
                font-size: 14px;
                font-weight: 400;
                line-height: 18.76px;
            }

            h5 {
                color: rgba(25, 39, 57, 0.94);
                font-size: 14px;
                font-weight: 400;
                line-height: 18.76px;
                height: 19px;
                margin-bottom: 8px;

                span {
                    color: #EE4D44;
                }
            }
        }

        .MAF__LOCATE {
            margin: 2em 0;

            @include label;

            label {
                width: 100%;
                margin-bottom: 24px;
            }

            p {
                height: 56px;
                margin: 0;
                color: rgba(28, 52, 84, 0.26);
                font-size: 16px;
                border-radius: 32px;
                border: 1px solid rgba(28, 52, 84, 0.26);
                padding: 4px 16px;
                font-weight: 500;
                line-height: 24px;
                display: flex;
                gap: 8px;
                align-items: center;
                justify-content: center;
                width: 138px;
                cursor: pointer;
            }
        }

        .MAF__LATLNG {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 1em;

            p {
                color: rgba(27, 43, 65, 0.69);
                font-size: 14px;
                font-weight: 500;
            }

            span {
                color: #192739;
                font-size: 22px;
                font-weight: 600;
                line-height: 27.567px;
                text-transform: capitalize;
            }
        }

        .offcanvas {
            z-index: 99999;
            height: 70vh;

            .offcanvas-header {
                display: none;
            }

            .offcanvas-body {
                h5 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            .HOURS__MINS {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                gap: 10px;
                margin-top: 10em;

                ul {
                    padding: 0;
                    list-style: none;
                    max-height: 50px;
                    overflow: auto;
                    margin: 0;

                    li {
                        color: #000;
                        font-size: 26px;
                        font-weight: 400;
                        line-height: normal;
                        width: 50px;
                    }
                }
            }
        }

        .MAF__PREFERED {
            margin-top: 1em;
            margin-bottom: 150px;

            .MAF__PREFERED__LABEL {
                display: flex;
                align-items: center;
                gap: 1em;
                justify-content: center;

                &>* {
                    border-radius: 8px;
                    padding: 6px 16px;
                    background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 4px;
                    max-width: 192px;
                    width: 100%;

                    span {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;

                        small {
                            color: rgba(28, 48, 74, 0.52);
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 16.289px;
                            text-transform: capitalize;
                        }

                        span {
                            color: #22A38C;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 18px;
                            text-transform: capitalize;
                        }

                    }
                }
            }
        }
    }



    .MAF__SAVE {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 56px;
        margin: 16px;
        background-color: #37BFA7;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 20px;
        border-radius: 4px;
        z-index: 9999;

        p {
            margin: 0;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            text-transform: capitalize;
        }
    }

    .AF__form__preference {
        margin-top: 2em;
        display: flex;
        flex-direction: column;

        .AF__delivery_ptimelabel {
            color: rgba(25, 39, 57, 0.94);
            font-size: 18px;
            font-weight: 600;
            line-height: 22.554px;
            text-transform: capitalize;
        }

        .AF__delivery__left {
            display: flex;
            align-items: unset;
            gap: 8px;
            margin: 24px 0;

            img {
                max-height: 24px;
                max-width: 24px;
                height: 100%;
                width: 100%;
                object-fit: contain;
            }

            p {
                margin: 0;

                ul {
                    padding: 0;
                    display: flex;
                    gap: 10px;
                    margin: 1em 0;
                    margin-left: -2em;

                    li {
                        list-style: none;
                        color: #1C345442;
                        font-weight: 400;
                        line-height: 18.76px;
                        font-size: 14px;
                        border: 1px solid #1C345442;
                        border-radius: 8px;
                        height: 40px;
                        width: 54px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }

                    .AF__delivery__date__selected {
                        color: $seafoam;
                        border-color: $seafoam;
                    }
                }

                p {
                    margin: 0;
                }
            }
        }

        .AF__delivery__right {
            width: 100%;
            margin-bottom: 120px;

            img {
                max-height: 18px;
                max-width: 18px;
                height: 100%;
                width: 100%;
                margin-right: 8px;
                object-fit: contain;
            }

            &>p {
                margin: 0;
                display: flex;
                gap: 10px;
                margin-top: 16px;
            }

            .AF__From__delivery_ptime,
            .AF__To__delivery_ptime {

                display: flex;
                gap: 8px;
                flex-direction: column;
                max-width: 126px;
                width: 100%;

                span {
                    color: rgba(27, 43, 65, 0.69);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18.76px;
                    /* 18.76px */
                }

                p {
                    line-height: 18.76px;
                    font-size: 14px;
                    color: #1B2B41B0;
                    font-weight: 400;
                    display: flex;
                    gap: 16px;


                    .from__hours,
                    .from__mins,
                    .to__hours,
                    .to__mins {
                        background: #F5F7FA;
                        color: #494E50;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        border-radius: 4px;
                        border: 1px solid #E5E7F0;

                        &:focus-visible {
                            outline: 0;
                        }
                    }
                }
            }
        }
    }

    .MAF__BUTTONS {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 24px 16px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        background-color: #FFF;

        p,
        a {
            margin: 0;
            border-radius: 4px;
            padding: 16px 20px;
            height: 56px;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-transform: capitalize;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .MAF__BUTTON__CANCEL {
            background: rgba(28, 52, 84, 0.26);
            text-decoration: none;
        }

        .MAF__BUTTON__SUBMIT {
            background-color: #37BFA7;
        }
    }
}