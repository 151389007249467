$seafoam: #37bfa7;
.datePickers {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    border-radius: 6px;
    border: 1px solid rgba(28, 52, 84, 0.26);
    padding: 6px 10px;
    gap: 10px;
    background-color: #FFF;

    .react-datepicker__tab-loop{
        margin-left: -10px;
    }

    .react-datepicker-wrapper {
        width: unset !important;
    }

    &>span {
        color: rgba(25, 39, 57, 0.94);
        font-size: 13px;
        font-weight: 500;
        line-height: 16.289px;
        letter-spacing: 0.39px;
    }

    input {
        width: 107px;
        height: 26px;
        border-radius: 8px;
        background: rgba(54, 145, 252, 0.15);
        outline: 0;
        border: 0;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        padding: 4px 8px;
        color: rgba(54, 118, 224, 1)
    }

    .react-datepicker-popper {
        max-width: 393px;
        width: 100%;
    }
    .custom-input {
        pointer-events: none;
      }
    .react-datepicker__navigation {
        display: none;
    }

    .react-datepicker__month-container,
    .react-datepicker__header {
        background-color: white;
        border-bottom: 0;
        float: unset;
        padding: 1em;
    }

    .react-datepicker__children-container {
        width: 100%;
    }

    .datepicker__footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1em;
        margin-bottom: 2em;
        margin-right: 2.5em;

        button {
            border: 0;
            outline: 0;
            border-radius: 9px;
            background: #007AFC;
            color: white;
            padding: 4px 12px;
            font-size: 14px;
        }

        .DP__footer__clear {
            background-color: #F8F8F8;
            color: #8A8A8A;
        }
    }

    .react-datepicker__day-name {
        width: 46.429px;
        height: 38.286px;
        color: rgba(60, 60, 67, 0.60);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        height: 16px;
    }

    .react-datepicker__day {
        width: 46.429px;
        height: 38.286px;
        font-size: 16px;
        font-weight: 400;
        line-height: 40px;
        color: #000;
        text-align: center;
        vertical-align: middle;
        ;
    }

    .react-datepicker__day--selected {
        // color: #000;
        border-radius: 8px;
        // background: linear-gradient(0deg, rgba(54, 145, 252, 0.15) 0%, rgba(54, 145, 252, 0.15) 100%), #FFF;
        background: $seafoam;
        color: white;
        text-align: center;
        vertical-align: middle;
    }

    .react-datepicker__day--keyboard-selected {
        background: linear-gradient(0deg, rgba(54, 145, 252, 0.15) 0%, rgba(54, 145, 252, 0.15) 100%), #FFF;
        color: #000;
        // background: $seafoam;
        // color: white;
    }

    .react-datepicker__header__dropdown {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 1em;

        .react-datepicker__month-select,
        .react-datepicker__year-select {
            border: 0;
            font-size: 18px;
            font-weight: 600;
            line-height: 22.554px;
            background-color: #FFF;
            width: max-content;
            outline: 0;

            option {
                height: 32px;
            }
        }
    }

    .react-datepicker__current-month {
        text-align: center;
        display: none;
    }
}