$seafoam: #37bfa7;

.pdpimagecarousel {
    // padding: 0 2em;

    &>div {
        // max-width: 90%;
        // margin: 0 auto;
    }

    .slick-slider {
        // width: 100%;
        margin: 0px;
        flex: 0 0 80px;
    }

    .slick-list {
        // margin: 0 24px;
        margin: 0px;
    }

    .slick-track {
        margin: 0px;
        width: 100% !important;
    }

    .slick-initialized .slick-slide {
        width: 100% !important;
        margin: 0px !important;
    }

    &>img {
        min-height: 470px;
        min-width: 470px;
        max-height: 470px;
        max-width: 470px;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .pdpimagecarousel__selected {
        border: 1px solid $seafoam;
        border-radius: 8px;
    }

    .pdpimagecarousel__prevArrow,
    .pdpimagecarousel__nextArrow {
        position: absolute;
        background-color: $seafoam;
        width: 1.5em;
        height: 1.5em;
        border-radius: 20px;
        color: white;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 2.5em;
        cursor: pointer;
    }

    .pdpimagecarousel__nextArrow {
        right: -26px !important;
    }

    .pdpimagecarousel__prevArrow {
        left: -26px !important;
    }

    .slick-disabled {
        display: none;
    }

    .pdpimagecarousel__slider {
        margin: 1em 0;
        height: 100%;
        max-width: 82px;
        max-height: 82px;
        max-width: 82px;
        min-height: 82px;
        width: 100%;
        object-fit: contain;
    }

    // .pdpimagecarousel__wishfav {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     margin: 24px 24px 0;

    //     p {
    //         display: flex;
    //         align-items: center;
    //         gap: 10px;
    //         justify-content: center;
    //         width: 100%;
    //         margin: 0;
    //         cursor: pointer;
    //         text-align: center;

    //         span {
    //             color: #000;
    //             height: 21px;
    //             font-size: 14px;
    //         }
    //     }

    //     .favorite {
    //         border-right: 1px solid #CCC;
    //         height: 24px;
    //     }

    //     img {
    //         max-width: 24px;
    //         max-height: 24px;
    //         height: 100%;
    //         width: 100%;
    //         object-fit: contain;
    //     }
    // }
}

.pdpimagecarousel__wishfav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    // margin: 24px 24px 0;
    position: relative;
    bottom: 97px;
    left: 188px;
    margin-bottom: -97px;

    .view.active {
        background-color: rgba(207, 238, 223, 1);
    }

    .favorite.active {
        background-color: rgba(207, 238, 223, 1);
    }

    div {
        background-color: rgba(241, 244, 241, 1);
        padding: 6px 16px 6px 8px;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    p {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        width: 100%;
        margin: 0;
        cursor: pointer;
        text-align: center;

        span {
            color: #000;
            height: 21px;
            font-size: 14px;
        }
    }

    .favorite {
        // border-right: 1px solid #CCC;
        // height: 24px;
    }

    img {
        max-width: 24px;
        max-height: 24px;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

@media(max-width: 1024px) {
    .pdpimagecarousel {
        &>img {
            max-width: 100%;
        }
    }
}

@media(max-width: 912px) {
    .pdpimagecarousel {
        .slick-dots li.slick-active button:before {
            color: black;
        }

        .MOBILE__IMAGE {
            position: relative;

            .slick-arrow {
                display: none !important;
            }

            svg {
                position: absolute;
                top: 1em;
                right: 1em;
                margin: 5px;
                padding: 5px;
                border-radius: 8px;
                border-radius: 32px;
                background: rgba(0, 0, 0, 0.52);
                padding: 10px;
                opacity: .85;
            }

            img {
                max-width: 428px;
                max-height: 428px;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .slick-track {
            display: -webkit-box;
        }

        .slick-dots li button:before {
            color: grey;
            width: 12px;
            height: 12px;
        }

        .pdpimagecarousel__wishfav {
            gap: 1em;
            flex-direction: column;

            p {
                justify-content: center;
            }
        }

        .slick-list {
            margin: 0;

            .slick-slide {
                max-width: 100% !important;
            }
        }

        &>div {
            max-width: 100%;
            margin: 0;
            width: 100%;
        }

        h5 {
            text-align: left;
            width: 100%;
            color: #000;
            font-size: 22px;
            font-weight: 600;
            line-height: 27.567px;
            text-transform: capitalize;
        }

        .pdpdetails__quotesku {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &>p {
                margin: 0;
                color: rgba(43, 43, 43, 0.50);
                font-size: 13px;
                font-weight: 400;
                line-height: 15px;
                text-transform: capitalize;
            }

            .reaquest__button {
                color: #22A38C;
                font-size: 14px;
                font-weight: 400;
                line-height: 18.76px;
                text-transform: capitalize;
            }
        }
    }

    .pdpimagecarousel__wishfav {
        bottom: 97px;
        left: 0px;
        justify-content: flex-start !important;
        margin-left: 20px !important;
        margin-bottom: -35px;
    }

}

@media(max-width: 786px) {
    .pdpimagecarousel {
        display: flex;
        flex-direction: column;
        align-items: center;

        .pdpimagecarousel__wishfav {
            width: 100%;
        }

        .slick-dots {
            position: static;
        }
    }
}