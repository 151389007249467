.Drawer .Drawer__checkbox {
    display: none;
  }
  .Drawer .Drawer__checkbox:checked ~ .Drawer__overlay {
    display: block;
    opacity: 1;
  }
  .Drawer .Drawer__checkbox:checked ~ .Drawer__container {
    visibility: visible;
    transform: translate3d(0, 0, 0) !important;
  }
  .Drawer .Drawer__overlay {
    display: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .Drawer .Drawer__container {
    position: fixed;
    visibility: hidden;
    background: white;
    transition: all;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  }
  
  .Drawer .Drawer_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background: #1b4332;
  }
  
  .Drawer .Drawer_title {
    font-size: 1.2vw;
    color: #fff;
    font-weight: 600;
  }
  
  .Drawer .Drawer_close {
    background: transparent;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
  }
  
  .Drawer .Drawer_content {
    padding: 1rem;
    height: 100%;
    overflow: scroll;
  }
  