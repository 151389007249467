.TIMER {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: rgba(255, 243, 141, 1);
    padding: 8px 16px;
    border-radius: 8px;
    margin-bottom: 24px;

    p {
        display: flex;
        flex-direction: column-reverse;
        gap: 4px;
        margin: 0;
        text-align: center;
        width: 36px;
    }

    .TIMER__LABEL {
        color: #192434;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.12px;
    }

    .TIMER__VALUE {
        color: #EBF3FE;
        font-size: 14px;
        font-weight: 500;
        line-height: 18.76PX;
        background: #192434;
        padding: 4px;
    }

    .TIMER__COLON {
        position: relative;
        top: -10px;
    }

    .TIMER__HURRYUP {
        display: flex;
        flex-direction: column;
        width: 100%;

        img {
            width: 32px;
            height: 32px;
            object-fit: contain;
            margin-right: 4px;
        }

        span {
            // margin-right: 16px;
            text-align: left;
            &:first-child {
                color: rgba(240, 72, 0, 1);
                font-size: 18px;
                font-weight: 700;
                line-height: 16.289px;
                text-transform: capitalize;
            }

            &:last-child {
                color: rgba(27, 43, 65, 0.69);
                font-size: 10px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: -0.1px;
            }
        }
    }
}