.PC__small {
    height: 227px;
    /*width: 227px;*/
    width: calc(90% - -8px);
    background-color: #FFF;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    .country-info {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 4px 1px 0px;
        grid-gap: 6px;
        gap: 6px;
        background: #CEE9DC;
        border-radius: 16px;
        position: relative;
        width: auto;
        max-width: max-content;
        margin-left: 0;
        top: 0;
        left:10px;
        .country-flag {
            width: 18px;
            height: auto;
            margin-right: 0;
            max-width: 10px;
            max-height: 10px;
            left: 0;
            vertical-align: middle;
            min-width: 15px;
            min-height: 19px;
            position: inherit;
            top: 0;
            padding: 0;
            margin-left: 5px;
        }
        .country-name {
            width: 16px;
            height: 18px;
            /* font-family: 'Inter', sans-serif; */
            /* font-style: normal; */
            font-weight: 800;
            font-size: 11px;
            line-height: 18px;
            text-align: center;
            text-transform: uppercase;
            color: #166B54;
            flex: none;
            /* order: 1; */
            flex-grow: 0;
        }
    }
    a {
        position: relative;

        .CARD__PREORDER {
            position: absolute;
            bottom: 16px;
            left: 16px;
            color: #22A38C;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.12px;
            padding: 4px 8px;
            border-radius: 20px;
            background: #DFFDE9;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
        }
    }
    .country-info {
        display: flex;
        align-items: center;
      }
      
      .country-flag {
        width: 20px;
        height: auto;
        margin-right: 5px;
      }
      
      .country-name {
        font-size: 12px;
        font-weight: 800;
      }
      
    .ORDERTAKING__CALENDAR {
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 999;
    }

    .CART__NOSTOCK {
        filter: grayscale(100%);
    }

    img {
        height: 100%;
        width: 100%;
        cursor: pointer;
        object-fit: contain;
    }

    .QUOTE__PRODUCT {
        background-color: #F0FFF9;
        position: relative;
        height: 70px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        // padding-top: 5px;

        .QUOTES {
            position: absolute;
            // right: 10px;
            // bottom: 7px;
            max-width: 16px;
            width: 16px;
            height: 16px;
            object-fit: contain;
            top: 33px;
            left: 7px;
        }
    }

    .CATALOG__PRODUCT {
        height: 52px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: white;
        // padding-top: 5px;
    }

    .HOVERED__CARD__FAV {
        position: absolute;
        right: 14px;
        top: 16px;
        cursor: pointer;
        max-width: 24px;
        max-height: 24px;
        object-fit: contain;
        z-index: 99;
    }

    .HOVERED__CARD__ADDCART {
        position: absolute;
        right: 16px;
        top: 40%;
        cursor: pointer;
        max-width: 32px;
        max-height: 32px;
        height: 100%;
        width: 100%;
        object-fit: contain;
        z-index: 99;
    }

    .HOVERED__NAME {
        color: rgba(25, 39, 57, 0.94);
        font-size: 13px;
        font-weight: 600;
        line-height: 16.29px;
        letter-spacing: -0.28px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 0 10px;
        height: 31.56px;
    }

    .HOVERED__PRICE {
        padding: 0 10px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;
        color: #22A38C;
        position: relative;
        top: 3px;
        // left: 13px;
        // margin-left: 7px;
    }
  
    .HOVERED__PRICE .QUOTES{
        padding: 0 10px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;
        color: #22A38C;
        position: relative;
        top: 3px;
        left: 20px;
    }
    .quation_price{
        display: flex;
        justify-content: space-between;
    }
}

.PC__small {
    img.snow-img + .country-info{
        left: 4px;
    }
}

.HOVERED__CONTENT {
    padding-bottom: 7px;

    img {
        max-height: 140px;
        object-fit: contain;
    }
    img.snow-img {
        max-width: 20px;
        max-height: 20px;
        position: relative;
        top: 0;
        left: 0;
    }
    img.quote-img {
        max-width: 20px;
        max-height: 20px;
        position: absolute;
        bottom: 18px;
        right: 16px;
    }
    .CARD__PREORDER+img.snow-img {
        left: 5px;
    }
}

.HOVERED__NAME {
    display: block;
    padding: 0 7px
}

.NOT__HOVERED {
    display: none !important;
}

.PC__small .product_btn_wrapper{
    display: flex; // Add this line
    align-items: center; // Align items vertically centered
    gap: 10px;
    // top: -5px;
}
.PC__small .product_btn_wrapper .CARD__PREORDER {
    position: relative;
    bottom: 0;
    left: 10px;
    max-height: 20px;
    vertical-align: middle;
    padding: 2px 8px 4px 8px;
    background: #DFFDE9;
    font-size: 10px;
    color: #22A38C;
    line-height: 16px;
    font-weight: 500;
    border-radius: 4px;
}
.HOVERED__CONTENT .product_btn_wrapper img.snow-img {
    max-width: 20px;
    max-height: 20px;
    // position: absolute;
    bottom: 0px;
    left: 10px;
    vertical-align: middle;
    min-width:20px;
    min-height: 20px;
}
.HOVERED__CONTENT .product_btn_wrapper .CARD__PREORDER + img.snow-img {
    left: 5px;
    top: 0px;
}
.HOVERED__CONTENT .product_btn_wrapper img.quote-img {
    max-width: 25px;
    max-height: 25px;
    position: absolute;
    bottom: 8px;
    right: 10px;
    vertical-align: middle;
    min-width:20px;
    min-height: 20px;
}

.HOVERED__CONTENT .QUOTE__PRODUCT .quation_price {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 4px;
    .HOVERED__PRICE{
        left:20px;
        top:0px;
    }
}
.HOVERED__CONTENT .QUOTE__PRODUCT .quation_price{
    margin: 2px 0;
}

@media(max-width: 912px) {
    .pdpmobile {
        height: 220px !important;

        .CARD__PREORDER {
            width: max-content;
            bottom: -62px !important;
        }
    }
    .HOVERED__CONTENT .product_btn_wrapper img.snow-img {
        bottom: 0px;
    }
    .HOME__NEWARRIVALS .HOVERED__CONTENT .product_btn_wrapper img.snow-img {
        bottom: 0;
    }

    .PC__small {
        /*width: 180px;*/
        width: calc(100% - 5px);
        height: 180px;
        .country-info {
            display: none;
        }
        a {
            img {
                max-height: 135px;
                height: 100%;
                object-fit: contain;
            }

            .CARD__PREORDER {
                bottom: 0px;
                left: 8px;
            }
        }

        .PC__SKU,
        .PC__PACKSIZE {
            padding: 0 10px;
            margin: 0;
        }

        .PC__SKU {
            overflow: hidden;
            color: rgba(28, 48, 74, 0.52);
            font-size: 11px;
            font-weight: 400;
            line-height: 14px;
            text-transform: capitalize;
            margin-bottom: 8px;
        }

        .PC__PACKSIZE {
            color: rgba(25, 39, 57, 0.94);
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            text-transform: capitalize;
        }

        .QUOTE__PRODUCT {
            .QUOTES {
                bottom: -8px;
            }
        }
    }


    .PC__small {
        /*width: 118.67px;*/
        width: calc(100% - 5px);
        // height: 196.67px;
        border-radius: 8px;
        overflow: hidden;
    }
    .PC__small a img {
        max-height: 89px;
    }
    .PC__small .product_btn_wrapper {
        max-height: 20px;
    }
    .PC__small .product_btn_wrapper .CARD__PREORDER{
        bottom: 4px;
    }
    .PC__small .product_btn_wrapper span.CARD__BUYOFFER {
        padding: 2px 4px 2px 4px;
        border-radius: 2px;
        background-color: rgba(248, 15, 99, 0.1);
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        vertical-align: middle;
        display: inline-block;
        position: relative;
        top: -5px;
        left: 8px;
        color: #F80F63;
    }
    .PC__small .CATALOG__PRODUCT {
        height: 54px;
        padding-top: 0;
    }
    .PC__small .HOVERED__NAME {
        color: rgba(25, 39, 57, 0.94);
        font-size: 14px;
        font-weight: 600;
        line-height: 18.29px;
        /* letter-spacing: -0.28px; */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        /* padding: 0 10px; */
        height: 35.56px;
    
    }
    .PC__small .HOVERED__PRICE {
        padding: 0 8px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;
        color: #22a38c;
        position: relative;
        top: 3px;
    }
    .HOME__NEWARRIVALS .PC__small .HOVERED__PRICE {
        top:0;
        display: block;
    }

    .HOME__NEWARRIVALS .PC__small .PC__PACKSIZE {
        line-height: 16px;
        color: rgba(28, 48, 74, 0.52);
        padding-top: 15px;
    }
    .PC__small .QUOTE__PRODUCT .QUOTES{
        max-width: 12px;
        width: 12px;
        height: 12px;
        object-fit: contain;
        top: 47px;
    }
    .HOVERED__CONTENT .product_btn_wrapper .CARD__PREORDER + img.snow-img {
        left: 5px;
        top: -4px;
    }

}
@media only screen and (max-width: 420px){
    .PC__small {
        width: 95%;
        .country-info {
            display: none;
        }
    }
    .home .HOME__NEWARRIVALS .PC__small:last-child {
        width:180px;
    }
    .HOVERED__CONTENT .product_btn_wrapper .CARD__PREORDER + img.snow-img {
        left: 5px;
        top: -4px;
    }
}
@media only screen and (max-width: 400px){
    .PC__small {
        width: 95%;
        .country-info {
            display: none;
        }
    }
    .home .HOME__NEWARRIVALS .PC__small:last-child {
        width:95%;
    }
    .HOVERED__CONTENT .product_btn_wrapper .CARD__PREORDER + img.snow-img {
        left: 5px;
        top: -4px;
    }
}