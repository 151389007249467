.MACCOUNT {
    margin: 0px 0 90px;
    background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
    min-height: 100vh;

    h5 {
        display: none;
        padding: 16px;
        background-color: #FFF;
        color: #000;
        font-size: 22px;
        font-weight: 600;
        line-height: 27.567px;
        text-transform: capitalize;
    }

    .MACCOUNT__TOP {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 12px;
        position: relative;
        border-left: 6px solid #37BFA7;
        height: 248px;

        img {
            position: absolute;
            offset: 0;
            z-index: 0;
            left: -12px;
            opacity: .15;
            top: 0;
            right: 0;
            bottom: 0;
        }

        .MACCOUNT__TOP__USER {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding-left: 16px;
            z-index: 9;

            p {
                margin: 0;
            }

            .USER__HELLO {
                color: #37BFA7;
                font-size: 28px;
                font-weight: 600;
                line-height: 35.085px;
                text-transform: capitalize;
            }

            .USER__NAME {
                color: #192434;
                font-size: 18px;
                font-weight: 600;
                line-height: 22.554px;
                text-transform: capitalize;
            }

            .USER__EMAIL {
                color: rgba(25, 39, 57, 0.94);
                font-size: 14px;
                font-weight: 400;
                line-height: 18.76px;
            }
        }

        .MACCOUNT__TOP__ICONMENU {
            display: flex;
            justify-content: flex-start;
            margin-top: 2em;
            gap: 10px;
            z-index: 9;

            &>p {
                margin: 0;
                width: calc(100% / 3 - 10px);
                height: 90px;
                background-color: #FFF;
                border-radius: 8px;

                a {
                    height: 100%;
                    width: 100%;
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;
                    flex-direction: column;
                }

                img {
                    border-radius: 4px;
                }

                span {
                    color: rgba(25, 39, 57, 0.94);
                    font-size: 15.561px;
                    font-weight: 500;
                    line-height: 17.506px;
                    text-transform: capitalize;
                }
            }

            .ICONMENU__ORDER {
                svg {
                    background: #FFB039;
                }
            }

            .ICONMENU__FINANCE {
                svg {
                    background: #2898FF;
                }
            }

            .offcanvas {
                max-width: 425px;
                width: 100%;
                max-height: calc(100% - 90px);
                height: 100%;
                overflow: auto;

                .offcanvas-header {
                    justify-content: unset;
                    gap: 1em;
                    padding-bottom: 0;

                    h5 {
                        margin: 0;
                    }

                    svg {
                        cursor: pointer;
                    }
                }

                .offcanvas-body {
                    // background-color: #FFF;
                    background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
                    max-height: 80vh;
                    padding-top: 0;

                    .NOTIFICATION__CONTENTS {
                        span {
                            background: #FFF !important;
                        }
                    }
                }
            }


            .ICONMENU__NOTIFICATIONS {
                position: relative;

                small {
                    position: absolute;
                    right: 7px;
                    top: 8px;
                    color: #FF316F;
                    background-color: #FFE9EF;
                    padding: 4px 10px;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16.289px;
                    text-transform: capitalize;
                    border-radius: 12px;

                    span {
                        color: #FF316F;
                    }

                    strong {
                        position: absolute;
                        top: 0;
                        font-size: 12px;
                    }
                }

                svg {
                    background: #FF316F;
                }

            }
        }
    }

    .MYACCOUNT__MENU {
        margin: 0 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .MACCOUNT__MENU__ONE,
        .MACCOUNT__MENU__TWO,
        .MACCOUNT__MENU__THREE {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: #FFF;
            border-radius: 8px;

            p {
                margin: 0;
                width: 100%;
                height: 60px;

                a {
                    height: 60px;
                    width: 100%;
                    display: flex;
                    gap: 8px;
                    padding: 8px 16px 8px 8px;
                    justify-content: space-between;
                    align-items: center;
                    text-decoration: none;

                    span {
                        flex: 1;
                        color: rgba(25, 39, 57, 0.94);
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 18px;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}

@media(max-width: 360px) {
    .MACCOUNT .MACCOUNT__TOP .MACCOUNT__TOP__ICONMENU p span {
        font-size: 12px;
    }
}