.MSETTINGS {
    margin: 16px 0 90px;
    background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
    min-height: 100vh;

    .MSETTINGS__TOP {
        display: flex;
        gap: 8px;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        align-items: center;
        padding: 16px;
        background-color: #FFF;

        h5 {
            color: #000;
            font-size: 22px;
            font-weight: 600;
            line-height: 27.567px;
            text-transform: capitalize;
            margin: 0;
        }
    }

    .MACCOUNT__SETTINGS__MENU {
        background-color: #FFF;
        border-radius: 8px;
        margin: 16px;
        ;

        hr {
            margin: 0;
        }

        p {
            margin: 0;
            width: 100%;
            height: 60px;

            a {
                height: 60px;
                width: 100%;
                display: flex;
                padding: 16px;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;

                span {
                    // flex: 1;
                    color: rgba(25, 39, 57, 0.94);
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 18px;
                    text-transform: capitalize;
                }

                .CURRENT__LANGUAGE {
                    text-align: right;
                    padding-right: 16px;
                    flex: 1;
                    color: rgba(27, 43, 65, 0.69);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: -0.16px;
                }
            }
        }
    }

    .offcanvas {
        hr {
            margin: 0;
        }

        .offcanvas-body {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1em;
            flex-direction: column;

            p {
                margin: 0;
                padding: 14px;
                color: rgba(25, 39, 57, 0.94);
                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
                text-transform: capitalize;
                width: 100%;
                text-align: center;
            }

            .selectedLanguage {
                border-radius: 8px;
                background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
            }
        }
    }
}