$seafoam: #37bfa7;

.MCATALOG {
    background-color: #F5F5F5;

    .MCATALOG__SEARCH {
        background-color: #FFF;
        padding: 1em;
        display: flex;
        width: 100%;
        align-items: center;
        gap: 14px;
        padding-bottom: 0;
        position: fixed;
        top: 0;
        z-index: 1;

        a {
            text-decoration: none;
        }

        label {
            max-width: 100%;
            width: 100%;
            position: relative;

            svg {
                position: absolute;
                top: 6px;
                left: 6px;
            }

            input {
                width: 100%;
                height: 36px;
                padding: 0 16px;
                color: rgba(28, 48, 74, 0.52);
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                text-transform: capitalize;
                display: flex;
                padding: 6px 16px 6px 30px;
                align-items: center;
                gap: 10px;
                border-radius: 6px;
                border: 1px solid rgba(28, 52, 84, 0.26);
                background: rgba(25, 59, 103, 0.05);
            }
        }
    }

    .MCATALOG__LEVEL1 {
        padding: 8px 1em 0px;
        max-width: 100%;
        background-color: white;
        height: 100px;
        position: fixed;
        top: 52px;
        z-index: 1;

        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            align-items: center;
            gap: 14px;
            height: 95px;
            overflow-x: auto;
            overflow-y: hidden;

            li {
                color: rgba(25, 39, 57, 0.94);
                font-size: 13px;
                height: 100%;
                font-weight: 400;
                line-height: 15px;
                text-transform: capitalize;
                text-align: center;
                min-width: 60px;

                img {
                    max-width: 62px;
                    max-height: 62px;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                span {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }

            .selectedlevelone {
                color: #22A38C;
            }
        }
    }

    .MCATALOG__BODY {
        display: flex;
        align-items: flex-start;
        margin-top: 4px;

        .MCATALOG__LEVEL2 {
            width: 20%;
            position: fixed;
            top: 152px;
            z-index: 1;

            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                max-height: 70vh;
                padding-bottom: 2em;
                overflow: auto;

                li {
                    overflow: hidden;
                    color: rgba(27, 43, 65, 0.69);
                    text-overflow: ellipsis;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                    padding: 10px;
                    min-height: 75px;
                    display: flex;
                    align-items: center;
                    word-break: break-all;
                }

                .selectedleveltwo {
                    color: #22A38C;
                    background-color: #DFF2EA;
                    border-radius: 8px;
                }
            }
        }

        .MCATALOG__PRODUCTS {
            display: flex;
            flex-direction: column;
            flex: 1;
            background-color: #FFF;
            gap: 1em;
            max-width: 80%;
            height: 100%;
            margin-left: 20%;
            padding-top: 150px;

            .MCATALOG__CONTENT {
                padding-top: 10px;

                .MCATALOG__GFD {
                    display: flex;
                    width: 90%;
                    overflow-x: scroll;
                    align-items: center;
                    gap: 10px;
                    padding: 0 !important;
                    margin-left: auto;
                    margin-right: auto;

                    &>p {
                        margin: 0;
                        // min-width: 132px;
                        text-align: center;
                        width: max-content;
                        color: rgba(25, 39, 57, 0.94);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18.76px;
                        white-space: nowrap;
                        padding: 1em 0;
                    }

                    .selectedlevelthree {
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            border-bottom: 2px solid #37BFA7;
                            left: 0;
                            right: 0;
                            bottom: 0px;
                        }
                    }
                }

                hr {
                    margin: 0;
                    margin-top: -16px;
                    margin-bottom: 16px;
                    margin-left: auto;
                    margin-right: auto;
                    width: 90%;
                }

                ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    display: flex;
                    overflow-x: scroll;
                    align-items: center;
                    gap: 10px;
                    margin-left: 2%;
                    margin-right: auto;
                    padding-bottom: 4px;

                    li {
                        text-align: center;
                        width: max-content;
                        white-space: nowrap;
                        color: rgba(27, 43, 65, 0.69);
                        font-size: 13px;
                        font-weight: 400;
                        white-space: nowrap;
                        line-height: 15px;
                        text-transform: capitalize;
                        padding: 10px 16px;
                        border-radius: 4px;
                        background: (linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF);
                    }

                    .selectedlevelthree,
                    .selectedlevelfour,
                    .selectedlevelfive {
                        background-color: #DFF3F0;
                        color: #22A38C;
                    }
                }
            }

            .MCATALOG__LISTS p {
                padding: 0 12px;
            }

            .MCATALOG__LISTS .infinite-scroll-component {
                padding: 0 12px;
                display: flex;
                height: calc(100vh - 330px);
                overflow: auto;
                flex-direction: column;
                background-color: #FFF;
                gap: 24px;
                max-height: 100%;
                overflow: overlay;
                padding-bottom: 12em;
                z-index: 0;

                span {
                    span {
                        height: 81px;
                        width: 100%;
                    }
                }
                
                .newCardSkeleton.mobilebox {
                    z-index: 0;
                    width: 100%; // Ensure this is set to 100%
                    box-sizing: border-box; // Ensures padding and borders are included in the width calculation
                    max-width: 281%;
                    span {
                        span {            
                            height: 84px !important;
                            width: 100% !important;
                        }
                    }
                }
                .MCATALOG__LIST {
                    display: flex;
                    gap: 24px;
                    position: relative;

                    .MCATALOG__LIST__CART {
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                    }

                    .MCATALOG__NOSTOCK {
                        filter: grayscale(1);
                    }

                    .MCATALOG__LIST__IMG {
                        max-width: 81px;
                        width: 100%;
                        position: relative;
                        border-radius: 8px;

                        .MCATALOG__PREORDER {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            color: #22A38C;
                            font-size: 10px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: -0.1px;
                            border-radius: 20px;
                            background: #DFFDE9;
                            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
                            padding: 0 8px;
                        }

                        img {
                            width: 81px;
                            object-fit: contain;
                        }
                    }

                    .MCATALOG__LIST__DETAIL {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    }

                    .MCATALOG__NAME {
                        color: rgba(25, 39, 57, 0.94);
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    .MCATALOG__SIZE {
                        color: rgba(27, 43, 65, 0.69);
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 14px;
                        letter-spacing: -0.12px;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    .MCATALOG__PRICE {
                        color: #22A38C;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20.048px;
                        text-transform: capitalize;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }
        }

    }
}


@media(max-width: 912px) {
    .MCATALOG {
        .MCATALOG__SEARCH {
            display: none;
        }

        .MCATALOG__LEVEL1 {
            height: auto;
            width: 100%;
            position: initial;
            border-radius: 0px 0px 16px 0px;
            padding: 26px 0px 0px 0px;
            background-color: rgba(247, 184, 184, 1);

            display: flex;
            flex-direction: column;
            gap: 8px;
            position: static; // new

            ul {
                border-radius: 0px 0px 2px 0px;
                padding: 0px 24px 0px 16px;
                gap: 14px;

                .selectedlevelone {
                    span {
                        color: rgba(174, 72, 72, 1);
                    }
                }

                @keyframes popUp {
                    from {
                        transform: scale(1);
                    }

                    to {
                        transform: scale(1.2);
                    }
                }

                .zoomImage img {
                    animation: popUp 0.5s forwards;
                }

                li {
                    span {
                        color: rgba(27, 43, 65, 0.69);
                        font-family: Lato;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: -0.01em;
                        text-align: center;
                    }
                }
            }

            .MCATALOG__LEVEL1_HEADING {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0px 16px;

                .HEADING {
                    color: rgba(25, 36, 52, 1);
                    font-family: Lato;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 27.57px;
                    text-align: left;
                }

            }
        }

        .MCATALOG__BODY {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 10px;
            width: 100%;
            margin-top: 8px;
            min-height: calc(100vh - 280px);

            .MCATALOG__LEVEL2 {
                top: unset;
                width: 100%;
                min-width: 112px;
                max-width: 112px;
                background-color: rgba(255, 255, 255, 1);
                position: relative;
                padding: 8px;
                border-radius: 8px;
                align-self: stretch;

                ul {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 8px;

                    @keyframes slideUp {
                        from {
                            opacity: 0;
                            transform: translateY(20px);
                        }

                        to {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }

                    li {
                        opacity: 0;
                        animation: slideUp 0.5s forwards;
                    }

                    li {
                        color: rgba(25, 36, 52, 1);
                        font-family: Lato;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: -0.01em;
                        text-align: left;

                        padding: 8px;
                        min-height: 0px;
                        display: flex;
                        align-items: center;
                        word-break: normal;
                        gap: 10px;
                        width: 100%;
                    }
                }
            }

            .MCATALOG__PRODUCTS {
                padding-top: 0px;
                gap: 2px;
                margin-left: 0px;
                // max-width: 316px;
                max-width: 70% !important;
                align-self: stretch;
                height: auto;
                background-color: transparent;

                .MCATALOG__LISTS {
                    min-height: calc(100vh - 280px);
                }
            }

            .MCATALOG__CONTENT {
                background-color: rgba(255, 255, 255, 1);
                padding: 8px 0px 8px 0px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 8px;
                border-radius: 8px 0px 0px 0px;

                ul {
                    padding: 0px 8px 0px 8px !important;
                    margin-left: 0px !important;
                    display: flex;
                    overflow-x: auto;
                    width: 100%;
                    min-width: 100%;
                    gap: 6px !important;

                    .selectedlevelthree {
                        background: #DFF3F0 !important;
                    }

                    li {
                        background: rgba(255, 255, 255, 1) !important;
                    }

                    @keyframes slideIn {
                        from {
                            opacity: 0;
                            transform: translateX(20px);
                        }

                        to {
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }

                    li {
                        opacity: 0;
                        animation: slideIn 0.5s forwards;
                    }
                }

                .MCATALOG_FILTER {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 45px;
                    padding: 0px 8px 0px 8px;
                    width: 100%;
                    max-width: 100%;

                    .MCATALOG_FILTER_col_1 {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;

                        div {
                            font-family: Lato;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 18.76px;
                            text-align: right;
                            color: rgba(27, 43, 65, 0.69);
                            border: 1px solid rgba(28, 55, 90, 0.16);
                            padding: 4px;
                            border-radius: 4px;

                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 2px;
                        }
                    }

                    .MCATALOG_FILTER_col_2 {
                        font-family: Lato;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 18.76px;
                        text-align: right;
                        color: rgba(25, 39, 57, 1);
                        padding: 4px;

                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 4px;
                    }


                    .offcanvas {
                        height: 65vh !important;
                    }

                    .offcanvas.offcanvas-bottom {
                        height: 80vh !important;
                        max-height: 100% !important;
                        border-radius: 8px;

                        .offcanvas-header {
                            .offcanvas-title_1 {
                                color: rgba(27, 43, 65, 0.69);
                                font-family: Lato;
                                font-size: 18px;
                                font-weight: 700;
                                line-height: 22.55px;
                                text-align: left;
                            }

                            .offcanvas-title_2 {
                                color: rgba(25, 36, 52, 1);
                                font-family: Lato;
                                font-size: 22px;
                                font-weight: 700;
                                line-height: 27.57px;
                                text-align: center;
                            }

                            .btn-close {
                                color: rgba(25, 39, 57, 1) !important;
                            }
                        }

                        .offcanvas-body {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            // justify-content: flex-start;
                            align-items: flex-start;
                            padding: 24px 16px 24px 16px;
                            border-top: 1px solid rgba(28, 52, 84, 0.26);

                            hr {
                                margin-top: 12px;
                                margin-bottom: 12px;
                                width: 100%;
                            }

                            .offcanvas-body-list {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                gap: 4px;

                                ul {
                                    padding: 4px 0px 4px 0px !important;
                                    width: 100%;

                                    li {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                        width: 100%;
                                        padding: 4px 0px 4px 0px;

                                        label {
                                            color: rgba(25, 39, 57, 1);
                                            font-family: Lato;
                                            font-size: 16px;
                                            font-weight: 500;
                                            line-height: 24px;
                                            letter-spacing: -0.01em;
                                            text-align: left;
                                        }

                                        input {
                                            width: 24px;
                                            height: 24px;
                                        }

                                        input:checked {
                                            background-color: $seafoam;
                                            border-color: $seafoam !important;
                                            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
                                        }

                                        // start
                                        .custom-checkbox {
                                            position: relative;
                                            display: inline-block;
                                            width: 24px;
                                            height: 24px;
                                            cursor: pointer;
                                        }

                                        .custom-checkbox input {
                                            opacity: 0;
                                            width: 0;
                                            height: 0;
                                        }

                                        .custom-checkbox .checkmark {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            height: 24px;
                                            width: 24px;
                                            background-color: transparent;
                                            border: 2px solid rgba(28, 55, 90, 0.15);
                                            border-radius: 4px;
                                            transition: background-color 0.3s, border-color 0.3s;
                                        }

                                        .custom-checkbox input:checked~.checkmark {
                                            background-color: $seafoam;
                                            border-color: $seafoam;
                                        }

                                        .custom-checkbox .checkmark:after {
                                            content: "";
                                            position: absolute;
                                            display: none;
                                        }

                                        .custom-checkbox input:checked~.checkmark:after {
                                            display: block;
                                        }

                                        .custom-checkbox .checkmark:after {
                                            left: 8px;
                                            top: 3px;
                                            width: 6px;
                                            height: 12px;
                                            border: solid white;
                                            border-width: 0 3px 3px 0;
                                            transform: rotate(45deg);
                                        }

                                        //end

                                        button {
                                            color: rgba(25, 39, 57, 0.94);
                                            font-family: Lato;
                                            font-size: 16px;
                                            font-weight: 500;
                                            line-height: 24px;
                                            letter-spacing: -0.01em;
                                            text-align: right;
                                            border-radius: 8px;
                                            border: 1px solid rgba(28, 52, 84, 0.26)
                                        }
                                    }
                                }
                            }

                            .filter_heading {
                                color: rgba(25, 36, 52, 1);
                                font-family: Lato;
                                font-size: 18px;
                                font-weight: 700;
                                line-height: 22.55px;
                                text-align: left;
                                margin-bottom: 8px;
                            }

                            .offcanvas-country-body-list {
                                ul {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: center;
                                    padding: 8px !important;
                                    flex-wrap: wrap;

                                    li {
                                        padding: 0px !important;
                                        width: auto;

                                        button {
                                            padding: 8px !important;
                                            color: rgba(25, 39, 57, 0.94);
                                            font-family: Lato;
                                            font-size: 16px;
                                            font-weight: 500;
                                            line-height: 24px;
                                            letter-spacing: -0.01em;
                                            text-align: right;
                                            border: 1px solid rgba(28, 52, 84, 0.26);
                                            border-radius: 8px;
                                        }

                                        .active {
                                            border: 1px solid rgba(55, 191, 167, 0.3);
                                            color: rgba(34, 163, 140, 1);
                                            background-color: rgba(55, 191, 167, 0.15);
                                        }
                                    }
                                }
                            }

                            .offcanvas__buttons {
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                padding: 32px 0px 32px 0px;
                                margin-bottom: 80px;
                                gap: 6px;

                                div {
                                    color: rgba(25, 36, 52, 1);
                                    font-family: Lato;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 18.76px;
                                    text-align: center;
                                    width: 100%;
                                }

                                button {
                                    width: 100%;
                                    color: rgba(255, 255, 255, 1);
                                    font-family: Lato;
                                    font-size: 16px;
                                    font-weight: 700;
                                    line-height: 20px;
                                    letter-spacing: 0.03em;
                                    text-align: center;
                                    padding: 14px 48px 14px 48px;
                                    border-radius: 8px;
                                    background-color: rgba(55, 191, 167, 1);
                                }
                            }
                        }
                    }
                }
            }

            .MCATALOG__LISTS {
                background-color: rgba(255, 255, 255, 1);
                width: 100%;
                max-width: 100%;

                .infinite-scroll-component {
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                    width: 100%;
                    max-width: 100%;
                    gap: 0px !important;
                    height: auto;
                    overflow: auto;

                    .MCATALOG__LIST {
                        padding: 12px 18px 12px 8px;
                        gap: 8px !important;

                        .MCATALOG__LIST__DETAIL {
                            gap: 4px !important;

                            .MCATALOG__NAME {
                                font-family: Lato;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 18px;
                                letter-spacing: -0.01em;
                                text-align: left;
                                color: rgba(25, 39, 57, 0.94);
                            }

                            .MCATALOG__TYPE {

                                .MCATALOG__PREORDER {
                                    font-family: Lato;
                                    font-size: 10px;
                                    font-weight: 500;
                                    line-height: 16px;
                                    letter-spacing: -0.01em;
                                    text-align: left;
                                    color: rgba(34, 163, 140, 1);
                                    border-radius: 4px;
                                    background: rgba(223, 253, 233, 1);
                                    // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
                                    padding: 2px 8px 2px 8px;
                                    margin-right: 4px;
                                }

                                img {
                                    width: 18px;
                                    height: 18px;
                                    margin-right: 4px;
                                }
                            }

                            .MCATALOG__SIZE {
                                color: rgba(27, 43, 65, 0.69);
                                font-family: Lato;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 16px;
                                text-align: left;
                            }

                            .MCATALOG__LAST_ROW {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: flex-end;
                                gap: 4px;

                                .MCATALOG__QUOTATION__PRICE {

                                    img {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }

                                .MCATALOG__PRICE {
                                    color: rgba(34, 163, 140, 1);
                                    font-family: Lato;
                                    font-size: 16px;
                                    font-weight: 700;
                                    line-height: 19.2px;
                                    text-align: left;

                                    .MCATALOG__PRICE_SYMBOL {
                                        font-family: Lato;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 18.76px;
                                        text-align: left;
                                        color: rgba(34, 163, 140, 1);
                                    }

                                    .MCATALOG__UNIT {
                                        font-family: Lato;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 18.76px;
                                        text-align: left;
                                        color: rgba(34, 163, 140, 1);
                                    }
                                }
                            }
                        }

                        .MCATALOG__LIST__CART {
                            right: 20px !important;
                        }

                        .MCATALOG__LIST__DETAIL {
                            .MCATALOG__NAME {}
                        }

                        .MCATALOG__LIST__IMG {
                            border-radius: 8px 8px 0px 0px !important;
                        }
                    }
                }
            }
        }
    }
}