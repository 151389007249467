$seafoam: #37bfa7;

.reaquest__button {
  border: 0;
  padding: 8px;
  text-decoration: underline;
  outline: 0;
  // color: white;
  font-size: 16px;
  margin: 0;
  // margin-top: 3em;

  svg {
    background-color: rgba(205, 230, 246, 1);
    border-radius: 100%;
  }

  img {
    max-width: 24px;
    max-height: 24px;
    height: 100%;
    width: 100%;
    object-fit: contain;
    margin-right: 2px;
  }
}

.reaquest__button_orange {
  float: right;
  color: #fdc156 !important;
  // background: #fdc15626 !important;
  // border-color: #fdc156 !important;
}

.pc,
.kg {
  padding: 3px 1em;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  margin: 0 !important;
  color: gray;
}

.unitSelected {
  background-color: $seafoam;
  color: white;
  border-radius: 8px;
}

.reaquest__button_div {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  justify-content: center;
  align-items: flex-end;

  button {
    width: max-content;
  }
}

.qatation_units_controls {
  border-radius: 8px;
  padding-left: 1px;
  gap: 10px;
  position: relative;

  input {
    // height: 48px;
    // background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
  }

  &>* {
    // border: 1px solid #e4e4e4;
  }

  .dropdown-menu {
    min-width: 5rem !important;
    left: 5.5em;
    right: 0;
  }

  p {
    color: #2b2b2b;
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    right: 12px;
    top: 12px;
    margin: 0;

    // &:focus {
    //   background-color: transparent;
    //   color: #2b2b2b;
    // }
  }

  .dropdown-item {
    text-align: center;
  }
}

.qatation_units,
.popover_price,
.quotation__remarks{
  gap: 10px;
  flex-direction: column;

  label {
    font-size: 14px;
    font-weight: 500;
    // width: 13em;
  }
}

.pr {
  padding-right: 10px;
}

.text_center {
  text-align: center;
}

.popover__close {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 46px;
    color: #000;
    margin-top: 6px;
    font-size: 21px;
    font-weight: 600;

  span {
    color: rgba(27, 43, 65, 0.69);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.16px;
  }

  svg {
    position: absolute;
    top: 20px;
    left: 12px;
    cursor: pointer;
    margin-right: 15px;
  }
}

.price__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.OLDQUOTE__PRICE {
  color: $seafoam;
}

.requestbutton__wrapper {
  // min-width: 11em;
  color: $seafoam !important;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}

#popover-positioned-bottom {
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
  // border: 1px solid #e4e4e4;
  min-width: 420px;
}

.quote__submit {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: 3em;

  button {
    font-size: 16px;
    font-weight: 600;
    // background-color: $seafoam;
    background-color: rgba(0, 85, 65, 1);
    height: 48px;
    width: 100%;
    text-decoration: none;
    color: #FFF !important;
    border-radius: 30px;

    // &:focus,
    // &:hover {
    //   background-color: rgba(0, 85, 65, 1);
    //   border-radius: 30px;
    // }
  }
}

.popover-header {
  background-color: white !important;
}

.form-control {
  font-size: 14px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white !important;
}

// .quotation__remarks {
//   margin-top: 1em;
//   display: flex;
//   flex-direction: column;
//   gap: 10px;

//   textarea {
//     // border: 0;
//     // outline: 0;
//     // width: 100%;
//     // background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
//     // padding: .375rem .75rem;
//     // border-radius: .375rem;
//   }
// }

.popover__msg {
  font-size: 12px;
    //  font-weight: 600;
    letter-spacing: -o 0.12px;
    margin-top: 10px;
    padding: 8;
    padding: 15px 28px 18px 29px;
    background: rgba(255, 189, 89, 0.35);
    border-radius: 4px;
    width: 600px;
    color: rgba(4, 30, 41, 1);
    height: Hug(56px);
    margin-left: 12px;
}
.quotation_para{
  margin-left: 38px;
  font-size: 12px;
  /* text-align: center; */
  color: #3f4944;
  margin-top: -7px;
}
.REQUEST_QUOTATION_MODEL {
  z-index: 9999;
  /* border-radius: 48px; */
  padding: 16px 16px 32px 16px;
  border-radius: 32px 32px 0px 0px;
  width: 462px;
 
}
.qatation_units,
.popover_price,
.quotation__remarks {
  position: relative;
  padding: 10px;
  border: 1px solid rgba(111, 121, 116, 1);
  margin: 10px;
  border-radius: 8px;
  margin-top: 43px;


  label {
    position: absolute;
    top: -10px;
    left: 20px;
    background-color: white;
    padding: 0 5px;
    font-size: 14px;
    font-weight: 500;
  }

  input,
  textarea {
    border: none;
    width: 100%;
    // padding: .375rem .75rem;
    // background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
    // border-radius: .375rem;
  }

  input:disabled {
    background-color: white !important;
  }
}

// .qatation_units_controls,
// .popover_price .popover_price {
//   padding-left: 1px;
//   gap: 10px;
//   position: relative;
// }

.form-control {
  font-size: 16px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white !important;
}

// .quotation__remarks {
//   textarea {
//     height: 48px;
//   }
// }



@media(max-width: 912px) {
  .popover__close {
    font-size: 16px;

    svg {
      top: 18px;
    }
  }
}