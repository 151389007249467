.pdppage {
    max-width: 1440px;
    margin: 0 auto;

    .pdppage__textone {
        color: #192434;
        font-size: 20px;
        font-weight: 600;
        margin: 0 1.5%;
        margin-top: 2em;
        height: 30px;
    }

    .pdppage__texttwo {
        font-size: 14px;
        color: rgba(27, 43, 65, 0.69);
        font-weight: 400;
        margin: 0 1.5%;
        height: 21px;
        margin-bottom: 32px;
    }

    .product_card_carousel {
        margin-bottom: 32px;
    }
}

@media(max-width: 912px) {
    .pdppage {
        margin-bottom: 100px;
        position: relative;

        &>svg {
            margin: 1em;
            margin-left: 6px;
        }

        &>svg {
            position: absolute;
            left: 1em;
            top: 1em;
            z-index: 9;
            border-radius: 32px;
            background: rgba(0, 0, 0, 0.52);
            padding: 10px;
            opacity: .85;
        }
    }
}