.ORDERSUMMARY {
    padding: 1em;
    background-color: #FFF;
    border-radius: 8px;

    h5 {
        color: #192434;
        font-size: 22px;
        font-weight: 600;
        line-height: 27.567px;
        text-transform: capitalize;
    }

    .ORDERSUMMARY__TOP,
    .ORDERSUMMARY__BOTTOM {
        p {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                color: rgba(27, 43, 65, 0.69);
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.16px;
            }
        }

        .ORDERSUMMARY__TOTAL span {
            color: #22A38C;
            font-size: 18px;
            font-weight: 500;
            line-height: 22.554px;
            text-transform: capitalize;
        }

        .accordion {
            margin: 10px 0;

            .accordion-item {
                border: 0;

                &:focus-visible {
                    border: 0;
                    outline: 0;
                }

                .accordion-header {

                    button {
                        background-color: white;
                        padding: 0;
                        color: rgba(27, 43, 65, 0.69);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: -0.16px;
                    }

                    .accordion-button,
                    .accordion-button:not(.collapsed) {
                        box-shadow: none;
                    }
                }

                .accordion-body {
                    padding: 0;
                    margin: 10px 0;

                    ul {
                        padding: 0;

                        li {
                            list-style: none;
                            display: flex;
                            align-items: baseline;
                            gap: 8px;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            margin-bottom: 1em;

                            span {
                                color: rgba(25, 39, 57, 0.94);
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 14px;
                                letter-spacing: -0.12px;
                                min-width: 4%;
                            }

                            .SUMMARY__NAME {
                                width: 300px;
                            }

                            .SUMMARY__VAT {
                                color: #EE4D44;
                                width: 20%;
                            }
                        }
                    }
                }
            }
        }
    }

    .ORDERSUMMARY__REORDER {
        border-radius: 8px;
        width: 100%;
        border: 1px solid #37BFA7;
        color: #22A38C;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 26.32px;
        text-transform: capitalize;
        background-color: #FFF;
        padding: 16px;
    }
}