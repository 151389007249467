.PURCHASEPLP_RIGHT_SIDE {
    display: flex;
    flex-direction: column;
    justify-content: normal;
    align-items: flex-start;
    width: 100%;
    padding: 0px 10px;
    gap: 16px;
}

.purchase_filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    background-color: transparent;

    .purchase_filter_heading {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        color: rgba(25, 36, 52, 1);
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
    }

    .purchase_filter_list {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        div {
            color: rgba(25, 39, 57, 1);
            font-family: Lato;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.2px;
            text-align: center;
            border: 1px solid rgba(28, 48, 74, 0.52);
            padding: 6px 16px;
            border-radius: 8px;
            cursor: pointer;
        }

        .active {
            color: #22a38c;
            border: 1px solid #22a38c;
            background-color: rgba(55, 191, 167, 0.15);
        }

        .filter-item {
            color: rgba(25, 39, 57, 1);
            font-family: Lato;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.2px;
            text-align: center;
            border: 1px solid rgba(28, 48, 74, 0.52);
            padding: 6px 16px;
            border-radius: 8px;

            &.active {
                color: rgba(34, 163, 140, 1);
                border: 1px solid rgba(34, 163, 140, 1);
                background-color: rgba(55, 191, 167, 0.15);
            }
        }
    }
}


.purchase_content .PURCHASEPLP__DATA .infinite-scroll-component {
    display: flex;
    gap: 16px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.purchase_content {
    width: 100%;
}

@media(max-width: 912px) {

    .PURCHASEPLP_RIGHT_SIDE {
        padding: 0px;
    }

    .purchase_filter {
        display: none;
    }

}