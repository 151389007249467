.passwordfield {
  position: relative;

  img {
    cursor: pointer;
    position: absolute;
    width: 20px;
    right: 8px;
    top: 44px;
  }
}
