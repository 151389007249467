.key_img {
    margin-bottom: 10px;
}

.reset_password_heading {
    color: rgba(25, 39, 57, 1);
    font-family: Lato;
    font-size: 28px;
    font-weight: 700;
    line-height: 35.08px;
    text-align: left;
}

.reset_password_info {
    color: rgba(25, 39, 57, 1);
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
}


.input_row {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.new_password_input,
.confirm_password_input {
    padding: 8px 20px 8px 20px;
    border-radius: 4px;
    background-color: rgba(245, 245, 245, 1);
    display: flex;
    flex-direction: column;
    gap: 10px;

    .mb-3 {
        margin-bottom: 0rem !important;

        label {
            margin-bottom: 0rem !important;
        }

        .form-control {
            padding: 0rem !important;
        }

        img {
            top: 25px !important;
        }
    }

    label {
        color: rgba(28, 48, 74, 0.52);
        font-family: Lato;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.01em;
        text-align: left;
        background-color: transparent;
    }

    input {
        color: rgba(28, 48, 74, 0.52);
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: left;
        border: none;
        background-color: transparent;
    }

    .form-control:focus {
        background-color: transparent;
    }
}

.error-input {
    border: 1px solid rgba(255, 216, 216, 1) !important;
    outline: 1px solid rgba(255, 216, 216, 1) !important;
    background-color: rgba(255, 245, 245, 1) !important;
}

.create_password_info_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    .create_password_heading {
        color: rgba(25, 39, 57, 1);
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 18.76px;
        text-align: left;
    }

    .validatorText {
        font-family: Lato;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        margin: 0px !important;
        list-style-type: none;
        padding-left: 0;

        li {
            list-style-type: none;
            padding-left: 0;
        }

        .validator {
            margin: 0;

            .seafoam,
            .notseafoam {
                width: 100%;
                height: 2em;
            }

            .textseafoam {
                color: rgba(44, 148, 92, 1);
                font-size: 14px;
            }

            .nottextseafoam {
                color: rgba(202, 21, 12, 1);
                font-size: 14px;
            }

            .textseafoam::before {
                content: '✔';
                color: rgba(44, 148, 92, 1);
                margin-right: 8px;
                font-size: 16px;
            }

            .nottextseafoam::before {
                content: '✖';
                color: rgba(202, 21, 12, 1);
                margin-right: 8px;
                font-size: 16px;
            }

        }
    }
}

.helper_text {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: right;
    color: rgba(44, 148, 92, 1);
}

.confirm_button_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(55, 191, 167, 1);
    color: rgba(255, 255, 255, 1);

    .confirm_button {
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        width: 100%;
        padding: 15px;
        background-color: rgba(55, 191, 167, 1);
        color: rgba(255, 255, 255, 1);
        border: none;
    }

    .confirm_button:hover {
        background-color: rgba(55, 191, 167, 1);
        color: rgba(255, 255, 255, 1);
    }

    .confirm_button:active {
        background-color: rgba(55, 191, 167, 1);
        color: rgba(255, 255, 255, 1);
    }
}

.return_signin_pwd {
    a {
        text-decoration: none;
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.01em;
        text-align: right;
        color: rgba(54, 118, 224, 1);
    }
}

.error {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(202, 21, 12, 1);
}

.error::before {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 5px;
}

/* Ensure content takes full width on small screens */
@media (max-width: 768px) {
    .error {
        display: none;
    }
}