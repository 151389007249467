$seafoam: #37bfa7;

.paymentcollection {
    min-height: 100vh;
    max-width: 1440px;
    margin: 0 auto;
    padding: 1em;

    .PC__back {
        margin: 1em 0;
        width: max-content;
        cursor: pointer;

        span img {
            max-width: 24px;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    img {
        max-width: 24px;
        max-height: 24px;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .PC__heading {
        font-size: 22px;
        font-weight: 600;
        line-height: 27.57px;
        margin-bottom: 1em;
    }

    .PC__content {
        display: flex;
        gap: 1em;

        .PC__left {
            width: 74%;


            .PC__info {
                box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.2);
                padding: 24px;
                display: flex;
                align-items: center;
                gap: 3em;
                justify-content: flex-start;

                p {
                    margin: 0;
                    display: flex;
                    align-items: center;

                    .info__label {
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #1B2B41B0;
                    }

                    .info__value {
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #192739F0;
                        margin-left: 4px;
                    }
                }
            }

            .PC__invoices {
                // margin-top: 1em;
                background-color: #FFF;
                // box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.2);
                padding: 24px;

                h6 {
                    display: flex;
                    gap: 1em;
                    align-items: center;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22.55px;
                }

                ul {
                    padding: 0;
                    list-style: none;
                    display: flex;
                    align-items: center;
                    gap: 1em;
                    flex-wrap: wrap;

                    li {
                        padding: 10px 10px 10px 20px;
                        border: 1px solid #1A38601A;
                        position: relative;
                        border-radius: 8px;
                        height: 38px;
                        display: flex;
                        align-items: center;

                        &::before {
                            content: "";
                            border-radius: 100%;
                            position: absolute;
                            background-color: grey;
                            width: 4px;
                            height: 4px;
                            left: 12px;
                            top: 16px;
                        }
                    }
                }
            }

            .PC__methods {
                margin-top: 1em;
                background-color: #FFF;
                // box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.2);
                padding: 32px 24px;

                h6 {
                    display: flex;
                    align-items: center;
                    gap: 1em;
                    color: #000;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 22.55px;
                    margin: 0;
                }

                span {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18.76px;
                    color: #1B2B41B0;
                }

                .PC__radio {
                    display: flex;
                    flex-direction: column;
                    gap: 1em;
                    max-width: 400px;
                    width: 100%;

                    .radio-label {
                        position: relative;
                    }

                    /* Hide the default radio button */
                    .radio-label input[type="radio"] {
                        display: none;
                    }

                    /* Style the custom radio button */
                    .radio-label:before {
                        content: "";
                        display: inline-block;
                        max-width: 18px;
                        width: 100%;
                        height: 18px;
                        border: 1px solid lightgray;
                        border-radius: 50%;
                        position: relative;
                        top: 5px;
                        background-color: lightgray;
                        margin-right: 10px;
                    }

                    .selectedpayment {
                        &::before {
                            background-color: $seafoam;
                        }

                        &::after {
                            content: "";
                            background-color: white;
                            left: 6px;
                            position: absolute;
                            height: 6px;
                            width: 6px;
                            top: 20.5px;
                            border-radius: 100%;
                        }
                    }

                    /* Style the custom radio button when checked */
                    .radio-label input[type="radio"]:checked+.PC__cash:before,
                    .radio-label input[type="radio"]:checked+.PC__QR:before {
                        background-color: #37bfa7;
                        border-color: #37bfa7;
                    }

                    label {
                        display: flex;
                        align-items: baseline;
                        gap: 10px;
                        cursor: pointer;

                        p {
                            margin: 0;
                        }
                    }

                    .PC__cash,
                    .PC__QR {
                        display: flex;
                        width: 100%;
                        flex-direction: column;

                        p {
                            display: flex;
                            align-items: center;
                            min-height: 46px;
                            gap: 1em;

                            &>span {
                                font-weight: 500;
                                line-height: 24px;
                                color: #192739F0;
                                font-size: 16px;
                            }
                        }

                        .cash__span1 {
                            margin-bottom: 10px;
                        }

                        span {
                            font-size: 12px;

                            span {
                                color: #000;
                                font-size: 16px;
                            }
                        }
                    }

                    .PC__cash {
                        p {
                            width: max-content;

                            img {
                                max-width: 48px;
                                max-height: 48px;
                            }
                        }
                    }

                    .PC__QR {
                        p {
                            margin-bottom: 1em;
                        }
                    }
                }
            }
        }

        .PC__right {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            border-radius: 8px;
            padding: 32px 24px;
            border: 1px solid $seafoam;
            background-color: #F3FBF9;
            height: 100%;

            p {
                margin: 0;
            }

            button {
                margin-top: 1em;
            }
        }
    }
}

@media(max-width:1200px) {
    .paymentcollection {

        .PC__content {
            flex-direction: column;

            .PC__left {
                width: 100%;

                .PC__info,
                .PC__invoices,
                .PC__methods {
                    padding: 0;
                    margin-top: 1em;
                    box-shadow: none;

                    ul li {
                        padding: 10px;

                        // &::before {
                        //     width: 0px;
                        // }
                    }
                }
            }

            .PC__right {
                min-height: 100%;
            }
        }
    }
}

@media(max-width: 912px) {
    .paymentcollection {
        padding: 0;
        background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
        position: relative;

        .PC__heading {
            padding-bottom: 8px;
            // box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
            display: flex;
            align-items: center;
            gap: 1em;
        }

        .PC__left {
            padding-bottom: 240px !important;
            height: 600px;
            overflow: auto;

            .PC__invoices,
            .PC__methods {
                padding: 24px 16px !important;
            }

            .PC__radio {
                border: 1px solid #37bfa7;
                border-radius: 8px;
                margin-top: 24px;

                .selectedpayment {
                    &::after {
                        left: 16px !important;
                        top: 21px !important;
                    }

                    &::before {
                        margin-left: 10px;
                        margin-right: 0;
                    }
                }

                .PC__QR {
                    p {
                        gap: 8px;
                        margin: 0 !important;

                        span {
                            width: max-content;
                        }
                    }
                }
            }


            .PC__invoices {
                ul {
                    max-height: 42vh;
                    overflow: auto;

                    li {
                        font-size: 14px;
                        padding: 10px 10px 10px 20px;

                        &::before {
                            left: 4px !important;
                            top: 16px !important;
                        }
                    }
                }

                h6 {
                    margin: 0;
                    margin-bottom: 15px;
                }
            }
        }

        .PC__back {
            display: none;
        }

        .PC__heading {
            margin: 0;
        }

        .PC__heading,
        .PC__left {
            padding: 16px;
        }

        .PC__left {
            padding-top: 0 !important;
        }

        .PC__right {
            background-color: #FFF !important;
            position: fixed;
            border: 0 !important;
            bottom: 0em;
            width: 100%;
            border-radius: 0 !important;
            padding: 16px !important;
            z-index: 999;
            min-height: unset !important;
            max-height: 170px;

            .content {
                margin-bottom: 1em !important;

                .summary__label {
                    color: rgba(25, 39, 57, 0.94);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: -0.16px;
                }

                .summary__value {
                    color: #22A38C;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22.554px;
                    text-transform: capitalize;
                }
            }
        }
    }
}

@media(max-width: 465px) {
    .paymentcollection {
        .PC__content {
            .PC__left {
                .PC__methods {
                    .PC__radio {
                        .PC__QR {
                            p {
                                gap: 8px;
                                margin: 0 !important;

                                span {
                                    font-size: 14px;
                                    width: max-content;
                                }

                                img {
                                    max-width: 20px;
                                    max-height: 20px;
                                }
                            }
                        }
                    }
                }
            }

            .PC__right {
                .content {
                    justify-content: space-between;
                }
            }
        }
    }
}