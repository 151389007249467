.login_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-image: url('../../assets/images/Login_imgs/login_bg.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.login_header {
    text-align: left;
    // margin-bottom: 20px;
    margin-left: 60px;
    margin-top: 20px;

    padding: 10px 0;
    font-size: 24px;
    font-weight: bold;

    .login_logo {
        height: 30px;
        width: auto;
    }
}

.login_content_row {
    display: flex;
    justify-content: space-between;
    padding: 0px 15%;
    gap: 80px;
}

.login_left_content_mobile {
    display: none;
}

.login_right_content_mobile {
    display: none;
}

.login_left_content {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .left_heading {
        font-family: Lato;
        font-size: 40px;
        font-weight: 700;
        line-height: 50.12px;
        text-align: center;
        color: rgba(252, 252, 252, 1);
    }

    .left_info {
        color: rgba(234, 234, 234, 1);
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 18.76px;
        text-align: center;
    }
}

.login_right_content {
    flex: 1;
    padding: 30px 40px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
}



/* Ensure content takes full width on small screens */
@media (max-width: 768px) {

    .login_container {
        background-image: none !important;
        min-height: 100%;
        padding-bottom: 0;
    }

    .login_footer {
        display: none;
    }

    .login_content_row {
        width: 100%;
        flex-direction: column;
        gap: 0px;
        padding: 0px;
        background-image: none;
    }

    .login_left_content,
    .login_right_content {
        width: 100%;
        margin-bottom: 10px;
        border: none;
        background-color: rgba(255, 255, 255, 1);
    }

    .login_left_content {
        display: none;
    }

    .login_right_content {
        // display: none;
    }

    .login_header {
        display: none;
    }

    .login_right_content_mobile {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: #F5F5F5;
        padding: 4px;
        border-radius: 32px;

        button {
            border-radius: 32px;
            padding: 16px 32px;
            flex: 0 0 50%;
            //box-shadow: 0 0 24px 0 rgba(0,0,0,0.1), 0 0 16px 0 rgba(0,0,0,0.2);
            border: 0;
            background: transparent;
            color: rgba(28, 48, 74, 0.52);
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;

            &.active {
                background: #fff;
                box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05), 0 0 16px 0 rgba(0, 0, 0, 0.05);
                color: #192739;
            }
        }

    }

    .login_left_content_mobile {
        width: 100%;
        margin-top: 0px;
        display: flex;
        max-height: 270px;
        overflow: hidden;
        height: 270px;
        background: url('../../assets//images/Login_imgs/mobile_bg.png');
        background-position: right center;
        background-size: cover;
        background-repeat: no-repeat;
        flex-wrap: wrap;
        align-content: center;


        .logo {
            display: block;
            padding: 0px 10px 15px 10px;
            margin: 0px 60px 10px 20px;


            img {
                display: block;
            }
        }

        .left_heading {
            padding: 5px;
            color: rgba(252, 252, 252, 1);
            font-family: Lato;
            font-size: 22px;
            font-weight: 700;
            line-height: 27.57px;
            text-align: left;
            margin: 0px 60px 5px 20px;
        }

        .left_info {
            padding: 5px;
            color: rgba(245, 245, 245, 1);
            font-family: Lato;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            margin: 0px 60px 5px 20px;
        }
    }

    .loginpage__alert {
        display: none;
    }

    .signin_text {
        display: none;
    }

    .new_user_text {
        display: none;
    }

    .welcome_text {
        display: none;
    }

    .welcome_info {
        display: none;
    }

    .login_right_content {
        // padding: 20px;
    }

    .login_right_content_mobile {
        display: flex;
        flex-direction: row;
    }
}