$seafoam: #37bfa7;


.productInfo {
    display: flex;
    justify-content: flex-start;
    gap: 48px;
    border-radius: 12px;
    padding: 24px;
    background-color: #FFF;
}

.productInfo__carousel,
.productInfo__details {
    flex: 1;
}

.similar_product_web_mobile {
    width: 100%;

    .similar_product_web {

        .blocks .slick-slider .slick-prev {
            top: -32px;
            left: 89.6%;
        }

        .blocks {
            background-color: transparent !important;
            background: transparent !important;

            .slick-list {
                // overflow-x: auto; /* Enables horizontal scrolling */
                // white-space: nowrap; /* Ensures the items stay on a single line */
                // scrollbar-width: thin; /* For Firefox, makes the scrollbar thinner */
                // width: 100%;
                // max-width: 672px;
            }

            .slick-track {
                display: flex;
                gap: 16px;
            }

            .similar_product_pdp_web .slick-slider .slick-track {
                gap: 0px;
            }

            .slick-slide {
                flex-shrink: 0;
                width: 112px;
            }

            .slick-slider {
                width: 100%;
                max-width: 672px;

                .slick-track {
                    width: 100% !important;

                    .slick-slide {
                        width: 227px !important;
                    }
                }
            }
        }
    }
}

.productInfo__details {
    margin-right: 0;
}

.productInfo {

    .productInfo__carousel {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: flex-start;
        align-items: flex-start;

        .pdpimagecarousel {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 24px;
            height: 100%;
            width: 100%;
            max-height: 598px;
            max-width: 672px;

            .pdpimagecarousel__prevArrow {
                display: none;
            }

            .pdpimagecarousel__nextArrow {
                display: none;
            }

            &>img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 10px;
                flex: 0 0 calc(100% - 104px);
                max-width: calc(100% - 104px);
                max-height: 568px;
                min-height: inherit;
            }
        }
    }

    //     h5 {
    //         text-align: left;
    //         width: 100%;
    //         color: #000;
    //         font-size: 22px;
    //         font-weight: 600;
    //         line-height: 27.567px;
    //         text-transform: capitalize;
    //     }

    //     .pdpdetails__quotesku {
    //         display: flex;
    //         align-items: center;
    //         justify-content: space-between;
    //         width: 100%;

    //         &>p {
    //             margin: 0;
    //             color: rgba(43, 43, 43, 0.50);
    //             font-size: 13px;
    //             font-weight: 400;
    //             line-height: 15px;
    //             text-transform: capitalize;
    //         }

    //         .reaquest__button {
    //             color: #22A38C;
    //             font-size: 14px;
    //             font-weight: 400;
    //             line-height: 18.76px;
    //             text-transform: capitalize;
    //         }
}

//     .productInfo__details {
//         flex: 1;
//         max-width: 760px;
//         border: 1px solid rgba(190, 201, 195, 1);
//         border-radius: 8px;
//         padding: 24px;
//     }


//     .front,
//     .back {
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         backface-visibility: hidden;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//     }

//     .front {
//         background-color: $seafoam;
//         color: white;
//         font-weight: 600;
//     }

//     .back {
//         background-color: #3366cc;
//         transform: rotateY(.5turn);
//         color: white;
//         font-weight: 600;
//     }

//     .container:hover .box {
//         transform: rotateY(.5turn);
//     }

// }

// .INFO__PAGE {
//     .blocks {
//         .blocks__header {
//             h3 {
//                 font-size: 20px !important;
//             }
//         }
//     }
// }

@media(max-width: 1440px) {
    .productInfo {
        margin: 1em 1.5%;
    }
}

@media(max-width: 1024px) {
    .productInfo {
        flex-direction: column;

        &>* {
            max-width: 100% !important;
        }

        .productInfo__details {
            width: 100%;
        }
    }
}

@media(max-width: 912px) {
    .productInfo {
        gap: 0em;
        margin: 0;
        padding: 0;

        .productInfo__carousel {
            max-width: 100%;
        }
    }

    .PDPMOBILE__SIMILAR {
        background: linear-gradient(0deg, var(--grey-5, rgba(25, 59, 103, 0.05)) 0%, var(--grey-5, rgba(25, 59, 103, 0.05)) 100%), var(--white, #FFF);
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 1em;
        gap: 10px;

        &>* {
            width: calc(100% / 4 - 10px) !important;
            background-color: white;
        }

        .PC__small {
            border-radius: 8px;
        }
    }

    .INFO__TITLE,
    .INFO__TITLE1 {
        padding: 10px;
    }

    .INFO__PAGE {
        .productInfo {
            &>* {
                width: 95%;
                margin: 0 auto !important;
            }

            .productInfo__carousel {
                width: 100%;
            }
        }
    }

    .similar_product_mobile {
        padding: 0px;
        background-color: #FFFFFF;
        display: flex;
        gap: 16px;
        flex-direction: column;
    }

    .PDPMOBILE__SIMILAR {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        white-space: nowrap;
        padding: 0px;
        flex-wrap: nowrap;
        padding: 0px !important;
        width: 100%;
    }

    .PDPMOBILE__SIMILAR>* {
        flex: 0 0 auto;
        margin-right: 10px;
    }

}

@media(max-width: 768px) {
    .PDPMOBILE__SIMILAR {
        padding-bottom: 2em;

        &>* {
            width: calc(100% / 3 - 10px) !important;
        }

    }
}

@media(max-width: 580px) {
    .PDPMOBILE__SIMILAR {
        padding: 0 10px;
        padding-bottom: 2em;

        &>* {
            width: calc(100% / 2 - 10px) !important;
        }

    }

    .INFO__TITLE {
        color: #192434;
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        width: 100%;
        padding: 2em 10px 10px;
        margin: 0;
        background: linear-gradient(0deg, var(--grey-5, rgba(25, 59, 103, 0.05)) 0%, var(--grey-5, rgba(25, 59, 103, 0.05)) 100%), var(--white, #FFF);
    }

    .INFO__TITLE1 {
        color: rgba(27, 43, 65, 0.69);
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        display: none;
        padding: 0 10px 2em;
        background: linear-gradient(0deg, var(--grey-5, rgba(25, 59, 103, 0.05)) 0%, var(--grey-5, rgba(25, 59, 103, 0.05)) 100%), var(--white, #FFF);
    }
}