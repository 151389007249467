// $seafoam: #37bfa7;

// .breadcrumbs {
//   margin: 2em 0;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   gap: 1em;
//   opacity: .6;
//   color: gray;
//   flex-wrap: wrap;

//   img {
//     cursor: pointer;
//     max-width: 24px;
//     max-height: 24px;
//     width: 100%;
//     height: 100%;
//     object-fit: contain
//   }

//   a,
//   p {
//     font-size: 18px;
//     margin: 0;
//     cursor: pointer;
//     color: gray;
//     text-decoration: none;

//     &:hover {
//       opacity: 1;
//       color: gray;
//     }


//   }
//   .breadcrumbs__category, .breadcrumbs__variety {
//     &::before {
//       content: ">";
//       margin-right: 8px;
//       pointer-events: none;
//     }
//   }

//   .breadcrumbs__home {

//     &::before,
//     &::after {
//       content: unset;
//     }
//   }

//   .breadcrumbs__sku {
//     color: $seafoam !important;
//     font-weight: 600;
//   }

//   &:hover {
//     opacity: 1;
//   }
// }

// @media(max-width: 1440px) {
//   .breadcrumbs {
//     margin: 2em 1.5%;
//   }
// }

// @media(max-width: 1024px) {
//   .breadcrumbs {
//     opacity: 1;
//   }
// }

.breadcrumbs {
  display: flex;
  align-items: center;
  // margin-top: 20px;
  padding: 24px 0px;

  a,
  span {
    text-decoration: none;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 4px;
    width: max-content;
  }
}