.ORDERDETAILS {
    margin: 3em auto;
    max-width: 1440px;

    .ORDERDETAILS__BREADCRUMBS {
        margin-bottom: 1em;

        p {
            margin: 0;
            display: flex;
            align-items: center;
            gap: 20px;

            &>* {
                color: #000;
                font-size: 12px;
                font-weight: 400;
            }

            a {
                text-decoration: none;
                color: #000;
                cursor: pointer;
                position: relative;

                &::after {
                    content: ">";
                    position: absolute;
                    right: -15px;
                    top: 1px;
                }
            }
        }
    }

    .ORDERDETAILS__BACK {
        margin-bottom: 1em;
        cursor: pointer;
        display: flex;
        align-items: center;
        text-decoration: none;
        gap: 8px;
        width: max-content;

        h5 {
            color: #000;
            font-size: 24px;
            font-weight: 600;
            text-transform: capitalize;
            margin: 0;
        }
    }

    .ORDERDETAILS__CONTENT {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .ORDERDETAILS__LEFT {
            max-width: 955px;
            width: 100%;

            .ORDERDETAILS__INFO {
                background-color: #FFF;
                height: 122px;
                padding: 16px;
                border-radius: 8px;
                position: relative;
                display: flex;
                flex-direction: column;
                margin-bottom: 2em;

                .ORDERDETAILS__INFO__ORDERINFO {
                    margin-bottom: 1em;
                    display: flex;
                    align-items: center;

                    span {
                        width: 30%;
                        color: #000;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26.32px;
                        text-transform: capitalize;
                    }
                }

                .ORDERDETAILS__INFO__CUSTOMERINFO {
                    display: flex;
                    align-items: center;

                    span {
                        min-width: 30%;
                        width: max-content;
                        color: var(--text-body-light, rgba(25, 39, 57, 0.94));
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 15px;
                        text-transform: capitalize;
                        display: flex;
                        gap: 8px;
                    }

                    .CUSTOMERINFO__LEFT {
                        align-items: flex-start;
                    }

                    .CUSTOMERINFO__RIGHT {
                        align-items: center;
                    }

                    .CUSTOMERINFO__DATA {
                        display: flex;
                        flex-direction: column;
                        align-items: baseline;
                    }
                }

                .ORDERDETAILS__BGTOP,
                .ORDERDETAILS__BG__INERSECT {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }

                .ORDERDETAILS__BGTOP {
                    opacity: .5;
                }
            }
        }

        .ORDERDETAILS__RIGHT {
            max-width: 373px;
            width: 100%;
        }
    }

    .ORDERDETAILS__DATA {
        margin-top: 1em;
    }
}

@media(max-width: 1440px) {
    .ORDERDETAILS {
        &>* {
            margin: 0 1em;
        }

        .ORDERDETAILS__CONTENT {
            gap: 1em;
        }
    }
}

@media(max-width: 1200px) {
    .ORDERDETAILS {
        .ORDERDETAILS__CONTENT {
            flex-direction: column;

            &>* {
                max-width: 100% !important;
            }
        }
    }
}

@media(max-width: 912px) {
    .ORDERDETAILS {
        margin: 0;

        .ORDERDETAILS__BACK {
            margin: 1em;
        }

        .ORDERDETAILS__BREADCRUMBS {
            display: none;
        }

        .ORDERDETAILS__CONTENT {
            margin: 0;
            margin-bottom: 90px;

            .ORDERDETAILS__LEFT {
                .ORDERDETAILS__INFO {
                    height: unset;
                    margin-bottom: 0;

                    .ORDERDETAILS__BG__INERSECT,
                    .ORDERDETAILS__BGTOP {
                        display: none;
                    }

                    .ORDERDETAILS__INFO__ORDERINFO {
                        gap: 3em;
                        margin-bottom: 13px;
                        justify-content: space-between;

                        span {
                            max-width: 200px;
                            width: 100%;
                            text-align: left;
                            color: #000;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 18px;
                            text-transform: capitalize;
                        }
                    }

                    .ORDERDETAILS__INFO__CUSTOMERINFO {
                        gap: 3em;

                        &>span {
                            max-width: 200px;
                            width: 100%;
                        }

                        span svg {
                            display: none;
                        }

                        .CUSTOMERINFO__RIGHT {
                            span {
                                flex-direction: column;
                            }
                        }

                        .CUSTOMERINFO__LEFT {
                            .CUSTOMERINFO__DATA {
                                span {
                                    &:first-child {
                                        color: rgba(25, 39, 57, 0.94);
                                        font-size: 12px;
                                        font-weight: 400;
                                        line-height: 14px;
                                        letter-spacing: -0.12px;
                                        width: 100%;
                                        overflow: hidden;
                                    }

                                    &:last-child {
                                        color: rgba(25, 39, 57, 0.94);
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 16px;
                                    }
                                }
                            }
                        }

                        .CUSTOMERINFO__RIGHT {
                            span {
                                color: rgba(25, 39, 57, 0.94);
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 14px;
                                letter-spacing: -0.12px;

                                span {
                                    color: rgba(25, 39, 57, 0.94);
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 16px;
                                }
                            }
                        }
                    }
                }

                .ORDERDETAILS__DATA {
                    background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
                    padding: 16px;
                    margin: 0;

                    h5 {
                        // color: #FFF;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26.32px;
                        text-transform: capitalize;
                    }
                }
            }

            .ORDERDETAILS__RIGHT {
                .ORDERSUMMARY {
                    h5 {
                        font-size: 16px;
                    }
                    .ORDERSUMMARY__REORDER {
                        display: flex;
                        height: 52px;
                        padding: 14px 16px;
                        justify-content: center;
                        align-items: center;
                        flex: 1 0 0;
                        border-radius: 8px;
                        color: #fff;
                        background-color: #37BFA7;
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}