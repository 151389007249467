$defaultColor: #0da5e8;
$seafoam: #37bfa7;
.loader {
  position: fixed;
  max-width: 100vw;
  height: 100vh;
  background-color: gray;
  top: 0;
  bottom: 0;
  z-index: 9999;
  left: 0;
  opacity: .2;
  right: 0;
}
.loader__container {
    position: absolute;
    width: 300px;
    height: 300px;
    top: 100px;
    bottom: 100px;
    left: 50px;
    right: 100px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .item {
    width: 100px;
    height: 100px;
    position: absolute;
    border-radius: 100%;

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        fill: #fff;
    }
  }
  
  .item-1 {
    // background-color: rgba($seafoam, 1);
    top: 100px;
    left: 100px;
    z-index: 1;
    animation: cube-rotate 2s infinite linear;
  }

  @keyframes cube-rotate {
    0% {
      // transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    50% {
      // transform: rotateX(0turn) rotateY(1turn) rotateZ(0turn);
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }