.MAINBLOCK {
    background-color: #FFF;
    padding: 16px 60px;
    border-radius: 8px;

    .MAINBLOCK__HEAD {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0;
        }

        .MAINBLOCK__HEAD__LEFT,
        .MAINBLOCK__HEAD__RIGHT {
            display: flex;
            align-items: center;
            gap: 1em;
        }

        .MAINBLOCK__ORDERNO {
            color: rgba(25, 39, 57, 0.94);
            font-size: 18px;
            font-weight: 600;
            line-height: 22.554PX;
            text-transform: capitalize;
        }

        .MAINBLOCK__DATE {
            color: rgba(27, 43, 65, 0.69);
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
        }

        .MAINBLOCK__TOTAL {
            color: rgba(25, 39, 57, 0.94);
            font-size: 22px;
            font-weight: 600;
            line-height: 36.19px;
            text-transform: capitalize;
        }

        .MAINBLOCK__DETAILS {
            color: #FFF;
            background-color: #37BFA7;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: -0.24px;
            text-transform: capitalize;
            border: 0;
            outline: 0;
            padding: 8px 14px;
            border-radius: 6px;
            text-decoration: none;
        }
    }

    .MAINBLOCK__TABLE {
        padding-left: 20px;
        margin-top: 2em;

        .MAINBLOCK__TABLE__HEAD,
        .MAINBLOCK__TABLE__BODY {
            display: flex;
            gap: 1em;

            span {
                width: 150px;
                text-align: left;
                color: rgba(27, 43, 65, 0.69);
                font-size: 14px;
                font-weight: 400;
                line-height: 18.76px;
            }

            .FIRST {
                width: 372px;
                display: flex;
                gap: 8px;

                span {
                    color: rgba(25, 39, 57, 0.94);
                }
            }
        }

        .MAINBLOCK__TABLE__LIST {
            display: flex;
            flex-direction: column;

            p {
                margin: 0;
                display: flex;
                align-items: center;
                gap: 1em;
                padding: 8px 0;
                margin-left: -9px;

                span {
                    width: 150px;
                    text-align: left;
                    color: rgba(25, 39, 57, 0.94);
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    padding: 4px 10px;
                    border-radius: 6px;
                }

                .FIRST {
                    width: 372px;
                }

                .GREEN {
                    color: #058B73;
                    background-color: #E2FBE9;
                    width: max-content;
                }

                .BLUE {
                    color: #2179E8;
                    background-color: #F2F8FF;
                    width: max-content;
                }

                .YELLOW {
                    color: #EB8D00;
                    background-color: #FFF7E3F0;
                    width: max-content;
                }

                .RED {
                    color: #CA150C;
                    background-color: rgba(231, 93, 85, 0.10);
                    width: max-content;
                }
            }
        }
    }
}

@media(max-width: 912px) {
    .MAINBLOCK {
        .MAINBLOCK__TABLE {
            .MAINBLOCK__TABLE__HEAD {
                display: none;
            }
            .MAINBLOCK__TABLE__BODY {
                flex-direction: column;
                border-bottom: 1px solid lightgray;

                &:last-child {
                    border-bottom: 0;
                }

            }
        }
    }
}

@media(max-width: 800px) {
    .MAINBLOCK {
        padding: 16px;

        .MAINBLOCK__TABLE {
            .MAINBLOCK__TABLE__LIST {
                p {
                    border-bottom: 1px solid lightgray;
                    flex-wrap: wrap;
                }
            }
        }
    }
}