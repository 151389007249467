.CATALOGPLP__CONTENT .PRODUCTCARD {
    width: 276px;
}

.HOME__BLOCKS {
    .PRODUCTCARD {
        // width:calc(100% - 10px);
        //height: 398px;
    }
}
// .product_btn_wrapper img ~ .country-info {
//     margin-left: 7px;
// }
// .PRODUCTCARD .product_btn_wrapper .CARD__PREORDER ~ .country-info {
//     margin-left: 7px;
// }
.country-info {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 4px 1px 0px;
    grid-gap: 6px;
    gap: 6px;
    background: #CEE9DC;
    border-radius: 16px;
    position: relative;
    width: auto;
    max-width: max-content;
    margin-left: 0;
    top: 0;
    left:10px;
    .country-flag {
        width: 18px;
        height: auto;
        margin-right: 0;
        max-width: 10px;
        max-height: 10px;
        left: 0;
        vertical-align: middle;
        min-width: 15px;
        min-height: 19px;
        position: inherit;
        top: 0;
        padding: 0;
        margin-left: 5px;
    }
    .country-name {
        width: 16px;
        height: 18px;
        /* font-family: 'Inter', sans-serif; */
        /* font-style: normal; */
        font-weight: 800;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #166B54;
        flex: none;
        /* order: 1; */
        flex-grow: 0;
    }
}
a {
    position: relative;

    .CARD__PREORDER {
        position: absolute;
        bottom: 16px;
        left: 16px;
        color: #22A38C;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.12px;
        padding: 4px 8px;
        border-radius: 20px;
        background: #DFFDE9;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    }
}
.country-info {
    display: flex;
    align-items: center;
    margin-left: -11px;
  }
  
  .country-flag {
    width: 20px;
    height: auto;
    margin-right: 5px;
  }
  
  .country-name {
    font-size: 12px;
    font-weight: 800;
  }
  
.PRODUCTCARD {
    // height: 398px;
    width: 276px;
    // width:calc(100% - 10px);
    background-color: #FFF;
    border-radius: 6px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    .ORDERTAKING__CALENDAR {
        position: absolute;
        right: 20px;
        top: 45%;
        z-index: 999;
    }

    a {
        position: relative;

        // .CARD__PREORDER {
        //     width: max-content;
        //     position: absolute;
        //     bottom: 16px;
        //     left: 16px;
        //     color: #22A38C;
        //     font-size: 12px;
        //     font-weight: 500;
        //     line-height: 16px;
        //     letter-spacing: -0.12px;
        //     padding: 4px 8px;
        //     border-radius: 20px;
        //     background: #DFFDE9;
        //     box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
        // }

        .snow-img {
            max-width: 20px;
            max-height: 20px;
            position: absolute;
            bottom: 18px;
            left: 16px;
        }

        .CARD__PREORDER+.snow-img {
            left: 95px;
        }

        .quote-img {
            max-width: 20px;
            max-height: 20px;
            position: absolute;
            bottom: 18px;
            right: 16px;
        }
    }

    .PC__IMAGE {
        min-height: 207px;
        max-height: 207px;
        height: 100%;
        max-width: 100%;
        width: 100%;
        object-fit: contain;
        padding-top: 9px;
        cursor: pointer;
    }

    .QUOTE__PRODUCT {
        background-color: #F0FFF9;
        position: relative;

        .QUOTES {
            // position: absolute;
            // right: 16px;
            // bottom: 2.5em;
        }
    }

    .CATALOG__PRODUCT {
        background-color: white;
    }

    .PC__CONTENT {
        padding: 8px 16px 16px 16px;
        max-height: 161px;
        height: 100%;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        .PC__NAME {
            overflow: hidden;
            color: rgba(25, 39, 57, 0.94);
            height: 40px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.28px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .PC__SKU {
            overflow: hidden;
            color: rgba(27, 43, 65, 0.69);
            text-overflow: ellipsis;
            font-size: 12px;
            font-weight: 500;
            // line-height: 14px;
            text-transform: capitalize;
            // margin-bottom: 0px;
        }

        .PC__PACKSIZE {
            color: rgba(25, 39, 57, 0.94);
            font-size: 14px;
            font-weight: 400;
            line-height: 18.76px;
        }

        .PC__QUOTATION__PRICE {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-top: 4px;
            // margin-bottom: 8px;
        }

        .PC__PRICE {
            color: #22A38C;
            font-size: 18px;
            font-weight: 600;
            line-height: 22.554px;
            text-transform: capitalize;
            .symbol{
                font-weight: 500;
            }
        }

        .PC__PRICE--dash {
            color: rgba(28, 48, 74, 0.52);
            font-size: 14px;
            font-weight: 400;
            line-height: 15px;
            text-decoration: line-through;
            text-transform: capitalize;
        }

        a {
            text-decoration: none;

            .PC__UNAVAILABLE {
                color: rgba(25, 39, 57, .94);
                font-size: 12px;
                font-weight: 400;
                line-height: 18.76px;
                margin-top: -3px;
                display: block;

                img.PC__UNAVAILABLE_IMG {
                    position: absolute;
                    max-width: 20px;
                    max-height: 20px;
                    bottom: 0;
                    right: 4px;
                }
            }
        }

    }
}

.HOVERED__CARD {
    position: relative;

    .HOVERED__CARD__FAV {
        position: absolute;
        right: 16px;
        top: 14px;
        cursor: pointer;
        z-index: 99;
    }

    .HOVERED__CARD__ADDCART {
        z-index: 99;
        position: absolute;
        right: 16px;
        top: 40%;
        cursor: pointer;
        max-width: 32px;
        max-height: 32px;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .CART__NOSTOCK {
        filter: grayscale(100%);
    }
}

.PRODUCTCARD {
    .product_btn_wrapper {
        // position: relative;
        // padding-bottom: 10px;
        // min-height: 34px;.product_btn_wrapper {
  display: flex; // Add this line
  align-items: center; // Align items vertically centered
  gap: 10px; // Optional: Add space between items


        .CARD__PREORDER {
            left: 0;
            position: relative;
            bottom: 0;
            max-height: 20px;
            vertical-align: middle;
            padding: 2px 8px 4px 8px;
            background: #DFFDE9;
            font-size: 10px;
            color: #22A38C;
            line-height: 16px;
            font-weight: 500;
            border-radius: 4px;


            &+img.snow-img {
                left: 80px;
            }
        }

        img.snow-img {
            max-width: 20px;
            max-height: 20px;
            bottom: 8px;
            left: 16px;
            vertical-align: middle;
        }

        img.quote-img {
            max-width: 25px;
            max-height: 25px;
            position: absolute;
            bottom: 8px;
            right: 16px;
            vertical-align: middle;
            min-width: 20px;
            min-height: 20px;
        }
    }
}

/* new css start */
.PRODUCTCARD {
    // height: 380px;
}

.PRODUCTCARD .PC__IMAGE {
    min-height: 194px;
    max-height: 194px;
}

.PRODUCTCARD .product_btn_wrapper {
    position: relative;
    padding-bottom: 3px;
    min-height: 27px;
    top: 0;
    display: block;
    margin-bottom: 4px;
    margin-left:15px;
    display: flex;
}

.PRODUCTCARD .product_btn_wrapper img.snow-img {
    bottom: 3px;
}

.PRODUCTCARD .PC__CONTENT {
    max-height: 143px;
}

.PRODUCTCARD .PC__CONTENT .PC__NAME {
    height: 38px;
    font-size: 16px;
    //font-weight: 300;
    line-height: 19.2px;
    margin-bottom: 4px !important;
}

.PRODUCTCARD .PC__CONTENT .PC__SKU {
    // font-size: 16px;
    // font-weight: 600;
    line-height: 16px;
    margin-bottom: 10px;
}

.PRODUCTCARD .PC__CONTENT .PC__PACKSIZE {
    color: rgba(27, 43, 65, .69);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.PRODUCTCARD .PC__CONTENT .PC__PRICE {
    font-size: 18px;
    // font-weight: 700;
    line-height: 22.55px;
    margin: 0;
}

.PRODUCTCARD .PC__CONTENT .PC__PRICE span.PC__UNIT {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.76px;
}

.PRODUCTCARD .QUOTE__PRODUCT .QUOTES {
    bottom: 30px;
    max-width: 16px;
}

.PRODUCTCARD .QUOTE__PRODUCT {
    margin-top: 0px;
}

.PRODUCTCARD .PC__CONTENT.QUOTE__PRODUCT {
    // max-height: 153px;
    max-height: auto !important;
}

/* end */
@media only screen and (min-width: 1025px) and (max-width: 1120px) {
    .PRODUCTCARD {
        width: 95%;
    }
}

@media only screen and (max-width: 912px) {
   .PRODUCTCARD .PC__IMAGE {
        min-width: 180px;
        max-height: 135px;
    }
    
    
    .PC__CONTENT {
        padding: 8px 8px 16px 8px !important;

        .PC__NAME {
            font-family: Lato;
            font-size: 13px !important;
            font-weight: 600 !important;
            line-height: 16.29px !important;
            text-align: left;
            color: rgba(25, 39, 57, 0.94) !important;
            // margin: 0px !important;
            margin: 0px 0px 2px 0px !important;
            height: 32px !important;
        }

        .PC__SKU {
            font-family: Lato;
            font-size: 12px !important;
            font-weight: 500 !important;
            line-height: 16px !important;
            text-align: left !important;
            color: rgba(28, 48, 74, 0.52) !important;
            margin-bottom: 8px !important;
        }

        .PC__PACKSIZE {
            color: rgba(28, 48, 74, 0.52) !important;
            font-family: Lato !important;
            font-size: 12px !important;
            font-weight: 500 !important;
            line-height: 16px !important;
            text-align: left !important;
        }

        .PC__QUOTATION__PRICE {
            margin: 2px 0px 0px !important;
        }
    }

    .PRODUCTCARD .product_btn_wrapper img.snow-img {
        left: 8px;
    }

    .home .HOME__NEWARRIVALS .PC__small:last-child {
        /*width: 180px;*/
        width: calc(90% - 5px);
        // height: 277px;
        border-radius: 8px;
        overflow: hidden;
        padding-bottom: 0px;
    }

    .home .HOME__NEWARRIVALS .PC__small:last-child a img {
        max-height: 95px;
    }

    .home .HOME__NEWARRIVALS .PC__small:last-child .product_btn_wrapper {
        top: 0;
    }

    .home .HOME__NEWARRIVALS .PC__small:last-child .CATALOG__PRODUCT {
        height: 124px;
        padding-top: 10px;
    }

    .home .HOME__NEWARRIVALS .PC__small:last-child .QUOTE__PRODUCT {
        height: 124px;
        padding-top: 10px;
    }
}

@media(max-width: 580px) {
    .PC__small {
        // a .CARD__PREORDER{
        //     font-size: 10px;
        //     // bottom: -48px;
        // }
    }
    .PRODUCTCARD{
        width: calc(100% - 10px);
    }
}