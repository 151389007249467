.PRODUCTPLP {
  max-width: 1445px;
  margin: 16px auto 60px;
  border-radius: 15px;


  p {
    margin: 0;
  }

  .my_product_sidebar {
    flex: 0 0 260px;
    padding: 19px;
    background-color: white;
    border-radius: 8px;

    .sidebar_1 {
      margin-bottom: 0px;
    }

    .sidebar_1 div {
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }

    .sidebar_1 div:hover {
      color: rgba(55, 191, 167, 1);
    }

    .sidebar_1 {
      div {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: rgba(25, 39, 57, 1);
        width: fill;
        height: 48px;
        padding: 12px 16px 12px 16px;
        border-radius: 4px 0px 0px 0px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      .active {
        color: rgba(255, 255, 255, 1);
        border-radius: 5px;
        background-color: rgba(55, 191, 167, 1);

        // .same_size {
        //   width: 24px;
        //   height: 24px;
        // }

        // .diff_size {
        //   width: 20px;
        //   height: 16px;
        //   margin-right: 4px;
        // }
      }

      .active:hover {
        color: rgba(255, 255, 255, 1);
      }
    }

    .sidebar_2 {
      .categories_heading {
        color: rgba(27, 43, 65, 0.69);
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
      }
    }

    .PRODUCTPLP__SIDEBAR {
      max-width: 240px;
      width: 100%;
      // padding: 10px 30px;
      background-color: white;
      border-radius: 8px;
      padding: 16px 0px 16px 0px;

      .category-heading {
        color: rgba(27, 43, 65, 0.69);
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        padding: 4px 16px 4px 16px;
      }

      .all_category {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: rgba(25, 36, 52, 1);
        padding: 12px 16px 12px 16px;
      }

      .all_category.selectedFilter {
        color: #22A38C;
      }

      button {
        padding: 0px;
      }

      button:hover {
        color: #4b596c;
      }

      .category-header {
        color: rgba(25, 36, 52, 1);
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin-bottom: 12px;
        cursor: pointer;

      }

      .category-header.selectedFilter {
        background-color: #FFF;
        color: #22A38C !important;

        &:hover {
          background-color: #FFF !important;
        }
      }

      .accordion {
        .accordion-item {
          background-color: unset;
          border: unset;
          padding: 12px 16px 12px 16px;

          button {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: rgba(25, 36, 52, 1);
            background-color: white;

            button {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: rgba(25, 36, 52, 1);
            }

            &:focus,
            &:focus-visible {
              border: 0;
              box-shadow: none;
              outline: 0;
            }

            &:hover {
              color: #4b596c;
            }
          }

          .accordion-body {
            padding: 0;

            ul {
              padding: 0;

              li {

                color: rgba(25, 36, 52, 1);
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;


                list-style: none;
                padding: 3px 10px;
                margin: 0;
                padding-left: 2em;
                // color: #192434;
                // font-size: 18px;
                // font-weight: 500;
                // line-height: 22.554px;
                margin-top: 10px;
                text-transform: capitalize;
                width: 100%;
                min-height: 36px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &:hover {
                  cursor: pointer;
                  color: #4b596c;
                  // background-color: lightgray;
                }
              }
            }
          }

          .accordion-button:not(.collapsed) {
            box-shadow: none;
          }

          .selectedFilter {
            background-color: #FFF;
            color: #22A38C !important;

            &:hover {
              background-color: #FFF !important;
            }
          }
        }
      }
    }

  }

  .my_product_content_header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 24px 16px 24px;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    border-radius: 8px;

    .my_product_content_header_first {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .heading {
      font-family: Lato;
      font-size: 28px;
      font-weight: 700;
      line-height: 35.08px;
      text-align: left;
      color: rgba(0, 0, 0, 1);
    }

    .heading_mobile {
      display: none;
    }


    .info {
      font-family: Lato;
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      text-align: left;
      color: rgba(25, 39, 57, 1);
    }

    .search_box_wrapper {
      // width: 420px;
      // height: 50px;
      width: 400px;
      height: 50px;
      display: flex;
      padding: 8px;
      border-radius: 6px;
      background: var(--Color-Grey-5, #193B670D);
      margin: 0px;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .search_btn {
        // width: 9%;
        // height: 34px;
        width: 35px;
        height: 35px;
        border: 1px solid #37bfa7;
        background: #37bfa7;
        color: #fff;
        font-size: 12px;
        padding: 8px;
        border-radius: 5px;
      }
    }

  }


  .PRODUCTPLP__HEADER {
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    align-items: normal;
    padding: 0px;


    h3 {
      color: #000;
      font-size: 22px;
      font-weight: 600;
      line-height: 27.567px;
      text-transform: capitalize;
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }

    .PRODUCTPLP__HEADER__MAIN {
      display: flex;
      align-items: center;
      flex: 1;
      max-width: 1149px;
      width: 100%;
      justify-content: space-between;

      .PRODUCTPLP__HEADER__TAB {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 11px;
        height: 20px;
        // visibility: hidden;

        .PRODUCTPLP__HEADER__PURCHASED {
          border-left: 1px solid #000;
          border-right: 1px solid #000;
          padding: 0 16px;
        }

        .purchased__all {
          cursor: pointer;
        }

        p {
          margin: 0;
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 13px;
          font-weight: 500;
          color: rgba(25, 39, 57, 0.94);
          line-height: 14px;
          height: 100%;

          span {
            cursor: pointer;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
          }

          .purchased__selected {
            color: #22A38C;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            text-decoration-line: underline;
            text-transform: capitalize;
          }
        }

        .purchased__selected {
          color: #22A38C;
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          text-decoration-line: underline;
          text-transform: capitalize;
        }
      }

      .PRODUCTPLP__HEADER__WISHFAV {
        display: flex;
        align-items: center;
        gap: 15px;

        a {
          text-decoration: none;
          padding: 10px 16px;
          height: 44px;
          width: 174px;
          gap: 10px;
          background-color: #FFF;
          border-color: rgba(28, 55, 90, 0.16);
          color: rgba(25, 39, 57, 0.94);
          font-size: 13px;
          font-weight: 500;
          line-height: 16.289px;
          text-transform: capitalize;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          border-radius: 6px;

          svg {
            margin-right: 10px;
          }
        }
      }
    }

    .PRODUCTPLP_RIGHT_SIDE {
      display: flex;
      flex-direction: column;
      justify-content: normal;
      align-items: flex-start;
      width: 100%;
      min-height: calc(100vh - 400px);
      padding: 0px 10px;
      // gap: 48px;
      gap: 48px;
    }
  }

  hr {
    border-color: rgba(28, 55, 90, 0.16);
    opacity: 1;
  }

  .PRODUCTPLP__DATA {
    // margin-top: 2em;
    display: flex;
    gap: 3em;
    padding: 20px;


    .sidebar_2 {
      flex: 0 0 240px;
      // background-color: white;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: rgba(25, 36, 52, 1);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 4px;
      padding: 4px 16px 4px 16px;
      gap: 12px;

      .categories_heading {
        color: rgba(27, 43, 65, 0.69);
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
      }

      .categories_list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: flex-start;
      }
    }

    .PRODUCTPLP__CONTENT {
      max-width: 1185px;
      width: 100%;
      border-radius: 8px;

      .PRODUCTPLP__DATA .infinite-scroll-component {
        display: flex;
        flex-wrap: wrap;
        gap: 17px;
      }

      .PRODUCTPLP__GENERALFILTER {
        height: max-content;
        margin-bottom: 20px;

        ul {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 20px;
          row-gap: 0;

          li {
            flex-wrap: wrap;
            list-style: none;
            color: rgba(27, 43, 65, 0.69);
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            height: 27px;
            background-color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding: 4px 8px;
            cursor: pointer;
            margin-bottom: 0.2em;

            &:hover {
              color: #22A38C;
              border-color: #22A38C;
            }
          }

          .selected {
            color: #22A38C;
            border-color: #22A38C;
            background-color: #F0FFF9;
          }
        }
      }
    }
  }
}

.PRODUCTPLP {
  .PRODUCTPLP__DATA.my_product_section {
    // gap: 15px;
    gap: 0px;
    padding: 0px;

    .PRODUCTPLP__DATA {
      padding: 0;

      .PRODUCTCARD {
        width: 276px;
      }
    }
  }
}

@media (max-width: 767px) {
  .my_product_sidebar {
    display: none;
  }
}

@media(max-width: 912px) {
  .PRODUCTPLP {
    // width: calc(100% - 10px);
    width: 100%;
    max-width: 100%;
    margin: 0px;
    border-radius: 0px;

    // padding-top: 16px;
    background: linear-gradient(0deg, var(--grey-5, rgba(25, 59, 103, 0.05)) 0%, var(--grey-5, rgba(25, 59, 103, 0.05)) 100%), var(--white, #FFF);

    .my_product_content_header {
      border-radius: 0px;
    }

    .PRODUCTPLP__DATA .infinite-scroll-component {
      gap: 14px !important;
    }

    .PRODUCTPLP__DATA.my_product_section {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0px 16px;
    }


    .PRODUCTPLP__HEADER {
      flex-direction: column;
      align-items: baseline;
      gap: 8px;

      .PRODUCTPLP_RIGHT_SIDE {
        gap: 0px;

        .my_product_content_header {
          margin-bottom: 0px;
        }
      }

      .my_product_sidebar {
        display: none;
      }


      .my_product_content_header {
        padding: 26px 16px 0px 16px;

        .heading {
          display: none;
        }

        // .heading_mobile {
        //   font-family: Lato;
        //   font-size: 23px;
        //   font-weight: 700;
        //   line-height: 35.08px;
        //   text-align: left;
        //   color: black;
        // }

        .heading_mobile {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          /* align-items: center; */
          /* padding: 0px; */
          font-family: Lato;
          font-size: 22px;
          font-weight: 700;
          line-height: 27.57px;
          text-align: left;

          span {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
          }

          a {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
          }
        }

        .info {
          padding: 0px;
        }

        .search {
          width: 100%;
        }

        .search_box_wrapper {
          width: 356px;
          display: flex;
          padding: 8px;
          border-radius: 6px;
          background: var(--Color-Grey-5, #193B670D);
          margin: 0px;

          .search_btn {
            width: 9%;
            border: 1px solid #37bfa7;
            background: #37bfa7;
            color: #fff;
            font-size: 12px;
            padding: 8px;
            border-radius: 5px;
          }
        }

        .PRODUCTPLP__SIDEBAR {
          max-width: 100%;
          padding: 0px;
          background-color: white;
          border-radius: 5px;

          .PRODUCTPLP__MOBILE__SIDEBAR {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            width: 100%;
            padding-top: 5px;
            background-color: white;

            &>span {
              max-width: 36px;
            }

            .offcanvas {
              height: 80vh;
              border-radius: 24px 24px 0px 0px;

              .offcanvas-header {
                background-color: rgba(245, 245, 245, 1);
                text-transform: capitalize;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 40px;
                padding: 24px;

                button {
                  font-family: Lato;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 16px;
                  text-align: left;
                  color: rgba(54, 118, 224, 1) !important;
                }

                span {
                  color: rgba(54, 118, 224, 1);
                  font-family: 'Font Awesome 5 Free';
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 16px;
                  text-align: left;
                }

                div {
                  color: rgba(25, 36, 52, 1);
                  font-family: Lato;
                  font-size: 18px;
                  font-weight: 700;
                  line-height: 22.55px;
                }
              }

              .offcanvas-body {
                background-color: #FFF;
                padding: 0px;

                .active::after {
                  content: '✓';
                  color: rgba(55, 191, 167, 1);
                }

                ul {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;

                  padding: 0;
                  list-style: none;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;

                  height: 56px;
                  padding: 16px 24px 16px 24px;
                  gap: 10px;
                  margin: 0px;
                  border-bottom: 1px solid rgba(26, 56, 96, 0.1);

                  font-family: Lato;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                  letter-spacing: -0.01em;
                  text-align: left;
                  color: rgba(25, 39, 57, 1);

                  li {
                    color: rgba(25, 39, 57, 0.94);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                    border-radius: 4px;
                    background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
                    padding: 10px 16px;
                  }

                  .selected,
                  .selectedFilter {
                    color: #22A38C;
                    border-color: #22A38C;
                    background-color: #F0FFF9;
                  }
                }

                .offcanvas__buttons {
                  position: fixed;
                  bottom: 1em;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  max-width: 100%;
                  gap: 2.5em;
                  margin: 0 auto;

                  .offcanvas__reset,
                  .offcanvas__apply {
                    width: 150px;
                    padding: 16px;
                    border-radius: 32px;
                    border: 1px solid rgba(28, 52, 84, 0.26);
                    color: rgba(27, 43, 65, 0.69);
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 22.554px;
                    text-transform: capitalize;
                  }

                  .offcanvas__apply {
                    background-color: #37BFA7;
                    color: #FFF;
                  }
                }
              }
            }

            .PRODUCTPLP__MENU {
              display: flex;
              align-items: center;
              gap: 10px;
              width: 100%;
              white-space: nowrap;
              overflow: auto;
              // overflow-y: hidden;
              padding-bottom: 0;
              scroll-behavior: smooth;

              .selectedMobileMenu {
                position: relative;
                color: #22A38C;

                &::after {
                  content: "";
                  position: absolute;
                  left: 0;
                  right: 0;
                  bottom: 0px;
                  border-bottom: 2px solid #22A38C;
                }

              }

              span {
                height: 40px;
                padding: 0 10px;
              }
            }

            svg {
              padding: 1em;
              color: black;
            }

            span {
              white-space: nowrap;
            }
          }

        }
      }


      h3 {
        width: 100%;
        margin: 0;
        justify-content: space-between;

        .PRODUCTPLP__HEADER__WISHFAV {
          display: flex;
          align-items: center;
          gap: 8px;

          .PRODUCTPLP__WISH,
          .PRODUCTPLP__fav {
            background-color: #FFF6EC;
            padding: 6px 10px;
            border-radius: 8px;

            svg {
              margin: 0;
            }
          }

          .PRODUCTPLP__fav {
            background-color: #FFEEF2;
          }
        }
      }



      .PRODUCTPLP__HEADER__MOBILE {
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          display: flex;
          padding: 8px 16px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 2px;
          height: 50px;
          border-radius: 8px;
          border: 1px solid rgba(28, 55, 90, 0.16);
          background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;

          .MOBILE__LABEL {
            color: rgba(28, 48, 74, 0.52);
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.12px;
            text-transform: capitalize;
          }

          .MOBILE__VALUE {
            color: rgba(27, 43, 65, 0.69);
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            text-transform: capitalize;
          }
        }

        .PURCHASE__SELECTED {
          background-color: #DFF3F0;

          .MOBILE__VALUE {
            color: #22A38C !important;
          }
        }
      }

      .PRODUCTPLP_RIGHT_SIDE {
        padding: 0px;
        min-height: 100%;

        .PRODUCTPLP__DATA {}
      }
    }


    .PRODUCTPLP__CONTENT {
      padding: 1em 0px;
      min-height: 768px;

      .PRODUCTPLP__DATA {

        .no_product {
          flex: 0 0 100%;
          text-align: center;
          padding-top: 50px;
        }

        .infinite-scroll-component {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          flex-wrap: wrap;

          .PRODUCTCARD {
            max-width: calc(96% / 3);
            width: 100%;
            height: 100%;
            background-color: white;

            .PC__CONTENT {

              .PC__NAME {
                font-size: 13px;
              }

              .PC__SKU {
                font-size: 11px;
              }

              .PC__PACKSIZE {
                font-size: 12px;
              }

              .PC__PRICE {
                font-size: 16px;
              }

              .QUOTES {
                bottom: 0.5em;
                right: 0.5em;
              }
            }

            a img {
              min-height: 144px;
              max-height: 144px;
            }
          }
        }
      }
    }
  }
}

@media(max-width: 580px) {
  .my_product_section {
    .PRODUCTPLP__DATA .infinite-scroll-component__outerdiv {
      flex: 0 0 100%;
    }
  }

  .PRODUCTPLP .PRODUCTPLP__DATA .PRODUCTPLP__CONTENT .PRODUCTPLP__DATA .infinite-scroll-component .PRODUCTCARD {
    max-width: calc(94% / 2);
  }

  .no_product {
    flex: 0 0 100%;
    text-align: center;
    padding-top: 50px;
  }
}

@media(max-width: 400px) {
  .PRODUCTPLP .PRODUCTPLP__HEADER .PRODUCTPLP__HEADER__MOBILE p .MOBILE__VALUE {
    font-size: 14px;
  }
}