$seafoam: #37bfa7;

.content {
    // width: 373px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;

    .summary__label {
        max-width: 50%;
        width: 100%;
    }

    .totalpay {
        color: #22A38C;
        font-size: 18px;
        font-weight: 600;
        line-height: 125.303%;
        text-transform: capitalize;
    }
}

// .Checkout--disabled {
//     background-color: #f3f3f3;
// }

.summary__button {
    background-color: $seafoam;
    border: 0;
    outline: 0;
    padding: 12px 10px;
    border-radius: 6px;
    color: #FFF !important;
    line-height: 26.32px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.FREESHIPPING {
    display: flex;
    align-items: center;
    gap: 10px;

    .FREESHIPPING__TEXT {
        color: #22A38C;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        text-transform: capitalize;
    }
}

.CART_INFO_TEXT {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;

    .RETURNREFUND {
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
            width: 24px;
        }

        span {
            display: flex;
            flex-direction: column;

            span {
                color: var(--text-body-light, rgba(25, 39, 57, 0.94));
                font-size: 16px;
                font-weight: 600;
                line-height: 26.32px;
                text-transform: capitalize;
            }

            a {
                color: #3B82F6;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: -0.12px;
                text-decoration-line: underline;
            }
        }
    }

    .SUMMARYINFO {
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
            max-width: 24px;
            width: 100%;
            height: 100%;
        }

        span {
            color: rgba(28, 48, 74, 0.52);
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.12px;
            flex: 1;
        }
    }
}

@media(max-width: 912px) {
    .CART_INFO_TEXT {
        background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
    }

    .content {
        width: 100%;
    }
}