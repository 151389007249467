.purchaseCardSkeleton {
    z-index: 0;
    width: 100%; // Ensure this is set to 100%
    box-sizing: border-box; // Ensures padding and borders are included in the width calculation

    span {
        span {
            width: 100%;
            height: 114px;
        }
    }
}

.purchaseCardSkeleton.mobilebox {
    z-index: 0;
    width: 100%; // Ensure the mobile version also takes full width
    box-sizing: border-box; // Ensures padding and borders are included in the width calculation

    span {
        span {
            height: 146px !important;
            width: 90% !important;
            margin-left: 20px;
            margin-right: 20px;
            // width: calc(96% / 2);
        }
    }
}