$seafoam: #37bfa7;

.REQUEST_QUOTATION_MODEL {
    width: 389px !important;
    margin: 0px !important;
}

.mt-2 {
    margin-top: 32px !important;
}

.quotation__remarks {
    margin-top: 32px !important;
}

.quotation_para {
    margin-left: 15px !important;
}

.popover__msg {
    padding: 8px 16px !important;
    display: flex !important;
    margin: 0px !important;
    width: 100% !important;
}

.pdpDetails {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: 1px solid rgba(190, 201, 195, 1);
    border-radius: 8px;
    padding: 24px;



    .pdpDetails__name_sku {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .pdpDetails__name {
            font-family: Lato;
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
            text-align: left;
            color: rgba(25, 36, 52, 1);
        }

        .pdpdetails__quotesku {
            color: rgba(27, 43, 65, 0.69);
            font-family: Lato;
            font-size: 13px;
            font-weight: 400;
            line-height: 15px;
            text-align: left;

            &>p {
                margin: 0;
                font-size: 13px;
                font-weight: 400;
                text-transform: capitalize;
                color: rgba(43, 43, 43, 0.50);
            }

            &>* {
                width: calc(100% / 2 - 10px);
            }

            .requestbutton__wrapper {
                width: unset !important;
                position: absolute;
                right: 0;
                top: 24px;
            }

            &:hover>* {
                width: unset !important;
            }

            span {
                display: none;
            }

            .plpDetails__quotemsg {
                display: block;
                color: $seafoam;
                position: absolute;
                right: 0;
                top: 64px;
                width: 50% !important;
                border-radius: 8px;
                border: 1px solid var(--contrast-10-light, rgba(26, 56, 96, 0.10));
                background: #F8FEFD;
                box-shadow: 0px 3px 18px -2px rgba(28, 55, 90, 0.12);
                padding: 10px 24px;
            }
        }
    }

    .add_tocart {
        // width: 100%;
        width: 60%;
        color: #ffff !important;
        background-color: rgba(0, 85, 65, 1) !important;
        border-radius: 4px;
        font-size: 14px;
        font-family: Lato;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
        text-align: center;
        border-radius: 100px;
    }

    .pdp__cart__disabled {
        background-color: gray !important;
        cursor: none;
        font-weight: 600;
    }

    .pdpDetails__unitprice {
        display: flex;
        flex-direction: column;

        &>p {
            font-size: 18px;
            color: #2b2b2b;
        }

        .unitPrice__content {
            display: flex;
            align-items: center;
            gap: 16px;

            .vertical-line {
                width: 1px;
                background-color: rgba(28, 55, 90, 0.16);
                height: auto;
                min-height: 50px;
                margin: 0 8px
            }

            div {
                display: flex;
                flex-direction: column;
                margin: 0px;
                gap: 4px;

                .pdpDetails__unitprice--label {
                    width: max-content;
                    border-radius: 10px;
                    color: rgba(27, 43, 65, 0.69);
                    font-size: 13px;
                    font-weight: 400;
                }

                .pdpDetails__unitprice--value {
                    text-align: center;
                    color: rgba(25, 39, 57, 0.94);
                    font-size: 16px;
                }
            }
        }
    }

    .priceDetails__cartAdd {
        display: flex;
        align-items: center;
        gap: 24px;

        .priceInfo__quantity {

            input {
                width: 3em;
                border: 0;
                outline: none;
                height: 32px;
                text-align: center;
                // padding-left: 10px;
            }

            button {
                background-color: #F5F5F5;
                box-shadow: 0px 3px 6px #0000001A;
                color: #000;
                outline: none;
                border: none;
                border-radius: 100%;
                padding: 0;
                height: 32px;
                width: 32px;

                img {
                    max-width: 24px;
                    max-height: 24px;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .priceInfo__addCart {
            border: none;
            outline: none;
            background-color: $seafoam;
            color: white;
            box-shadow: 0px 3px 6px #0000001A;
            border-radius: 10px;
            padding: 5px 12px;
        }

        button {
            img {
                max-width: 24px;
                max-height: 24px;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .ordertaking__cartadd {
        // position: relative;
        // top: -4em;
        // right: -14em;
        // height: 0;
    }

    .pdpDetails__priceInfo {

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        gap: 24px;


        .priceInfo__info {
            .pdpDetails__orderCard {
                gap: 16px;
            }
        }

        .pdpDetails__orderCard {
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 100%;

            .pdpDetails__orderCard__header {
                color: rgba(25, 36, 52, 1);
                font-family: Lato;
                font-size: 16px;
                font-weight: 700;
                line-height: 19.2px;
                text-align: left;

                &>span {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    span {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

            .PDP__OC {
                display: flex;
                align-items: center;
                gap: 8px;
                width: 100%;
                flex-wrap: wrap;
                //overflow: auto;
                // scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .PDP__OC_container {
                // max-width: 760px;
                max-width: 100%;
                // overflow: auto;

                .PDP__OC {
                    gap: 8px;
                    // padding-bottom: .5rem;
                    //overflow: auto;
                    // scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }


                    .ORDERINGCARDSS {
                        width: 112px;
                        height: 56px;
                        justify-content: center;
                        flex-direction: row;
                        gap: 8px !important;
                    }

                    .ORDERINGCARDSS.SELECTEDORDER {
                        color: rgba(0, 33, 23, 1);
                        background-color: rgba(207, 238, 223, 1);
                        border: none !important;
                    }

                    .ORDERINGCARDS__DATES {
                        white-space: nowrap;
                    }
                }
            }


            .PLP__SPECIALREQUEST {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;
                // margin-bottom: 32px;

                label {
                    color: #000;
                    font-family: Lato;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: -0.12px;
                    text-align: left;
                }

                input {
                    border: 1px solid rgba(111, 121, 116, 1);
                    padding: 16px 16px;
                    border-radius: 6px;
                    color: rgba(28, 48, 74, 0.52);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: -0.12px;
                }
            }
        }

        .Ordertaking_priceInfo__info {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 0px;
            justify-content: space-between;

            .info__currency {
                color: rgba(0, 85, 65, 1);
                font-family: Lato;
                font-size: 28px;
                font-weight: 600;
                line-height: 36px;
                text-align: left;
            }

            .info__currency_unit {
                color: rgba(0, 85, 65, 1);
                font-family: Lato;
                font-size: 22px;
                font-weight: 600;
                line-height: 28px;
                text-align: left;
            }

        }

        .priceInfo__info {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 24px;
            // max-width: 760px;
            justify-content: space-between;


            // .pdp_price_block {
            //     display: flex;
            //     flex-direction: row;
            //     justify-content: flex-start;
            //     align-items: center;
            //     gap: 4px;

            // .pdp_approxPrice {
            //     display: flex;
            //     flex-direction: row;
            //     align-items: center;
            //     justify-content: flex-start;
            //     gap: 5px;

            //     // .pdpDetails__approxPrice {
            //     //     color: rgba(25, 39, 57, 1);
            //     //     font-family: Lato;
            //     //     font-size: 12px;
            //     //     font-weight: 500;
            //     //     line-height: 16px;
            //     //     text-align: left;

            //     //     svg {
            //     //         width: 16px;
            //     //     }
            //     // }

            //     .pdpDetails__priceexpiry {
            //         color: rgba(25, 39, 57, 1);
            //         font-family: Lato;
            //         font-size: 12px;
            //         font-weight: 500;
            //         line-height: 16px;
            //         text-align: left;
            //     }
            // }

            // }

            .info__currency {
                // color: $seafoam;
                // font-size: 22px;
            }

            .info__unitprice {
                color: rgba(25, 39, 57, 0.94);
                font-size: 14px;
                font-weight: 400;
                margin-left: 12px;
            }

            p {
                margin: 0;
                color: $seafoam;
                font-size: 32px;
                font-weight: 500;
                width: 100%;

                span {
                    font-size: 18px;
                    color: #0C1E2E;
                }
            }
        }

        .pdpDetails__quoteInfo {
            display: flex;
            align-items: center;
            gap: 1em;
            flex-direction: row;
            justify-content: flex-start;

            .pdpDetails__approxPrice {
                color: rgba(25, 39, 57, 1);
                font-size: 12px;
                font-weight: 400;
                text-transform: capitalize;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 4px;

                svg {
                    width: 16px;
                }
            }

            .pdpDetails__priceexpiry {
                color: rgba(25, 39, 57, 1);
                font-family: Lato;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                text-align: left;

            }

            .approxPrice__orderTaking {
                color: rgba(28, 48, 74, 0.52);
            }
        }
    }

    .line {
        border-bottom: 1px solid rgba(28, 55, 90, 0.16);
        margin: 12px 0;
    }

    .moreinfo {
        // margin-bottom: 20px;

        .moreinfo__tabs {
            h5 {
                padding: 15px 35px;
                height: auto;

                &:after {
                    bottom: 0;
                    border-width: 4px;
                    border-color: #F5F5F5;
                }
            }

            .moreinfo__selectedTab::after {
                bottom: 0;
                border-width: 4px;
                border-color: #37bfa7;
            }
        }

        .moreinfo__tabContent>span {
            font-size: 13px;
            line-height: 19px;
        }

        .moreinfo__tabContent {
            display: flex;
            flex-direction: column;
            gap: 32px;

            .row {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }

            .memo_heading {
                color: rgba(0, 0, 0, 1);
                font-family: Lato;
                font-size: 16px;
                font-weight: 700;
                line-height: 19.2px;
                text-align: left;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 16px;
            }

            span {
                font-family: Lato;
                font-size: 14px;
                font-weight: 500;
                line-height: 18.76px;
                text-align: left;
                color: rgba(25, 39, 57, 1);
            }

            .tabContent__details {
                p {
                    span {
                        font-size: 13px;
                        line-height: 19px;
                    }

                    .details--value {
                        font-size: 14px;
                        color: #192739F0;
                        line-height: 18.76px;
                    }
                }
            }
        }
    }

    .PDP__SEASONS {

        // margin: 40px 0 48px;
        margin-bottom: 16px;

        .PDP__SEASONS_Content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
        }

        small {
            color: rgba(27, 43, 65, 0.69);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.16px;
            margin-top: 8px;
        }

        .SEASON__DATA {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            margin: 24px 0px 0px 0px;

            .month_cal {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 4px;
            }

            span {
                display: flex;
                // width: 52px;
                padding: 10px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                background: #F5F5F5;
                // border-top: 4px solid #EE4D44;
                border-radius: 8px;
                width: 74px;
                height: 32px;
            }

            .SEASON__HIGH {
                // border-top: 4px solid #22A38C;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                padding: 6px 16px 6px 8px;
                background-color: rgba(247, 250, 246, 1);
                border-radius: 8px;
            }

            .SEASON__LOW {
                // border-top: 4px solid #F09203;
            }
        }

        .SEASONS__AVAILABLE {
            // margin: 1em 0;
            gap: 32px;
            display: flex;
            align-items: center;
            padding-left: 8px;
            margin-left: 8px;

            span {
                color: #192739;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
            }

            .SEASON__HIGHAVAILABLE {
                position: relative;

                &::before {
                    position: absolute;
                    content: "";
                    width: 8px;
                    height: 8px;
                    background-color: #22A38C;
                    left: -14px;
                    top: 4px;
                    border-radius: 100%;
                }
            }

            .SEASON__LOWAVAILABLE {
                position: relative;

                &::before {
                    position: absolute;
                    content: "";
                    width: 8px;
                    height: 8px;
                    background-color: #F09203;
                    left: -14px;
                    top: 4px;
                    border-radius: 100%;
                }
            }

            .SEASON__NOTAVAILABLE {
                position: relative;

                &::before {
                    position: absolute;
                    content: "";
                    width: 8px;
                    height: 8px;
                    background-color: #EE4D44;
                    left: -14px;
                    top: 4px;
                    border-radius: 100%;
                }
            }
        }
    }
}

@media(max-width: 1200px) {
    .pdpDetails {
        .pdpDetails__priceInfo {
            .priceInfo__info {
                justify-content: unset;

                p {
                    font-size: 24px;
                }
            }
        }
    }
}

@media(max-width: 1024px) {
    .pdpDetails {

        .pdpDetails__unitprice,
        .pdpdetails__quotesku,
        .pdpdetails__quotesku .pdpDetails__name {
            max-width: 100%;
        }

        .pdpDetails__unitprice--label {
            font-size: 14px !important;
        }
    }
}


@media(max-width: 912px) {
    .productInfo .productInfo__carousel {
        gap: 0px !important;
    }

    .PDP__MOBILE__CARTADD {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 16px 0px 32px;
        background: white;
        max-width: 100%;
        // margin-left: 1%;
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;

        &>svg {
            position: absolute;
            left: 34%;
            bottom: 8px;
        }

        img {
            // width: 44px;
            object-fit: contain;
        }

        &>.priceInfo__quantity {
            width: 160px;
            display: flex;
            align-items: center;
            gap: 4px;
            border-radius: 32px;
            border: 1px solid rgba(28, 52, 84, 0.26);
            padding: 9px 8px;

            input {
                border: 0;
                outline: 0;
                max-width: 50%;
                text-align: center;
            }

            button {
                border-radius: 45px;
                background: #f5f5f5;
                border: 0;
                outline: 0;
                height: 32px;
                width: 32px;
                padding: 0;
                padding-bottom: 5px;
                // display: flex;
                // justify-content: center;
                // align-items: center;
            }
        }
    }

    //   ------------------

    .PDP__MOBILE__CARTADD {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(247, 250, 246, 1);
        z-index: 1000;
        padding: 16px 16px 34px 16px;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    }

    /* Optional: Adjust styles for buttons and input to fit better */
    .PDP__MOBILE__CARTADD .priceInfo__quantity {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(207, 238, 223, 1);
        border: none;
    }

    .PDP__MOBILE__CARTADD button {
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 10px;
    }

    .PDP__MOBILE__CARTADD input[type='number'] {
        width: 50px;
        /* Adjust width as needed */
        text-align: center;
        background-color: rgba(207, 238, 223, 1);
    }

    //   ------------------
    .pdpdetails__quotesku {
        margin: 0 !important;

        p {
            color: rgba(28, 48, 74, 0.52);
            font-size: 14px;
            font-weight: 400;
            line-height: 18.76px;
        }

        .pdpDetails__unitprice--label,
        .pdpDetails__unitprice--value {
            color: #192739;
            font-size: 14px;
            font-weight: 400;
            line-height: 18.76px;
        }
    }

    .pdpDetails {
        padding: 0px 16px 16px 16px !important;
        gap: 16px !important;
        border: none;

        .mobile_request_quotation {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        .pdpDetails__name_sku {
            .pdpDetails__name {
                font-weight: 500 !important;
                font-size: 24px !important;
                line-height: 32px !important;
            }

            .pdpdetails__sku {
                font-weight: 400 !important;
                font-size: 14px !important;
                line-height: 20px !important;
                letter-spacing: 0.25px;
            }
        }

        p {
            margin: 0;
        }

        .pdpDetails__priceInfo {
            // margin: 4px 0;

            .pdpDetails__orderCard {
                gap: 12px;

                .PDP__OC {
                    flex-wrap: nowrap;
                    width: 100%;
                    overflow: auto;

                    .ORDERINGCARDS {
                        max-width: 150px;
                        width: 100%;
                    }
                }

                .PLP__SPECIALREQUEST {
                    margin-bottom: 0;

                    label {
                        color: #000;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26.32px;
                        text-transform: capitalize;
                    }

                    input {
                        color: rgba(28, 48, 74, 0.52);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18.76px;
                    }
                }

                .PLP__MOBILE__PRICE {
                    display: flex;
                    align-items: center;
                    // margin: 12px 0;
                    justify-content: space-between;
                    width: 100%;

                    .PLP__MOBILE__APPROXTEXT {
                        color: rgba(28, 48, 74, 0.52);
                        font-size: 12px;
                        font-weight: 400;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        .pdpDetails__priceexpiry {
                            color: #EE4D44;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18.76px;
                        }

                        span {
                            color: #EE4D44;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18.76px;
                        }
                    }

                    p {
                        color: #22A38C;
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 27.567px;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        span {
                            color: rgba(27, 43, 65, 0.69);
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 16.289px;
                            text-transform: capitalize;
                        }

                        .info__currency {
                            color: #22A38C;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 20.048px;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }

        .similar_product_mobile * {
            background-color: #FFFFFF !important;
            margin: 0px;
            padding: 0px;
            background: none;

            .INFO__TITLE,
            h5 {
                padding: 0px;
                background: none;
            }
        }
    }

    .pdpDetails .pdpDetails__priceInfo .pdpDetails__orderCard .pdpDetails__orderCard__header {

        .TIMER {
            width: 100%;
        }

        // display: flex;
        // flex-direction: row-reverse;
        // justify-content: flex-start;
        // align-items: center;
        // background-color: rgba(255, 243, 141, 1);
        // padding: 8px 16px;
    }

    .pdpDetails .pdpDetails__priceInfo .pdpDetails__orderCard .pdpDetails__orderCard__header>span {
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-start;
        // align-items: flex-start;
        // gap: 8px;

        display: flex;
        // flex-direction: column;
        align-items: center;
        gap: 8px;

        p {
            display: flex;
            align-items: center;

            &>span {
                color: rgba(240, 72, 0, 1);
                font-size: 18px;
                font-weight: 600;
                line-height: 22.554px;
                text-transform: capitalize;
            }
        }

        span {
            font-family: 'Lato';
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0.5px;
            color: #345045;
        }
    }

    .TIMER {
        width: 47%;
    }

    .MOBILE__priceInfo__info {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;

        .price_block_1 {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        p {
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 8px;

            .info__currency {
                color: rgba(0, 85, 65, 1);
                font-family: Lato;
                font-size: 32px;
                font-weight: 600;
                line-height: 40px;
                text-align: left;
            }

            span {
                color: rgba(0, 85, 65, 1);
                font-family: Lato;
                font-size: 24px;
                font-weight: 500;
                text-align: left;
                line-height: 32px;
            }

            .info__unitprice {
                color: rgba(27, 43, 65, 0.69);
                font-size: 14px;
                font-weight: 400;
                line-height: 134%;
            }

        }

        .PLP__MOBILE__APPROXTEXT {
            span {
                color: rgba(25, 39, 57, 1);
                font-family: Lato;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                text-align: left;
            }
        }
    }
}

@media(max-width: 700px) {
    .pdpDetails {
        .pdpDetails__priceInfo {
            .pdpDetails__orderCard {
                flex-wrap: wrap;
            }
        }
    }
}


.get_fress_shipping {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(241, 244, 241, 1);
    padding: 8px 16px;
    border-radius: 8px;

    img {
        width: 32px;
        height: 32px;
    }

    .get_fress_shipping_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        div {
            color: rgba(0, 81, 62, 1);
            font-family: Lato;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.25px;
            text-align: left;
        }
    }
}

.pdp_price_addtocart_parent {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    [class*="pdp_addtocart_"] {
        // flex: 0 0 60%;
        width: 100%;

        .priceDetails__cartAdd {
            display: flex;
            align-items: center;
            grid-gap: 30px;
            gap: 30px;
            top: inherit;
            right: inherit;
            height: auto;

            .priceInfo__quantity {
                display: flex;
                background-color: #cfeedf;
                padding: 4px;
                border-radius: 32px;

                button {
                    background-color: #F5F5F5;
                    box-shadow: 0px 3px 6px #0000001A;
                    color: #000;
                    outline: none;
                    border: none;
                    border-radius: 100%;
                    padding: 0;
                    height: 32px;
                    width: 32px;
                    line-height: 2px;
                }

                input {
                    // width: 70px;
                    // border: 0;
                    // outline: none;
                    // height: 32px;
                    background-color: #cfeedf;
                    text-align: center;
                    font-size: 16px;
                }
            }
        }
    }
}

.pdp_price_quote {
    width: 100%;

    .pdp_price_addtocart_parent {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        // margin-bottom: 10px;

        .pdp_price_block {
            .info__currency {
                color: rgba(0, 85, 65, 1);
                font-family: Lato;
                font-size: 28px;
                font-weight: 600;
                line-height: 36px;
                text-align: left;
            }

            .info__currency_unit {
                color: rgba(0, 85, 65, 1);
                font-family: Lato;
                font-size: 22px;
                font-weight: 600;
                line-height: 28px;
                text-align: left;
            }
        }


        // [class*="pdp_price_"] {
        //     flex: 0 0 40%;
        //     font-size: 28px;
        //     line-height: 34px;

        //     //   font-weight: 700;
        //     span.info__unitprice {
        //         display: block;
        //         margin-left: 0 !important;
        //         font-size: 13px !important;
        //     }
        // }

        [class*="pdp_addtocart_"] {
            flex: 0 0 60%;

            .priceDetails__cartAdd {
                display: flex;
                align-items: center;
                grid-gap: 30px;
                gap: 30px;
                top: inherit;
                right: inherit;
                height: auto;

                .priceInfo__quantity {
                    display: flex;

                    button {
                        background-color: #F5F5F5;
                        box-shadow: 0px 3px 6px #0000001A;
                        color: #000;
                        outline: none;
                        border: none;
                        border-radius: 100%;
                        padding: 0;
                        height: 32px;
                        width: 32px;
                        line-height: 2px;
                    }

                    input {
                        width: 70px;
                        border: 0;
                        outline: none;
                        height: 32px;
                        text-align: center;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .pdp_price_addtocart_parent {
        flex-direction: column;
        gap: 30px;
        margin-bottom: 20px;
        margin-top: 20px;

        [class*="pdp_price_"] {
            flex: 0 0 100%;
        }

        [class*="pdp_addtocart_"] {
            flex: 0 0 100%;
            width: 100%;

            .priceDetails__cartAdd {
                width: 100%;

                .add_tocart {}
            }
        }
    }

    .pdpDetails {
        .moreinfo {
            .moreinfo__tabs {
                h5 {
                    padding: 15px 15px;
                }
            }
        }
    }

}

@media only screen and (max-width: 400px) {
    .PDP__MOBILE__CARTADD {
        // flex-wrap: wrap;
    }

    .pdpDetails {
        .add_tocart {
            max-width: 100%;
        }
    }
}