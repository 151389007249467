.main .main__search .SEARCHSUGGEST {
  border-radius: 8px;
  overflow: hidden;
  padding-top: 20px;
  svg {
    position: inherit;
  }
  .SEARCHSUGGEST__HEADER{
    display: none;
    & > svg{
      //display: none;
      & + label{
        max-width: 100%;
      }
    }
    svg{
      top:0;
      left:0;
    }
  }
  .RECOMMENDED__SECTION {
    padding: 32px;
    background: #fff;
    .blocks{
      padding-left: 0;
      padding-right: 0;
      background: transparent;
      .slick-slider {
        .slick-prev,.slick-next{
          position: absolute;
          top:50%;
          transform: translateY(-50%);
        }
        .slick-prev{
          left: 0;
        }
        .slick-next{
          left: 93%;
        }
      }
      .blocks__header{
        margin-top: 0;
        h3{
          font-size: 18px;
          line-height: 22.55px;
        }
        a{
          right:0;
          width: auto;
          svg{
            position: relative;
            top: 0;
            left: 0;

          }
        }
      }
      .PC__small{
        a > img{
          max-width: 227px;
          max-height: 160px;
        }
        .product_btn_wrapper{
          display: none;
        }
      }
    }
    .HOME__BLOCKS {
      .slick-track{
        min-width: 100%;
      }
    }
  }
}

.SEARCHSUGGEST {

    overflow: auto;
    max-height: 100vh;
    // min-width: 100vh;
    // min-height: 100vh;
    background: #fff;
    position: absolute;
    width: 100%;
    top: 52px;
    box-shadow: 0px 1px 1px 1px #a3a3a3;
    z-index: 10;
  
  // min-height: 100vh;
  // //background: linear-gradient(
  // //    0deg,
  //  //   var(--grey-5, rgba(25, 59, 103, 0.05)) 0%,
  //   //  var(--grey-5, rgba(25, 59, 103, 0.05)) 100%
  //  // ),
  //  // var(--white, #fff);
  //  background: transparent;
  //   position: absolute;
  //   // top:100%;
  //   width: 100%;
.SEARCHSUGGEST__DROPDOWN {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  z-index: 1000; /* Ensure it appears above other elements */
  width: 100%; /* Match the width of the input */
  max-height: 200px; /* Limit height */
  overflow-y: auto; /* Scroll if too many suggestions */
}

.SEARCHSUGGEST__DROPDOWN ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.SEARCHSUGGEST__DROPDOWN li {
  padding: 10px;
  cursor: pointer;
}

.SEARCHSUGGEST__DROPDOWN li:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}
  .SEARCHSUGGEST__HEADER {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: #fff;
    grid-gap: 16px;
    gap: 16px;
    border-bottom: 10px solid var(--grey-5, rgba(25, 59, 103, .05));


    label {
      // max-width: 356px;
      width: 100%;
      // border: 1px solid #37bfa7;
      border-radius: 6px;
      position: relative;
      height: 48px;

      a {
        text-decoration: none;
      }

      svg {
        position: absolute;
        top: 0px;
        height: 100%;
        /* right: 0px; */
        width: 48px;
        /* background: #37bfa7; */
        border-radius: 6px;
        padding: 14px;
        bottom: 0;
      }

      input {
        width: 82%;
        height: 100%;
        padding: 0 16px;
        color: rgba(28, 52, 84, 0.26);
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.16px;
        text-transform: capitalize;
        display: flex;
        padding: 15px 83px 20px 44px;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        border-radius: 6px;
        border: 0;
        background: #f5f5f5;
      }
      .searchbtn{
        font-size: 14px;
    font-weight: 600;
    /* color: #ade1f5; */
    color: #22a38c;
    padding: 5px;
    }
  }
  }
  .SEARCHSUGGEST {
    // other styles
    
    .RECOMMENDED__SECTION {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  
    .RECOMMENDED__SECTION .HOME__BLOCKS {
      flex: 0 0 60%;
    }
  
    .RECOMMENDED__SECTION .TRENDING__SECTION {
      flex: 0 0 40%;
    }
  
    .IMAGE__SECTION {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
    }
  
    .IMAGE__SECTION .column1,
    .IMAGE__SECTION .column2 {
      flex: 1;
      margin: 0 5px;
    }
    
    .IMAGE__SECTION .column1 {
      margin-right: 10px;
    }
  }
  
  .SEARCHSUGGEST__RESULT {
    margin-top: 4px;
    padding: 0 16px;
    background-color: #fff;
    ul {
      padding: 0;
      list-style: none;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      background-color: #fff;

      a {
        text-decoration: none;
        li {
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: space-between;
          height: 56px;

          img {
            max-height: 48px;
            max-width: 48px;
            height: 100%;
            width: 100%;
            object-fit: contain;
          }

          p {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-decoration: none;
            color: rgba(27, 43, 65, 0.69);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.16px;
            margin: 0;
          }
        }
      }
    }
  }
  div[data-testid="root"].react-responsive-modal-root .react-responsive-modal-modal{
      max-width: 100% !important;
      margin: 0px !important;
    
  }
  .IMAGE__SECTION {
    width: 100%;
    padding: 0 0px 10px 10px;
  }

  .columns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    a {
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
    .column1,.column2{
      margin-right: 0 !important;
      a{
        display: block;
        overflow: hidden;
        border-radius: 8px;
      }
    }
  }

  .searchbanner1 .searchbanner2 {
    width: 54%;
    height: auto;
    border-radius: 8px;
  }
  .deals__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22.55px;
    letter-spacing: -0.16px;
    color: #192739;
  }

  .recently_searched {
    margin-top: 0px;
    padding: 12px 32px 0;
    background: #fff;

    b {
      font-size: 20px;
      line-height: 22.55px;
      color: #192739;
    }

    .recent_item_card_wrp {
      display: flex;
      align-items: center;
      grid-gap: 6px;
      padding-top: 6px;
      padding-bottom: 9px;
      flex-wrap: wrap;
      margin: 1rem 0 0;

      .recent_item_button {
        border: 2px solid rgba(28, 52, 84, .26);
        border-radius: 6px;
        background: #fff;
        color: #22a38c;
        padding: 5px 11px;
        font-size: 1rem;
        /* margin-right: -4px; */
        font-weight: 700;
        margin-left: 0px;
        margin-right: 0px;
      }
      p {
        margin: 0;
        width: 100%;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: #525252;
        line-height: 22.55px;
      }
    }

    .recent_item_extend {
      display: flex;
      justify-content: center;
      width: 100%;

      svg {
        cursor: pointer;
        width: 35px;
        height: 24px;
      }
    }
  }
}
@media only screen and (max-width: 992px){
  .SEARCHSUGGEST {
    min-height: 100%;
    background: transparent;
    position: relative;
    padding-bottom: 110px;
    top:0px;
}
.SEARCHSUGGEST .SEARCHSUGGEST__RESULT{
  //  ul{
  //   flex-direction: row !important;
  //   flex-wrap: wrap;
  //   gap: 10px;
  //   justify-content: space-between;
  //   li{
  //     width: 48%;
  //     img{
  //       max-width: 100%;
  //     }
  //   }
  //  }
  ul {
    list-style: none;
    padding: 0;
  
    li {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      border-bottom: 2px solid #ebe7e7;
      width: 100%;
      img {
        margin-right: 10px;
        max-width: 50px;
        height: auto;
      }
  
      p {
        margin: 0;
        margin-bottom: 20px;
      }
    }
  }
}
}
  
