.SELECTEDORDER {
    border: 1px solid #37BFA7 !important;
}
.ORDERINGCARDS .ORDERINGCARDS__DATE {
    white-space: nowrap;
}

.ORDERINGCARDS,
.SELECTEDORDER,
.UPCOMMING__ORDER {
    // background: #FFF;
    cursor: pointer;
    // width: 111px;
    height: 88px;

    // border: 1px solid rgba(190, 201, 195, 1);
    padding: 8px 16px 8px 16px;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid rgba(28, 55, 90, 0.16);
    display: flex;
    flex-direction: column;

    p {
        margin: 0px;
    }

    .ORDERINGCARDS__DATE {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;

        .Date_Days {
            color: rgba(24, 28, 26, 1);
            font-family: Lato;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.25px;
            text-align: center;
        }

        .Date_Year {
            color: rgba(24, 28, 26, 1);
            font-family: Lato;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            text-align: center;
        }
    }

    span {
        color: rgba(27, 43, 65, 0.69);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
    }

    .ORDERINGCARDS__SELLING__PRICE {

        .ORDERINGCARDS__PRICE,
        .ORDERINGCARDS__UNIT,
        .ORDERINGCARDS__CURRENCY {
            color: rgba(21, 107, 84, 1);
            font-size: 16px;
            font-weight: 700;
            line-height: 22.554px;
            text-transform: capitalize;
        }

        .ORDERINGCARDS__UNIT {
            font-size: 13px;
            font-weight: 500;
            line-height: 16.289px;
        }

        .ORDERINGCARDS__CURRENCY {
            font-size: 16px;
            font-weight: 700;
        }
    }
}

.UPCOMMING__ORDER {
    background: rgba(205, 230, 246, 1);
    border: 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: flex-start;
    gap: 4px;
    width: 111px;
    height: 88px;

    height: 88px;
    // text-align: center;
    padding: 8px 16px 8px 16px;
    border-radius: 8px;


    .ORDERINGCARDS__DATE {
        color: rgba(28, 48, 74, 0.52);
        line-height: 20px;
    }

    .UPCOMMING__ORDER__CONTENT {
        // display: flex;
        // padding: 6px 22px;
        // align-items: center;
        // gap: 2px;
        // align-self: stretch;
        // border-radius: 6px;
        // background: linear-gradient(180deg, #FFCA20 0%, #FFDF08 100%);
    }
}

@media (max-width: 580px) {

    .ORDERINGCARDS .ORDERINGCARDS__DATE,
    .SELECTEDORDER .ORDERINGCARDS__DATE,
    .UPCOMMING__ORDER .ORDERINGCARDS__DATE {
        font-size: 12px;
    }
}