$seafoam: #37bfa7;

.moreinfo {
    .moreinfo__tabs {
        display: flex;
        align-items: center;
        justify-content: center;

        h5 {
            margin: 0;
            height: 26px;
            width: auto;
            font-weight: 600;
            font-size: 16px;
            color: rgba(28, 48, 74, 0.52);
            cursor: pointer;
            margin-bottom: -1px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -6px;
                border-bottom: 2px solid rgba(28, 48, 74, 0.52);
            }

            &:last-child {
                text-align: right;
            }

            &:nth-child(2) {
                text-align: center;
            }
        }

        .moreinfo__selectedTab {
            color: $seafoam;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -6px;
                border-bottom: 2px solid $seafoam;
            }
        }
    }

    .moreinfo__tabContent {
        // margin-top: 2em;
        // margin-left: 37px;

        .row {
            .doYouWant_content {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
            }

            .return_btn,
            .payment_btn {
                background-color: rgba(238, 242, 238, 1);
                // width: 135px;
                // height: 36px;
                padding: 8px 16px 8px 16px;
                gap: 10px;
                border-radius: 4px 0px 0px 0px;

                a {
                    color: inherit;
                    text-decoration: none;
                }
            }

            .additionalInfo_row_1 {
                margin-bottom: 32px;
                gap: 60px;
            }

            .additionalInfo_row_1,
            .additionalInfo_row_2 {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                .additionalInfo_col {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 16px;

                    .additionalInfo_text {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 16px;

                        .additionalInfo_col_heading {
                            color: rgba(25, 39, 57, 1);
                            font-family: Lato;
                            font-size: 13px;
                            font-weight: 600;
                            line-height: 16.29px;
                            text-align: left;
                        }

                        .additionalInfo_col_content {
                            color: rgba(25, 39, 57, 1);
                            font-family: Lato;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 16.29px;
                            text-align: left;
                        }
                    }
                }
            }

            .jagotaAdvantage_content {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 60px;

                .jagotaAdvantage_content_col {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;
                }

                .jagotaAdvantage_content_text {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    color: rgba(25, 39, 57, 1);
                    font-family: Lato;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18.76px;
                    text-align: left;
                }
            }
        }
        // margin-left: 37px;

        &>span {
            font-size: 16px;
            color: rgba(25, 39, 57, 0.94);
            font-weight: 400;
            line-height: 22.1px;
            white-space: pre-line;
        }

        .tabContent__details {
            display: flex;
            flex-direction: column;
            // gap: 1em;

            p {
                display: flex;
                align-items: center;
                margin: 0;
                min-width: 420px;
                max-width: max-content;

                span {
                    width: calc(100% / 2);
                    display: flex;
                    flex-direction: column;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 16.289px;
                    color: rgba(27, 43, 65, 0.69);
                    text-align: left;
                    max-width: 212px;
                    min-height: 22px;
                    line-height: 20.05px;

                    span {
                        font-size: 13px;
                        color: $seafoam;
                        width: max-content;
                    }
                }

                .details--value {
                    color: rgba(25, 39, 57, 0.94);
                    max-height: 22px;
                    overflow: hidden;
                }
            }
        }
    }
}

@media(max-width: 912px) {
    .moreinfo {
        .moreinfo__tabs {
            h5 {
                text-align: center;
            }
        }

        .moreinfo__tabContent .row .additionalInfo_row_1 {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
        }

        .moreinfo__tabContent .row .jagotaAdvantage_content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 16px;

            .jagotaAdvantage_content_col {
                gap: 16px;
            }
        }
    }

}