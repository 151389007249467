@import 'fonts';

body {
  margin: 0;
  // background-color: white !important;
// background-color: #f3f3f3;
background: linear-gradient(0deg, var(--grey-5, rgba(25, 59, 103, 0.05)) 0%, var(--grey-5, rgba(25, 59, 103, 0.05)) 100%), var(--white, #FFF);
}

$seafoam: #37bfa7;


* {
  box-sizing: border-box;
  font-display: swap;

  // font-family: "Prompt";
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Style the scrollbar thumb */
  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Change this color to your preferred scrollbar color */
    border-radius: 8px;
  }

  /* Style the scrollbar track on hover (optional) */
  &::-webkit-scrollbar-track:hover {
    background-color: #ddd; /* Change this color to your preferred hover color */
  }

  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: thin;
  /* for Firefox */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Remove the up and down arrows from input[type="number"] */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button:hover,
input[type="number"]::-webkit-outer-spin-button:hover,
input[type="number"]::-webkit-inner-spin-button:focus,
input[type="number"]::-webkit-outer-spin-button:focus,
input[type="number"]::-webkit-inner-spin-button:active,
input[type="number"]::-webkit-outer-spin-button:active {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}


.englang {
  font-family: "Lato", 'Arial', sans-serif !important;
}

.thailang {
  font-family: "Prompt", 'Arial', sans-serif !important;
}

.bg__trans {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.0);
  z-index: 999;
}

.footer {
  background-color: $seafoam;
  position: relative;
  bottom: 0;
}

.catalog {
  color: white !important;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    left: 10px;
    right: 8px;
    bottom: -5.5px;
    border: 5px solid $seafoam;
    border-radius: 25px;
  }

  h5 {
    color: white;
  }
}

th {
  font-weight: 600 !important;
}

.product__active {
  color: white !important;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    left: 10px;
    right: 8px;
    bottom: -5.5px;
    border: 5px solid $seafoam;
    border-radius: 25px;
  }

  h5 {
    color: white;
  }
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
// input[type="number"] {
//   -moz-appearance: textfield;
// }

input:focus,
a:focus {
  outline: none;
  box-shadow: none !important;
}

b {
  font-weight: 600 !important;
}

.react-responsive-modal-closeButton:focus {
  outline: white;
}

.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.1) !important;
}

.form-check-input:checked {
  background-color: $seafoam !important;
  border-color: $seafoam !important;
}

.form-check-input:focus {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;

  outline: 0;
}

:root {
  --toastify-color-success: #37bfa7 !important;
  --toastify-icon-color-success: #37bfa7 !important;
}

.IFRAME {
  position: unset !important;
}

.react-responsive-modal-closeButton {
  display: none !important;
}

@media(max-width: 912px) {
  .app {
    overflow: hidden;
    // padding-bottom: 90px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: unset;
  }
  body {
    background: #FFF;
  }

  .bg__trans {
    display: none;
  }
}
