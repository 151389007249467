$seafoam: #37bfa7;

.cart {
    max-width: 1440px;
    margin: 0 auto;
    padding: 1em;
    min-height: 500px;
    height: 100%;

    .cart__breadcrumbs {
        display: flex;
        gap: 6px;
        align-items: center;
        margin-bottom: 16px;

        a,
        span {
            cursor: pointer;
            text-decoration: none;
            color: #000;
            font-size: 12px;
            font-weight: 400;
        }
    }

    .cart__title {
        font-size: 600;
        font-size: 24px;
        line-height: 36px;
        color: #000;
        margin-bottom: 2em;
    }

    .cart__content {
        display: flex;
        gap: 1em;

        .cart__left {
            width: 74%;

            .cart__clearall {
                margin-right: 0;
                margin-top: 1em;
                border: 0;
                outline: 0;
                font-weight: 500;
                font-size: 13px;
                line-height: 16.29px;
                background-color: white;
                // box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.2), 0px 0px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
                padding: 10px;
                border-radius: 8px;
                color: #192739F0;
                display: flex;
                align-items: center;
                gap: 10px;
                float: right;

                img {
                    max-width: 24px;
                    max-height: 24px;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .MuiPaper-elevation1 {
                display: none;
            }

            .MuiPaper-root {
                box-shadow: none;
            }

            .MuiTable-root {

                .MuiTableHead-root {
                    margin-bottom: 1em;

                    .MuiTableRow-root {
                        .MuiTableCell-root * {
                            max-height: 40px;
                        }
                    }
                }

                .MuiTableBody-root {
                    margin-top: 1em;

                    .Mui-selected {
                        background-color: #FFF !important;
                    }

                    .MuiTableRow-root {
                        &:hover {
                            background-color: white;
                        }

                        .MuiTableCell-root {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18.76px;
                            min-width: max-content;
                            max-width: 400px;
                        }

                        .cart__product {
                            p {
                                display: flex;
                                margin: 0;
                                gap: 10px;
                                align-items: center;
                            }

                            img {
                                max-width: 80px;
                                max-height: 80px;
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }

                            a {
                                display: flex;
                                flex-direction: column;
                                text-decoration: none;

                                .cart__product__name {
                                    color: #000;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 18.76px;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                }

                                .cart__product__sku {
                                    color: rgba(43, 43, 43, 0.50);
                                    font-size: 13px;
                                    font-weight: 400;
                                    line-height: 15px;
                                    text-transform: capitalize;
                                }
                            }
                        }

                        .cart__qty span {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;

                            .qty__qty {
                                font-weight: 600;
                                line-height: 26.32px;
                                font-size: 16px;
                            }

                            .qty__unit {
                                color: #1C304A85;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 15px;
                            }

                            img {
                                cursor: pointer;
                                max-height: 32px;
                                max-width: 32px;
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                            }

                            &>* {
                                width: calc(100% / 4 - 4px);
                            }
                        }

                        .cart__totalprice {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                        }

                        .cart__delete {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .cart__right {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            border-radius: 8px;
            padding: 32px 24px;
            height: 100%;
            background-color: #FFF;
        }
    }
}


@media(max-width: 912px) {
    .cart {
        padding: 0;
        // margin-top: 44px;

        .cart__title {
            margin: 1em;
            color: #000;
            font-size: 22px;
            font-weight: 700;
            //line-height: 27.567px;
            padding: 0px 16px 0px 0px;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            margin-left:15px;
            font-family: Lato;
            font-size: 22px;
            font-weight: 700;
            line-height: 27.57px;
            text-align: left;
        }

        .cart__content {
            flex-direction: column;

            .cart__right,
            .cart__left {
                max-width: 100%;
                width: 100%;
            }

            .cart__right {
                padding: 0;
                margin-bottom: 110px;
                position: relative;
                background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), rgba(255,255,255,0);
                z-index: 1;

                .CART_INFO_TEXT {
                    .RETURNREFUND span {
                        gap: 0;

                        span {
                            font-weight: 700;
                        }
                    }
                }

                &>* {
                    padding: 1em;
                }
                &+p.content{
                    padding-top: 10px;
                    border-top-right-radius: 20px;
                    border-top-left-radius: 20px;
                    overflow: hidden;
                }

                .summary__button {
                    padding: 12px 10px !important;
                    width: 90%;
                    margin: 0 auto;

                    &:before{
                        content: "";
                        width: calc(100% + 10%);
                        position: absolute;
                        height: 100%;
                        background: rgba(255, 255, 255, 1);
                        z-index: -1;
                        left: -5%;
                        display: block;
                        opacity: 1;
                        visibility: visible;
                    }
                }

                .content {
                    margin: 0;
                    padding-top: 0;
                    background-color: #fff;

                    .summary__label {
                        font-size: 14px;
                    }

                    .Checkout--disabled {
                        background-color: #FFF;
                    }


                    &:nth-last-child(1) .summary__label {
                        font-size: 18px;
                    }
                }
                

            }

            .cart__clearall {
                padding: 16px;
                margin: 0 !important;
                background-color: #F5F5F5;
                display: flex;
                justify-content: flex-end;

                span {
                    color: #22A38C;
                }
            }
        }

        .MuiTable-root {
            .MuiTableHead-root {
                .MuiTableRow-root {
                    .MuiTableCell-root {
                        display: none;

                        // &:first-child {
                        //     display: block;
                        //     z-index: 9999999;
                        // }
                    }
                }
            }

            .MuiTableBody-root {
                .MuiTableRow-root {
                    display: flex;
                    position: relative;
                    flex-direction: column;
                    align-items: flex-start;
                    border-bottom: 1px solid rgba(224, 224, 224, 1);
                    overflow-x: hidden;


                    .MuiTableCell-root {
                        border: 0;

                        &:nth-child(1) {
                            position: absolute;
                            top: 1em;
                            // display: none;
                        }

                        &:nth-child(2) {
                            padding-bottom: 0;
                            margin-left: 3em;

                            .cart__product {
                                p {
                                    a {
                                        gap: 10px;
                                    }
                                }
                            }
                        }

                        &:nth-child(3),
                        &:nth-child(4) {
                            text-align: left;
                            margin-left: 90px;
                            height: 50px;
                        }

                        &:nth-child(4) {
                            position: absolute;
                            bottom: 0.3em;
                            left: 9em;
                            height: 50px;
                        }

                        .cart__unitprice span {
                            color: rgba(25, 39, 57, 0.94);
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 18px;
                            text-transform: capitalize;
                        }

                        .cart__qty span {
                            gap: 12px !important;

                            img {
                                max-width: 40px !important;
                                max-height: 40px !important;
                            }

                            .qty__qty {
                                font-weight: 700;
                            }
                        }

                        &:nth-child(5) {
                            position: absolute;
                            right: 0;
                            bottom: 0.25em;
                            height: 50px;

                            .cart__totalprice {
                                span {
                                    color: #22A38C;
                                    font-size: 18px;
                                    font-weight: 400;
                                    line-height: 18px;
                                    text-transform: capitalize;
                                }
                            }
                        }

                        &:nth-child(6) {
                            display: flex;
                            position: absolute;
                            right: -100%;
                            // bottom: 0.5em;
                            height: 100%;
                            vertical-align: middle;
                            align-items: center;
                            background: #f44336;
                            transition: all 0.7s;
                        }
                    }

                    .cart__product {
                        a {
                            .cart__product__name {
                                max-width: 272px;
                                word-break: break-all;
                            }
                        }
                    }

                    &:hover,
                    &:focus-within {
                        .MuiTableCell-root:nth-child(6) {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}
// .cart .MuiTable-root .MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-root:nth-child(6) {
//     right: 0;
// }