$seafoam: #37bfa7;

.success {
    max-width: 520px;
    min-height: 60vh;
    margin: 0 auto;

    &>* {
        text-align: center;
    }

    .success__list {
        display: flex;
        align-items: center;
        flex-direction: column;

        p {
            color: rgba(25, 39, 57, 0.94);
            font-size: 18px;
            font-weight: 600;
            line-height: 22.554px;
            text-transform: capitalize;
            margin: 0;
            margin-bottom: 18px;

            img {
                max-width: 24px;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        ul {
            padding: 0;

            li {
                border-radius: 8px;
                border: 1px solid rgba(26, 56, 96, 0.10);
                background: #FFF;
                color: rgba(27, 43, 65, 0.69);
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                width: 136px;
                height: 38px;
                padding: 10px;
                list-style: none;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    width: 4px;
                    height: 4px;
                    background-color: rgba(27, 43, 65, 0.69);
                    left: 8px;
                    top: 16px;
                    border-radius: 100%;
                }
            }
        }
    }

    &>img {
        max-width: 177px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: flex;
        margin: 15% auto 0;
    }

    h4 {
        font-weight: 600;
        color: #192739F0;
        font-size: 22px;
        line-height: 22.567px;
        margin: 0 auto 40px;
        max-width: 320px;
    }

    p {
        color: #1280E5;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        margin: 40px 0;
    }

    .success__buttons {
        display: flex;
        gap: 1em;
        align-items: center;
        justify-content: center;

        a {
            text-decoration: none;
        }

        @mixin buttons {
            border: 1px solid $seafoam;
            padding: 12px 10px;
            border-radius: 6px;
            font-weight: 600;
            font-size: 16px;
            line-height: 26.32px;
            height: 50px;
            width: 263px;
        }

        .toFina {
            background-color: white;
            color: $seafoam;
            @include buttons
        }

        .toHome {
            background-color: $seafoam;
            color: white;
            @include buttons
        }
    }
}

@media(max-width: 912px) {
    .success {
        background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
        min-height: 80vh;


        .success__buttons {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 9999;
            background: #FFF;
            flex-direction: column;
            padding: 24px;

            .toFina,
            .toHome {
                width: 90%;
                margin: 0 auto;
            }
        }

        .SUCCESS__MOBILE {
            width: 100%;
            margin: 1em auto 0;
            background-color: #FFF;
            // margin-top: 3em;
        }

        img,
        h4,
        .success__list,
        p {
            background-color: #FFF;
            margin: 0;
        }

        img {
            text-align: center;
            padding: 15% 0 0
        }

        .success__list {
            p {
                margin-bottom: 24px;
            }
        }

        &>p {
            text-align: center;
            padding: 0 20px;
            padding-bottom: 40px;
        }

        h4 {
            max-width: 100%;
            padding: 0 20px;
            padding-bottom: 40px;
        }
    }
}

@media(max-width: 465px) {
    .success {
        background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px;
        margin-top: -4em;

        & > p {
            padding: 0 5em 40px;
        }

        img {
            padding: 0 !important;
        }

        .success__list {
            p {
                img {
                    padding: 0;
                }
            }
        }
    }
}