.FORMINPUTS {
    max-width: calc(100% / 3 - 2em);
    width: 100%;
    color: rgba(25, 39, 57, 0.94);

    h5 {
        color: rgba(25, 39, 57, 0.94);
        font-size: 14px;
        font-weight: 400;
        line-height: 18.76px;
        height: 19px;
        margin-bottom: 8px;

        span {
            color: #EE4D44;
        }
    }

    input {
        width: 100%;
        padding: 16px 20px;
        border-radius: 4px;
        background: rgba(25, 59, 103, 0.05);
        border: 0;
        color: rgba(25, 39, 57, 0.94);
        font-size: 14px;
        font-weight: 400;
        line-height: 18.76px;
    }
}