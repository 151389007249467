.NOTIFICATIONS {
    .NOTIFICATION__TABS {
        display: flex;
        align-items: baseline;
        justify-content: center;
        border-radius: 12px;
        background: rgba(25, 59, 103, 0.05);
        height: 44px;
        padding: 4px;
        margin-top: 10px;

        p {
            margin: 0;
            height: 100%;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            border-radius: 12px;
            cursor: pointer;
            color: rgba(28, 48, 74, 0.52);
        }

        .NOTIFICATIONS__TAB__SELECTED {
            color: #222741;
            background-color: #FFF;
        }
    }

    .NOTIFICATION__CONTENTS {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 10px;

        &>span {
            width: 100%;
            background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
            border-radius: 8px;
            padding: 8px 12px;
            position: relative;

            .NOTIFICATION__CATEGORY {
                display: flex;
                justify-content: center;
                align-items: center;
                width: max-content;
                gap: 10px;
                margin-bottom: 10px;
                height: 24px;

                strong {
                    position: absolute;
                    right: 16px;
                    top: 16px;
                    color: #22A38C;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 15px;
                    text-transform: capitalize;
                }

                svg {
                    padding: 3px 5.5px;
                    border-radius: 100%;
                }

                .NOTIFICATION__CATEGORY__ORDER {
                    background: #FCD6CE;
                }

                .NOTIFICATION__CATEGORY__NOTIFICATION {
                    background: #B7CCFF;
                }

                .NOTIFICATION__CATEGORY__ACCOUNT {
                    background: #CEFCF4;
                }

                .NOTIFICATION__CATEGORY__FAV {
                    background: #FCCECE;
                }

                &>small {
                    text-transform: capitalize;
                    color: #222741;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 18.2px;
                }
            }

            .NOTIFICATION__MESSAGE {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                color: rgba(25, 39, 57, 0.94);
                max-height: 50px;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: -0.12px;
                position: relative;

                small {
                    display: block;
                    width: 85%;
                }

                .NOTIFICATION__DOT {
                    position: absolute;
                    right: 0px;
                    top: 2px;
                }
            }

            .NOTIFICATION__CREATED {
                color: #22A38C;
                font-size: 12px;
                font-weight: 400;
                line-height: 15.6px;
            }
        }
    }
}