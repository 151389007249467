$seafoam: #37bfa7;

.product_card_width {
  width: unset !important;
  margin: 5px;
}

.previous a,
.next a {
  font-size: 1.5em !important;
  color: $seafoam !important;
}

.home {
  .HOME_NEWARRIVALS, .HOME_BLOCKS{
    .blocks{
      .slick-slider {
        .slick-next{
          left:97%;
        }
      }
    }
  }
}
.HOME_TRENDING .blocks .blocks_header a {
  right: 0;
  width: auto;
}
.blocks {
  // padding: 30px 0;
  background: linear-gradient(0deg, var(--grey-5, rgba(25, 59, 103, 0.05)) 0%, var(--grey-5, rgba(25, 59, 103, 0.05)) 100%), var(--white, #FFF); // Default background

  &>* {
    max-width: 1440px;
    margin: 0 auto;
  }

  .blocks__header {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    margin-top: 1em;
    position: relative;

    span,
    a {
      text-decoration: none;
      color: rgba(27, 43, 65, 0.69);
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-transform: capitalize;
      position: absolute;
      right: 6.5%;
      cursor: pointer;
      width: auto;
      svg{
        width:24px;
        height: 24px;
        margin-top: -1px;
      }
    }
   .blocks_header span, .blocks .blocks_header .newarrival a {
      text-decoration: none;
      color: rgba(27, 43, 65, 0.69);
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      text-transform: capitalize;
      position: absolute;
      right: 1%;
      cursor: pointer;
      width: 100px;
  }

    small {
      color: rgba(25, 39, 57, .94);
      font-size: 16px;
    }

    h3 {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 22px;
      font-weight: 700;
      line-height: 27.57px;
    }
  }


  .slick-slider {
    position: relative;
    overflow: hidden;

    .slick-track {
      margin-left: unset;
    }

    .slick-prev,
    .slick-next {
      position: absolute;
      height: 32px;
      width: 32px;
      border-radius: 100%;
      stroke-width: 1px;
      border: 1px solid rgba(0, 0, 0, 0.5);
    }

    .slick-prev {
      top: -32px;
      left: 93.6%
      // background-color: red;
    }

    .slick-next {
      top: -32px;
      left: 96%;
      // background-color: red;
    }
  }
}
@media(max-width: 912px) {
  .slick-slider {
    padding-right: 0px;
  }

  .slick-prev,
  .slick-next {
    display: none;
  }

  .blocks {
    padding: 16px 3%;

    .slick-list {
      overflow: unset;
    }
  }

  .HOME_PROMOTION_BLOCK {
    background: linear-gradient(0deg, var(--grey-5, rgba(25, 59, 103, 0.05)) 0%, var(--grey-5, rgba(25, 59, 103, 0.05)) 100%), var(--white, #FFF);

    .PROMOTION__HEADER {
      margin: 0 3%;
      margin-bottom: 20px;

      ul {
        li {
          font-size: 18px !important;
          line-height: 22.554px !important;
        }

      }
    }

    .PROMOTION__CONTENT {
      padding: 0;
    }
  }
  .blocks .blocks__header a {
    right: 0;
  }
}
@media (max-width: 912px) {
  .blocks__header span,
  .blocks__header a {
    border-radius: 32px;
  
    padding: 8px 12px 8px 12px;
    
    border: 1px solid rgba(28, 52, 84, 0.26);
  }
}

.blocks.no-background {
  background: none;
}