@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('./assets/fonts/Poppins/Poppins-Thin.ttf') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('./assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('./assets/fonts/Poppins/Poppins-Light.ttf') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('./assets/fonts/Poppins/Poppins-Medium.ttf') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('./assets/fonts/Poppins/Poppins-SemiBold.ttf') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./assets/fonts/Poppins/Poppins-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('./assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('woff2');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('./assets/fonts/Poppins/Poppins-Black.ttf') format('woff2');
}

@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./assets/fonts/Prompt/Prompt-Thin.ttf') format('woff2');
}

@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./assets/fonts/Prompt/Prompt-ExtraLight.ttf') format('woff2');
}

@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./assets/fonts/Prompt/Prompt-Light.ttf') format('woff2');
}

@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./assets/fonts/Prompt/Prompt-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('./assets/fonts/Prompt/Prompt-Medium.ttf') format('woff2');
}

@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('./assets/fonts/Prompt/Prompt-SemiBold.ttf') format('woff2');
}

@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./assets/fonts/Prompt/Prompt-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./assets/fonts/Prompt/Prompt-ExtraBold.ttf') format('woff2');
}

@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./assets/fonts/Prompt/Prompt-Black.ttf') format('woff2');
}
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('./assets/fonts/Lato/Lato-Thin.ttf') format('woff2');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('./assets/fonts/Lato/Lato-Light.ttf') format('woff2');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./assets/fonts/Lato/Lato-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./assets/fonts/Lato/Lato-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('./assets/fonts/Lato/Lato-Black.ttf') format('woff2');
}
