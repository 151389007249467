$seafoam: #37bfa7;
$color2: #858585;
.cangepassword__modal {
  background: #000;
}

.changepassword {
  min-height: 60vh;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 3em;
  background-color: #f3f3f3;
  position: relative;

  .cp__container {
    display: flex;
    position: relative;
    justify-content: center;
  }

  .validatorText {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 2.5em;
    padding: 0;
    top: 4em;
    margin: 0;

    strong {
      // text-decoration: underline;
      font-weight: 500;
    }

    .validator {
      // list-style: none;
      margin: 0;
      .seafoam, .notseafoam {
        width: 100%;
        height: 2em;
      }
  
      .textseafoam {
        color: $seafoam;
        font-style: italic;
        font-size: 14px;
      }
  
      .nottextseafoam {
        color: red;
        font-style: italic;
        font-size: 14px;
      }
    }
  }

  .changepassword__back {
    cursor: pointer;

    span img {
      max-width: 24px;
      max-height: 24px;
      width: 100%;
      height: 100%;
    }
  }

  .changepassword__container {
    // max-width: 400px;
    // margin: 0 auto;
    .changepassword__prev,
    h2 {
      color: $seafoam;
    }

    .changepassword__prev {
      font-size: 16px;
      text-decoration: underline;
      position: relative;
      padding-left: 1em;
      display: flex;
      align-items: center;
      cursor: pointer;

      &::before {
        content: "";
        border-top: 2px solid #9caba2;
        border-right: 2px solid #9caba2;
        margin-right: 1em;
        height: 10px;
        width: 10px;
        transform: rotate(225deg);
        position: absolute;
        left: 0;
      }
    }

    h2 {
      font-size: 28px;
      margin-bottom: 1em;
      text-align: center;
    }

    .changepassword__form {
      max-width: 400px;
      margin: 0 auto;
      .error {
        color: red;
      }
      .mb-3 {
        margin-bottom: 1em !important;
      }
      input {
        box-shadow: 0 1px 4px #7f7f7f1c;
        margin-bottom: 2em;
        border: 0;
        border-radius: 8px;
        height: 2.5em;
        padding-left: 1em;
      }

      h6 {
        font-size: 14px;
        color: #2b2b2b;
      }
    }

    .changepassword__buttondiv {
      width: 320px;
      margin: 0 auto;
    }

    .changepassword__button {
      background-color: $seafoam;
      font-size: 14px;
      color: white;
      width: 210px;
      margin: 0 auto;
      text-align: center;
      padding: 0.5em 1.2em;
      border-radius: 20px;
      cursor: pointer;
    }
  }

  .changepassword__verification {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.customModal {
  width: 100%;
}
.VerificationModal {
  width: 40%;
  height: 70%;
}

@media (max-width: 1600px) {
  .changepassword {
    padding: 3em 7%;
  }
}

@media(max-width: 1200px) {
  .changepassword .validatorText {
    right: 0;
  }
}

@media (max-width: 1024px) {
  .changepassword {
    padding: 3em 10%;
  }

  .cp__container {
    flex-direction: column;
  }

  .validatorText {
    position: unset !important;
    width: max-content;
    margin: 3em auto 0 !important;
  }
}

@media (max-width: 480px) {
  .changepassword {
    padding: 1.5em 3% 3em;
  }
  .changepassword .changepassword__container .changepassword__form {
    .error {
      color: red;
    }
    width: 100%;
  }
  .changepassword .changepassword__container .changepassword__form input {
    .error {
      color: red;
    }
    // margin-bottom: 0em;
  }
  .changepassword__buttondiv {
    display: flex;
    justify-content: center;

    .changepassword__button {
      margin: 0 !important;
    }
  }
}
