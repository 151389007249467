.ORDERPAGE {
    max-width: 1440px;
    margin: 2em auto;
    // margin-left:2em;
    

    .ORDERPAGE__BREADCRUMBS {
        p {
            margin: 0;
            display: flex;
            align-items: center;
            gap: 20px;

            &>* {
                color: #000;
                font-size: 12px;
                font-weight: 400;
            }

            a {
                text-decoration: none;
                color: #000;
                cursor: pointer;
                position: relative;

                &::after {
                    content: ">";
                    position: absolute;
                    right: -15px;
                    top: 1px;
                }
            }
        }
    }

    .ORDERPAGE__TITLE {
        margin: 1em 0;
        color: #000;
        font-size: 24px;
        font-weight: 600;
        text-transform: capitalize;
    }

    .ORDERPAGE__TABS {
        display: flex;
        align-items: baseline;
        gap: 16px;

        p {
            color: rgba(25, 39, 57, 0.94);
            font-size: 14px;
            font-weight: 400;
            line-height: 18.76px;
            cursor: pointer;
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            .count {
                border-radius: 14px;
                // background: #E6FBF3;
                // padding: 4px 12px;
                // color: #22A38C;
            }
        }

        .currentTab {
            font-weight: 500;
            border-bottom: 4px solid #37BFA7;
            padding-bottom: 1em;
        }
    }

    .ORDERPAGE__SEARCH__DATE {
        margin: 2em 0;
        display: flex;
        align-items: center;
        gap: 2em;

        label {
            max-width: 344px;
            width: 100%;
            position: relative;

            svg {
                position: absolute;
                top: 50%;
                transform: translate(0px, -50%);
                left: 10px;
            }

            input {
                max-width: 373px;
                width: 100%;
                height: 43px;
                padding: 6px 16px;
                color: rgba(28, 48, 74, 0.52);
                font-size: 13px;
                font-weight: 500;
                line-height: 16.289pxpx;
                text-transform: capitalize;
                display: flex;
                padding: 6px 16px 6px 40px;
                align-items: center;
                gap: 10px;
                border-radius: 6px;
                border: 1px solid rgba(28, 52, 84, 0.26);
                background: #FFF;
            }
        }
    }

    .ORDERPAGE__BLOCK {
        display: flex;
        flex-direction: column;
        gap: 1em;

        &>p {
            background-color: white;
            padding: 16px 60px;
            border-radius: 8px;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 500;

        }
    }
}

@media(max-width: 1440px) {
    .ORDERPAGE {
        &>* {
            margin: 0 1em;
        }

        .ORDERPAGE__SEARCH__DATE {
            margin: 2em 1em;
        }

        .ORDERPAGE__TITLE {
            margin: 1em;
        }
    }
}

@media(max-width: 912px) {
    .ORDERPAGE {
        margin: 0;

        .react-datepicker {
            position: absolute;
            z-index: 9999;
            left:0;

            button {
                top: 10px;
            }
            .react-datepicker__day-name{
                width:40px;
            }
            .react-datepicker__month-container {
                .react-datepicker__header {
                    background-color: #FFF;
                    padding-top: 10px;
                    padding-left: 0;
                    padding-right: 0;
                }

                .react-datepicker__month {
                    .react-datepicker__week {
                        display: flex;
                        align-items: center;

                        .react-datepicker__day {
                            height: 40px;
                            width: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .react-datepicker__day--in-range {
                            background-color: #37BFA7 !important;
                        }
                    }
                }
            }
        }

        .ORDERPAGE__BREADCRUMBS {
            display: none;
        }

        .ORDERPAGE__TABS {
            max-width: 90%;
            display: block;
            overflow: auto;
            overflow-x: hidden;
            gap: 0;

            .slick-initialized .slick-slide {
                width: unset !important;
            }

            p {
                margin: 0 10px;
                gap: 0;
                color: rgba(27, 43, 65, 0.69);
                padding-right: 10px !important;
                padding-left: 10px !important;
                width: max-content;

                .count {
                    background-color: unset;
                    padding: 0;
                    color: rgba(27, 43, 65, 0.69);

                    // &::after {
                    //     content: ")";
                    // }

                    // &::before {
                    //     content: "(";
                    // }

                }
            }

            .currentTab {
                color: #37BFA7;

                .count {
                    color: #37BFA7;
                    background-color: unset;
                }
            }
        }

        .ORDERPAGE__TITLE {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .ORDERPAGE__TABS {
            margin: 0;
            max-width: 100%;
            padding: 0 10px;
            background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
            display: flex;
            overflow: auto;
            p {
                padding: 1em 0;
                margin: 0;
            }
        }

        .ORDERPAGE__SEARCH__DATE {
            margin: 0;
            padding-left: 17px;
            padding-top: 15px;
            padding-bottom: 12px;
            // margin-top: 20px;
            padding-right: 0px;
            grid-gap: 1em;
            gap: 1em;
            background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
        }

        .ORDERPAGE__BLOCK {
            background: linear-gradient(0deg, rgba(25, 59, 103, 0.05) 0%, rgba(25, 59, 103, 0.05) 100%), #FFF;
            margin: 0;
            padding: 0 1em;
            margin-bottom: 90px;

            .MAINBLOCK {
                .MAINBLOCK__HEAD {

                    position: relative;

                    .MAINBLOCK__HEAD__LEFT {
                        flex-direction: column;
                        align-items: baseline;
                        gap: 2px;

                        .MAINBLOCK__ORDERNO {
                            color: rgba(25, 39, 57, 0.94);
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 26.32px;
                            text-transform: capitalize;
                        }

                        .MAINBLOCK__DATE {
                            color: rgba(25, 39, 57, 0.94);
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 15px;
                            text-transform: capitalize;

                            svg {
                                display: none;
                            }
                        }
                    }

                    .MAINBLOCK__HEAD__RIGHT {
                        .MAINBLOCK__DETAILS {
                            display: none;
                        }

                        .MAINBLOCK__TOTAL {
                            color: #22A38C;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 26.32px;
                            text-transform: capitalize;
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -8px;
                        border-bottom: 1px dotted rgba(28, 52, 84, 0.26);
                    }
                }

                .MAINBLOCK__TABLE {
                    margin: 0;
                    padding: 0;

                    .MAINBLOCK__TABLE__BODY {
                        gap: 0;

                        span {
                            width: 100%;
                        }

                        .FIRST {
                            margin: 14px 0;

                            span {
                                font-weight: 500;
                                line-height: 16px;
                                color: rgba(25, 39, 57, 0.94);
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 16px;
                                line-height: 15px;
                            }
                        }

                        .MAINBLOCK__TABLE__LIST {
                            p {
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;
                                padding: 0;
                                gap: 12px;
                        
                                &:last-child {
                                    border-bottom: unset;
                                }
                        
                                span {
                                    max-width: 47%;
                        
                                    &:nth-child(1) {
                                        order: 4;
                                        text-align: right;
                                        display: flex;
                                        flex-direction: column;
                                        gap: 4px;
                        
                                        &::before {
                                            content: attr(data-delivery-date); // Dynamic content based on language
                                            color: rgba(28, 48, 74, 0.52);
                                            font-size: 12px;
                                            font-weight: 400;
                                            line-height: 14px;
                                            letter-spacing: -0.12px;
                                        }
                                    }
                        
                                    &:nth-child(2) {
                                        order: 3;
                                        text-align: left;
                                        display: flex;
                                        flex-direction: column;
                                        gap: 4px;
                        
                                        &::before {
                                            content: attr(data-total-price); // Dynamic content based on language
                                            color: rgba(28, 48, 74, 0.52);
                                            font-size: 12px;
                                            font-weight: 400;
                                            line-height: 14px;
                                            letter-spacing: -0.12px;
                                        }
                                    }
                        
                                    &:nth-child(3) {
                                        order: 1;
                                        text-align: left;
                                        display: flex;
                                        flex-direction: column;
                                        gap: 4px;
                        
                                        &::before {
                                            content: attr(data-invoice-no); // Dynamic content based on language
                                            color: rgba(28, 48, 74, 0.52);
                                            font-size: 12px;
                                            font-weight: 400;
                                            line-height: 14px;
                                            letter-spacing: -0.12px;
                                        }
                                    }
                        
                                    &:nth-child(4) {
                                        order: 2;
                                        text-align: right;
                                        width: max-content;
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 390px) {
    .ORDERPAGE .ORDERPAGE__SEARCH__DATE{
        gap: 15px;
        .datePickers {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 38px;
            border-radius: 6px;
            border: 1px solid rgba(28, 52, 84, 0.26);
            /* padding: 6px 10px; */
            // grid-gap: 10px;
            // gap: 10px;
            background-color: #FFF;
        }
    }
  
}
@media only screen and (max-width: 420px) {
    .ORDERPAGE .ORDERPAGE__SEARCH__DATE{
        gap:15px;
        .datePickers {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 38px;
            border-radius: 6px;
            border: 1px solid rgba(28, 52, 84, 0.26);
            /* padding: 6px 10px; */
            // grid-gap: 10px;
            // gap: 10px;
            background-color: #FFF;
        }
    }
  
}