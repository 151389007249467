.right_content.error-present {
  gap: 10px;
}

.right_content {
  display: flex;
  flex-direction: column;
  gap: 15px;


  .right_content_row {
    display: flex;
    flex-direction: column;
    gap: 10px;


    .loginpage__alert {
      color: rgba(202, 21, 12, 1);
      font-size: 16px;
      font-family: Lato;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.01em;
      text-align: left;
      margin: 0px;
    }

    .loginpage__alert::before {
      content: "";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      margin-right: 5px;
    }


    .right_username,
    .right_password,
    .form-control {
      padding: 4px;
      background-color: rgba(245, 245, 245, 1);
      border: none;
      border-radius: 4px;
      color: rgba(28, 52, 84, 0.26);
    }

    .error-input {
      border: 1px solid rgba(255, 216, 216, 1) !important;
      outline: 1px solid rgba(255, 216, 216, 1) !important;
      background-color: rgba(255, 245, 245, 1) !important;
    }


    .form-control {
      margin-left: .4rem;
      margin-right: .4rem;
      width: 95%;
    }

    .form-label {
      // margin-top: .5rem;
      margin-left: .5rem;
      margin-bottom: 0rem !important;
    }

    .mb-3 {
      margin-bottom: 0rem !important;

      input {
        color: #192739 !important;
      }

      img {
        top: 30px !important;
        // display: none;
      }
    }

    .right_forget_pwd {
      button {
        background-color: transparent;
        border: none;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: right;
        color: rgba(54, 118, 224, 1);
      }
    }

    .login__button {
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      background: rgba(55, 191, 167, 1);
      border: none;
      border-radius: 4px;
      padding: 16px 28px 16px 28px;
    }

    .login_footer * {
      color: rgba(27, 43, 65, 0.69);
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 134%;
      background-color: transparent;
    }

    .login_footer {
      flex-wrap: wrap;
      display: flex;
      flex-direction: initial;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      text-align: center;

      .footer-line-1 {
        flex: 0 0 100%;

        select,
        option {
          color: rgba(54, 118, 224, 1);
          padding: 4px;
          border: none;
          border-radius: 4px;
        }

        /* Style the dropdown arrow */
        select::-ms-expand {
          display: none;
        }

        /* Style the dropdown arrow for other browsers */
        select {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background-image: url('../../assets/images/Login_imgs/DownArrow.png');
          background-repeat: no-repeat;
          background-position: right center;
          padding-right: 24px;
        }
      }


      button {
        border: none;
        background-color: transparent;
        color: #1C304A52;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
      }

      .selected {
        background-color: #F5F5F5;
        color: #192739;
        border-radius: 8px;
      }

      .footer-line-2,
      .footer-line-3 {
        color: rgba(28, 48, 74, 0.52);

        &.active {
          color: rgba(54, 118, 224, 1);
        }

      }
    }
  }

  .right_content_col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .right_checkbox {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      // line-height: 16px;
      text-align: left;
      color: rgba(25, 39, 57, 1);

      .mb-3 {
        margin-bottom: 0rem !important;
      }

      .form-check {
        min-height: 0rem;
        margin-bottom: 0rem;

        .form-check-input {
          background-color: transparent;
          border: 1px solid rgba(28, 52, 84, 0.26) !important;
        }
      }
    }

    .right_forget_pwd {
      button {
        background-color: transparent;
        border: none;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: right;
        color: rgba(54, 118, 224, 1);
      }
    }

    .checkbox-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 2px;

      .checkbox-input {
        appearance: none;
        background-color: transparent;
        border: 1px solid rgba(28, 52, 84, 0.26) !important;
        width: 0.88em;
        height: 0.88em;
        background-repeat: no-repeat;
        border-radius: .25em;
        cursor: pointer;
      }

      .checkbox-input:checked {
        background-color: rgba(55, 191, 167, 1);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
      }
    }

    .checkbox-label {
      font-family: Lato;
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      text-align: left;

      a {
        text-decoration: none;
        cursor: pointer;
      }

      span {
        color: rgba(54, 118, 224, 1);
      }
    }
  }
}

.welcome_text {
  color: rgba(25, 39, 57, 1);
  font-family: Lato;
  font-size: 28px;
  font-weight: 700;
  line-height: 35.08px;
  text-align: left;
}

.welcome_info {
  color: rgba(27, 43, 65, 0.69);
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.55px;
  text-align: left;
}

.signin_text {
  color: rgba(25, 39, 57, 1);
  font-family: Lato;
  font-size: 28px;
  font-weight: 700;
  line-height: 35.08px;
  text-align: left;
}

.new_user_text {
  color: rgba(25, 39, 57, 1);
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;

  span {
    font-family: Lato;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    color: rgba(55, 191, 167, 1);

    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
  }
}

// $gray: #7f8681;
// $green: #6aa779;
// $white: #ffffff;
// $seafoam: #37bfa7;

// .font-10 {
//   font-size: 10px;
// }

// .font-13 {
//   font-size: 13px;
// }

// .customModal__forgotEmail {
//   border-radius: 8px;
//   margin: 10px auto !important;
//   padding: 1.2rem !important;
//   margin-top: 20vh !important;
//   max-width: 100% !important;
//   min-width: 600px;
//   max-width: 90%;

//   &>* {
//     background-color: #f3f3f3 !important;
//     padding: 10px !important;
//   }

//   .forgot__header {
//     position: relative;
//     text-align: center;

//     .emailModal__close {
//       position: absolute;
//       right: 0;
//       top: 0;
//       cursor: pointer;
//       color: white;
//       background-color: $seafoam;
//       // border-radius: 8px;
//     }
//   }

//   .forgot__email {
//     display: flex;
//     flex-direction: column;
//   }

//   .forgot__submit {
//     background-color: $seafoam;
//     color: white;
//     outline: none;
//     border: none;
//     margin: 1em auto;
//     padding: .5em 2.2em;
//     font-size: 18px;
//     justify-content: center;
//   }

//   .alert {
//     margin: 0 auto;
//     padding: 8px;
//     width: 90%;
//   }
// }

// .customModal__resetPassword {
//   max-width: 90% !important;
//   margin: 3em auto !important;

//   .changepassword {
//     padding: 10px !important;

//     .changepassword__back {
//       display: none;
//     }

//     .cp__container {
//       flex-direction: column;
//     }

//     .validatorText {
//       position: unset !important;
//       margin-top: 1em !important;
//       display: flex;
//       flex-direction: column;
//       align-items: flex-start;

//       .validator {
//         span {
//           word-break: break-all;
//         }

//         &::marker {
//           content: '';
//         }
//       }
//     }
//   }

// }

// .loginPage {
//   min-height: 100vh;
//   background-image: url("../../assets/images/Login_imgs/Jagota_home_bg.webp");
//   background-position: top center;
//   background-size: cover;
//   background-attachment: fixed;
//   display: flex;
//   width: 100%;
//   flex-wrap: wrap;
//   align-content: center;

//   .loginpage__alert {
//     color: rgb(156, 48, 48);
//     font-size: 16px;
//     margin: 0.5em 0;

//     svg {
//       cursor: pointer;
//     }
//   }

//   .loginPage__subDiv {
//     //padding: 90px 0px;
//     padding: 20px 0;
//     flex: 0 0 100%;
//   }

//   label {
//     color: $white;
//     font-size: 16px;
//     position: relative;
//     top: -3px;
//     margin-bottom: 0;
//   }

//   .loginPage__container {
//     border-radius: 20px;
//     background-color: #7f8681;
//     padding: 10px 40px 50px 40px;
//     margin: auto;
//     opacity: 0.9;
//     width: 32%;

//     .eye-slash {
//       position: absolute;
//       right: 8px;
//       top: 30px;
//       z-index: 9999;
//     }

//     .login__form {
//       .login__heading {
//         color: $seafoam;
//         font-size: 30px;
//         font-weight: 600;
//       }

//       .loginform__top_section {
//         span {
//           font-size: 16px;
//         }
//       }

//       .loginform__top__section.text-end.text-white {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;

//         .language {
//           // border: 1px solid white;
//           border-radius: 10px;
//           padding: 3px 10px;
//           color: white;
//           background-color: transparent;

//           img {
//             max-width: 24px;
//             // max-height: 16px;
//             height: 100%;
//             width: 100%;
//             object-fit: contain;
//             margin-right: 4px;
//           }
//         }

//         .selected__lang {
//           background-color: $seafoam;
//           color: white;

//           font-weight: 500;
//         }

//         p {
//           display: flex;
//           gap: 1em;
//           margin: 0;

//           span {
//             text-decoration: underline;
//             cursor: pointer;
//           }
//         }

//         &>span {
//           display: flex;
//           justify-content: flex-end;
//           align-items: center;
//         }
//       }

//       .loginform__top_section_buttton {
//         background: $seafoam;
//         border: $seafoam;
//         font-weight: 600;
//         padding: 6px 28px;
//         margin-left: 10px;
//       }

//       .jagota-img {
//         margin: 60px 0px 25px;
//         text-decoration: none;

//         img {
//           max-width: 130px;
//           max-height: 60px;
//           height: 100%;
//           width: 100%;
//           object-fit: contain;
//           // filter: brightness(0) invert(1);
//         }
//       }

//       .login__hr {
//         color: #ffffff;
//         margin: 0px 0px 20px 0px;
//       }

//       .passwordfield img {
//         cursor: pointer;
//         position: absolute;
//         width: 20px;
//         right: 8px;
//         top: 35px;
//       }

//       .login__button {
//         background: $seafoam;
//         border: $seafoam;
//         font-size: 16px;
//         font-weight: 600;
//       }
//     }

//     .passwordfield_input {
//       padding: 0.375rem 1.7rem 0.375rem 0.75rem !important;
//     }

//     input::-ms-reveal,
//     input::-ms-clear {
//       display: none;
//     }

//     .login__with {
//       font-size: 16px;
//       font-weight: 600;
//     }

//     .loginPage__footer {
//       div {
//         span {
//           img {
//             max-width: 32px;
//             max-height: 32px;
//             height: 100%;
//             width: 100%;
//             object-fit: contain;
//           }
//         }
//       }
//       p {
//         font-size: 14px;
//       }

//       .login__with {
//         font-size: 14px;
//       }

//       a {
//         font-weight: 600;
//       }

//       .forgot__click {
//         cursor: pointer;
//         text-decoration: underline;
//       }
//     }
//   }
// }

.termsmsg {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
  }
}

// .login__resetPassword {
//   .modal-dialog {
//     max-width: 100% !important;

//     .modal-content {
//       background-color: transparent !important;
//     }
//   }
// }

// @media (max-width: 1600px) {
//   .loginPage {
//     .loginPage__container {
//       padding: 25px;
//       width: 40%;
//     }
//   }
// }

// @media(max-width: 1440px) {
//   .loginPage {
//     .loginPage__container {
//       padding: 15px 10px;
//     }
//   }
// }

// @media(max-width: 1280px) {
//   .loginPage {
//     .loginPage__container {
//       .login__form {
//         .loginform__top__section.text-end.text-white {
//           p {
//             gap: 0;
//           }
//         }
//       }
//     }
//   }
// }

// @media(max-width: 1100px) {
//   .loginPage {
//     .loginPage__container {
//       width: 50%;
//     }
//   }
// }

// @media (max-width: 1024px) {
//   .loginPage {
//     .loginPage__subDiv {
//       // padding: 200px 0px;
//     }

//     .login__form {
//       .jagota-img {
//         margin: 60px 0px 25px;
//       }
//     }
//   }
// }

// @media(max-width: 820px) {
//   .loginPage {
//     .loginPage__container {
//       width: 65%;
//     }
//   }
// }

// @media (max-width: 768px) {
//   .loginPage {
//     .loginPage__subDiv {
//       // padding: 40px 0px;
//     }

//     .loginPage__container {
//       width: 75%;
//       padding: 10px 25px 20px 25px;
//     }

//     .login__form {
//       .loginform__top_section_buttton {
//         font-size: inherit;
//         margin-left: 15px;
//       }

//       .jagota-img {
//         margin: 30px 0px 15px;
//       }
//     }
//   }

//   .modalbody {
//     max-height: 60vh;
//     overflow: scroll;

//     .mainbody {
//       border: 1px solid #666;
//       padding: 8px;
//       // overflowY:scroll;
//       overflow: scroll;
//     }

//     .termsmsg {
//       display: flex;
//       justify-content: flex-start;
//       align-items: baseline;
//       margin: 10px 0px;
//       gap: 15px;
//       height: 2em;
//     }
//   }
// }

// @media(max-width: 912px) {
//   .loginPage {
//     .loginPage__container {
//       width: 90%;
//     }
//   }
// }

// @media (max-width: 480px) {
//   .loginPage {
//     .loginPage__subDiv {
//       // padding: 40px 0px;
//     }

//     .loginPage__container {
//       padding: 10px 20px 25px;
//       width: 95%;
//     }

//     .login__form {
//       .loginform__top_section_buttton {
//         font-size: inherit;
//         margin-left: 15px;
//       }

//       .jagota-img {
//         margin: 20px 0px 15px;
//       }

//       .loginform__top__section span {
//         display: flex;
//         align-items: center;
//         justify-content: space-around;
//       }

//       .loginpage___nomember {
//         margin: 0;
//         font-size: 12px;
//       }
//     }
//   }

//   .loginPage .loginPage__container .login__form .loginform__top_section_buttton {
//     padding: 4px 20px;
//   }

//   .customModal__resetPassword {
//     padding: 0 !important;
//   }
// }

// @media(max-width: 425px) {
//   .loginPage {
//     .loginPage__container {
//       .login__form {
//         .loginform__top__section.text-end.text-white {
//           flex-direction: column-reverse;
//           gap: 1em;

//           span, p {
//             display: flex;
//             justify-content: space-between;
//             width: 100%;
//             & > * {
//               width: max-content;
//             }
//           }
//         }
//       }
//       .loginPage__footer {
//         p {
//           font-size: 12px;
//         }
//       }
//     }
//   }


// }